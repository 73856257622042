import React, { FunctionComponent } from 'react';
import { MagazineDetails } from '../../containers';
import AppMeta from '../common/AppMeta';

interface MagazineDetailsPageProps {
  article: any;
}

const MagazineDetailsPage: FunctionComponent<MagazineDetailsPageProps> = ({
  article,
}: MagazineDetailsPageProps) => {
  return (
    <>
      <AppMeta
        title={article.get('metaTitle')}
        description={article.get('metaDescription')}
        keywords={article.get('metaKeywords')}
        prefix="Guichet Magazine"
      />
      <MagazineDetails article={article} />
    </>
  );
};

export default MagazineDetailsPage;
