import { ActionCreator } from 'redux';
import { categoryActionsKeys } from '../types';
import {
  SimpleActions,
  simpleFetchRequest,
  simpleFetchReset,
  simpleFetchResetMessages,
} from '../helpers';
import { ConfigCallApi, ApiRoutes } from '../providers';

/**
 * Fetch EventCategories action creator
 *
 * @param {boolean} [refresh]
 * returns {SimpleActions}
 */
export const fetchEventCategories: ActionCreator<SimpleActions> = (
  refresh?: boolean,
): SimpleActions => {
  const confiApi: ConfigCallApi = {
    url: ApiRoutes.eventCategories,
    callGlobalError: false,
    refresh,
  };
  return simpleFetchRequest(categoryActionsKeys.EVENT_CATEGORIES, confiApi);
};

/**
 * Reset EventCategories action creator
 *
 * returns {SimpleActions}
 */
export const resetEventCategories: ActionCreator<SimpleActions> = (): SimpleActions =>
  simpleFetchReset(categoryActionsKeys.EVENT_CATEGORIES);

/**
 * Reset EventCategories messages action creator
 *
 * returns {SimpleActions}
 */
export const resetEventCategoriesMessages: ActionCreator<SimpleActions> = (): SimpleActions =>
  simpleFetchResetMessages(categoryActionsKeys.EVENT_CATEGORIES);
