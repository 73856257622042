import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { fetchSearch as fetchSearchAc } from '../../actions';
import LoadingPage from '../../components/common/LoadingPage';
import SearchC from '../../components/pages/Search';
import {
  getSearchList,
  getSearchListError,
  getSearchListInfinitLoading,
  getSearchListLoading,
  getSearchPagination,
} from '../../selectors/search';

const Search: FunctionComponent<any> = ({
  fetchSearch,
  searchList,
  loading,
  infinitLoading,
  pagination,
}: any) => (
  <>
    <LoadingPage loading={loading} />
    <SearchC
      fetchSearch={fetchSearch}
      searchList={searchList}
      infinitLoading={infinitLoading}
      onLoadMore={searchQuery => {
        fetchSearch(searchQuery, pagination.get('nextPage'), true);
      }}
      pagination={pagination}
      loading={loading}
    />
  </>
);

/**
 * Search map state to props
 *
 * @param {Dispatch} dispatch
 */
const mapDispatchToProps = (dispatch: Dispatch): any => {
  return bindActionCreators(
    {
      fetchSearch: fetchSearchAc,
    },
    dispatch,
  );
};

/**
 * Search map state to props
 *
 * @param {AppState} appState
 */
const mapStateToProps = (appState: any): any => {
  return {
    searchList: getSearchList(appState),
    loading: getSearchListLoading(appState),
    infinitLoading: getSearchListInfinitLoading(appState),
    error: getSearchListError(appState),
    pagination: getSearchPagination(appState),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
