import { createSelector } from 'reselect';
import { List } from 'immutable';
import { SimpleFetchStateRecord } from '../helpers';
import { AppState, CampaignState } from '../types';
import { CampaignsRecord, CampaignRecord } from '../models';
import { Error } from '../providers';

export const getCampaignState = (appState: AppState) => appState.campaign;

//* ************************* BEGIN  CAMPAIGNS *************************** */

export const getCampaignsState = createSelector<
  AppState,
  any,
  SimpleFetchStateRecord<CampaignsRecord>
>(
  [getCampaignState],
  (campaignState: CampaignState) => campaignState.campaignsState,
);

export const getCampaignsLoading = createSelector<AppState, any, boolean>(
  [getCampaignsState],
  (campaignsState: SimpleFetchStateRecord<CampaignsRecord>) =>
    campaignsState.get('loading'),
);

export const getCampaignsError = createSelector<AppState, any, Error | null>(
  [getCampaignsState],
  (campaignsState: SimpleFetchStateRecord<CampaignsRecord>) =>
    campaignsState.get('error'),
);

export const getCurrentCampaignsList = createSelector<
  AppState,
  any,
  List<CampaignRecord> | undefined | null
>(
  [getCampaignsState],
  (campaignsState: SimpleFetchStateRecord<CampaignsRecord>) =>
    campaignsState.get('data')?.get('currentCampaigns'),
);

export const getFutureCampaignsList = createSelector<
  AppState,
  any,
  List<CampaignRecord> | undefined | null
>(
  [getCampaignsState],
  (campaignsState: SimpleFetchStateRecord<CampaignsRecord>) =>
    campaignsState.get('data')?.get('futureCampaigns'),
);

//* ************************* END  CAMPAIGNS *************************** */

//* ************************* BEGIN  CAMPAIGNS *************************** */

export const getHomeCatagoriesState = createSelector<
  AppState,
  any,
  SimpleFetchStateRecord<CampaignsRecord>
>(
  [getCampaignState],
  (campaignState: CampaignState) => campaignState.homeCampaignsState,
);

export const getHomeCampaignsLoading = createSelector<AppState, any, boolean>(
  [getHomeCatagoriesState],
  (campaignsState: SimpleFetchStateRecord<CampaignsRecord>) =>
    campaignsState.get('loading'),
);

export const getHomeCampaignsError = createSelector<
  AppState,
  any,
  Error | null
>(
  [getHomeCatagoriesState],
  (campaignsState: SimpleFetchStateRecord<CampaignsRecord>) =>
    campaignsState.get('error'),
);

export const getHomeCurrentCampaignsList = createSelector<
  AppState,
  any,
  List<CampaignRecord> | undefined | null
>(
  [getHomeCatagoriesState],
  (campaignsState: SimpleFetchStateRecord<CampaignsRecord>) =>
    campaignsState.get('data')?.get('currentCampaigns'),
);

//* ************************* END  CAMPAIGNS *************************** */
