import { Record } from 'immutable';
import { ProductForRec, ProductsListingForRec } from '../types';

export class ProductRecord extends Record<ProductForRec>(
  {
    id: 0,
    title: null,
    reference: null,
    slug: null,
    term: null,
    oldPrice: null,
    discount: null,
    price: null,
    cover: null,
    slide: null,
    images: null,
    qty: null,
    qtyMax: null,
    qtyMin: null,
    description: null,
    combinations: null,
    campaign: null,
    metaTitle: null,
    metaDescription: null,
    metaKeywords: null,
  },
  'product',
) {}

export class ProductsListRecord extends Record<ProductsListingForRec>(
  {
    list: null,
    success: false,
    pagination: null,
    loadMore: false,
  },
  'products-list',
) {}
