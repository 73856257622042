import { Record } from 'immutable';
import { Brand } from '../types';

export class BrandRecord extends Record<Brand>(
  {
    id: 0,
    title: null,
    logo: null,
  },
  'brand',
) {}
