import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import TermAccordions from '../Accordion';
import { CampaignRecord, TermRecord } from '../../models';
import { ProductsGrid } from '../../containers';
import AppMeta from '../common/AppMeta';

interface CampaignProps {
  campaign?: CampaignRecord | null;
  term?: TermRecord | null;
}

const Catalog = ({ campaign, term }: CampaignProps) => {
  // if (!campaign) return null;

  const terms = campaign.get('terms');

  return (
    <div className="catalog">
      <AppMeta
        title={term ? term.get('metaTitle') : campaign.get('metaTitle')}
        description={
          term ? term.get('metaDescription') : campaign.get('metaDescription')
        }
        keywords={
          term ? term.get('metaKeywords') : campaign.get('metaKeywords')
        }
        prefix="Guichet Store"
      />

      <div className="container">
        <header>
          <img
            className="img-fluid"
            src={campaign?.get('cover')}
            alt={`campaign-img-${campaign.get('id')}`}
          />
          <Link className="brand" to="/">
            <img
              src={campaign?.get('brand')?.get('logo')}
              alt={`brand-${campaign.get('id')}`}
            />
          </Link>
        </header>

        <div className="row mt-5">
          <div className="col-md-4">
            {!!terms && (
              <TermAccordions terms={terms} selectedTermId={term?.get('id')} />
            )}

            <div className="mt-4">
              {/* <p className="p-info">
                Frais de livraison à partir de : <b>5,50 €</b>
              </p> */}
              <p className="p-info">
                Jusqu'au{'   '}
                {moment(campaign.get('endAt'), 'DD/MM/YYYY HH:mm:ss').format(
                  'dddd, DD/MM à LT \\h',
                )}
              </p>
            </div>
          </div>

          <ProductsGrid
            campaignId={campaign.get('id')}
            termId={term?.get('id')}
          />
        </div>
      </div>
    </div>
  );
};

export default Catalog;
