export default {
  eventCategories: 'categories',
  themes: 'themes',
  slider: 'slides',
  events: 'events',
  pastEvents: 'events',
  event: 'event',
  signIn: 'users/login',
  forgotPassword: 'users/recover',
  recoverPassword: 'users/password/reset',
  signUp: 'users/register',
  socialSignIn: 'users/social/login',
  user: 'users/me',
  updateUser: 'users/update',
  cantact: 'contacts',
  userUpdate: 'users/update',
  signOut: 'users/logout',
  cart: 'cart',
  addToCart: 'cart/add',
  updateCart: 'cart/update',
  deleteSeat: 'cart/seat/delete',
  deleteCartIem: 'cart/delete',
  destroyCart: 'cart/destroy',
  applyDiscount: 'cart/discount/apply',
  clearDiscount: 'cart/discount/clear',
  countries: 'countries',
  cities: 'cities',
  addAddress: 'addresses/create',
  addresses: 'addresses',
  deleteAddress: 'addresses/delete',
  updateAddress: 'addresses/update',
  addShippingAddress: 'cart/shipping',
  checkout: 'cart/checkout',
  finalization: 'orders/confirmation',
  ordersCheck: 'orders/check',
  dynamicComponent: 'components',
  campaigns: 'campaigns',
  products: 'products',
  getOrders: 'orders',
  getArticles: 'articles',
  getTopics: 'topics',

  search: 'search',
  newsletters: 'newsletters/subscription',
};
