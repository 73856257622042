/* eslint-disable @typescript-eslint/no-explicit-any */
import { applyMiddleware, compose, createStore, Action } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import rootSaga from './sagas';
import { AppState } from './types';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

function configureStore() {
  // const middleware = [loggerMiddleware, thunkMiddleware];
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [sagaMiddleware];
  const middlewareEnhancer = applyMiddleware(...middleware);

  const enhancers = [
    middlewareEnhancer,
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
      : (f: any) => f,
  ];
  const composedEnhancers: any = compose(...enhancers);

  const store = createStore<AppState, Action<any>, any, any>(
    rootReducer,
    composedEnhancers,
  );

  sagaMiddleware.run(rootSaga);

  return store;
}

export default configureStore;
