import { all, fork } from 'redux-saga/effects';
import { simpleFetchSaga } from '../helpers/simple-fetch/saga';
import authenticationSaga from './authentication';
import cartSaga from './cart';
import addressesSaga from './addresses';

export default function* rootSaga() {
  yield all([
    fork(simpleFetchSaga),
    fork(authenticationSaga),
    // fork(eventSaga),
    fork(cartSaga),
    fork(addressesSaga),
  ]);
}
