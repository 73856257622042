/* eslint-disable no-restricted-syntax */
import { call, put, takeEvery, select } from 'redux-saga/effects';
import { arrayPush, arrayRemove, arrayRemoveAll, change } from 'redux-form';
import { List } from 'immutable';
import { getIsConnected } from '../selectors/authentification';
import { cartActions, CheckoutSteps, PaymentMethod } from '../types';
import { callApi, Error } from '../providers';
import {
  setInstruction,
  setPaymentInfo,
  checkoutFin,
  setSelectedAddress,
  setCities,
  setCountries,
  setCartData,
  cartLoadingEnd,
  cartFailed,
  cartSuccess,
  cartLoadingStart,
  destroyCart,
  toggleModalConnection,
  cartLoadingPageStart,
  cartLoadingPageEnd,
  setSelectedCarrier,
  setSelectedPayment,
  addShippingAddress,
} from '../actions';
import {
  getCartItems,
  getCart,
  getCartSelectedAddressId,
  getCartSelectedCarrierId,
  getCartSelectedPaymentId,
  getSelectedPaymentMehodById,
} from '../selectors/cart';
import {
  removePlanItem,
  checkPlanTokenExpiration,
  bookSeats,
  getCurrentCurrency,
} from '../helpers';
import { CartRecord, CartItemRecord } from '../models';

declare global {
  interface Window {
    fbq: any;
  }
}

function* getCartRequest({ payload }: any) {
  if (window.location.pathname === '/panier') yield put(cartLoadingPageStart());
  try {
    const { cart }: any = yield call(callApi, payload);

    if (cart) {
      yield put(arrayRemoveAll('CartForm', 'items'));
      yield put(setCartData(cart));

      for (let i = 0; i < cart.items.length; i++) {
        const item = cart.items[i];
        for (let k = 0; k < item.quantity; k++) {
          if (item.fields) {
            yield put(arrayPush('CartForm', `items[${i}].benefits`, {}));
          } else {
            yield put(arrayPush('CartForm', `items[${i}]`, {}));
          }
        }
      }

      // payment methods
      if (cart.paymentMethods && cart.paymentMethods.length > 0)
        yield put(setSelectedPayment(cart.paymentMethods[0].id));

      // set code promo
      if (cart.discount) {
        yield put(change('PromoForm', 'code_promo', cart.discount));
      }
    }
  } catch (error) {
    yield put(cartFailed(error));
  } finally {
    yield put(cartLoadingPageEnd());
  }
}

function* setCartRequest({ payload }: any) {
  yield put(cartLoadingStart());
  try {
    const { cart }: any = yield call(callApi, payload);

    window.fbq('track', 'AddToCart');

    if (cart) {
      // show modal<x
      if (payload.callback) payload.callback();

      yield put(arrayRemoveAll('CartForm', 'items'));
      yield put(setCartData(cart));

      for (let i = 0; i < cart.items.length; i++) {
        const item = cart.items[i];
        for (let k = 0; k < item.quantity; k++) {
          if (item.fields) {
            yield put(arrayPush('CartForm', `items[${i}].benefits`, {}));
          } else {
            yield put(arrayPush('CartForm', `items[${i}]`, {}));
          }
        }
      }

      // set code promo
      if (cart.discount) {
        yield put(change('PromoForm', 'code_promo', cart.discount));
      }
      const selectedAddressId = yield select(getCartSelectedAddressId);
      const selectedCarrierId = yield select(getCartSelectedCarrierId);
      yield put(addShippingAddress(selectedAddressId, selectedCarrierId));
    }
  } catch (error) {
    const {
      isPlan,
      eventPlanKey,
      postData: { seats },
    } = payload;
    if (isPlan) {
      yield call(removePlanItem, eventPlanKey, [seats]);
    }
    yield put(cartFailed(error));
  } finally {
    yield put(cartLoadingEnd());
  }
}

function* updateCartSaga({ payload }: any) {
  yield put(cartLoadingStart());
  const { itemIndex, quantity: newQuantity } = payload.postData;
  try {
    const { cart, message }: any = yield call(callApi, payload);
    const oldItems = yield select(getCartItems);
    const item = oldItems[itemIndex];
    const oldQuantity = item.quantity;

    if (cart) {
      yield put(setCartData(cart));
      if (item.fields) {
        if (newQuantity < oldQuantity) {
          for (let k = oldQuantity; k >= newQuantity; k--) {
            yield put(
              arrayRemove('CartForm', `items[${itemIndex}].benefits`, k),
            );
          }
        } else {
          for (let k = oldQuantity; k < newQuantity; k++) {
            yield put(
              arrayPush('CartForm', `items[${itemIndex}].benefits`, {}),
            );
          }
        }
      }

      // success
      if (message) yield put(cartSuccess(message));
      const selectedAddressId = yield select(getCartSelectedAddressId);
      const selectedCarrierId = yield select(getCartSelectedCarrierId);
      yield put(addShippingAddress(selectedAddressId, selectedCarrierId));
    }
  } catch (error) {
    yield put(cartFailed(error));
  } finally {
    yield put(cartLoadingEnd());
  }
}

function* deleteSeatSaga({ payload }: any) {
  yield put(cartLoadingStart());
  try {
    const { cart, message }: any = yield call(callApi, payload);

    if (cart) {
      yield put(setCartData(cart));
    }

    // success
    if (message) yield put(cartSuccess(message));
  } catch (error) {
    // yield put(cartFailed(error));
  } finally {
    yield put(cartLoadingEnd());
  }
}

function* deleteCartItem({ payload }: any) {
  yield put(cartLoadingStart());
  const { itemIndex } = payload;
  try {
    const { cart }: any = yield call(callApi, payload);

    if (cart) {
      const oldItems: any[] = yield select(getCartItems);
      const oldItem = oldItems[itemIndex];
      const {
        product: { isPlan, seatsChartId },
      } = oldItem;
      if (isPlan) {
        const { attributes } = oldItem;
        const seatsToDelete = attributes
          .find(att => att.name === 'Places')
          .value.split(',');
        yield call(removePlanItem, seatsChartId, seatsToDelete);
      }

      yield put(setCartData(cart));
      yield put(arrayRemove('CartForm', 'items', itemIndex));

      const selectedAddressId = yield select(getCartSelectedAddressId);
      const selectedCarrierId = yield select(getCartSelectedCarrierId);
      yield put(addShippingAddress(selectedAddressId, selectedCarrierId));
    }
  } catch (error) {
    if (error) {
      yield put(cartFailed(error));
    }
  } finally {
    yield put(cartLoadingEnd());
  }
}

function* applyDiscount({ payload }: any) {
  yield put(cartLoadingStart());
  try {
    const { cart, message }: any = yield call(callApi, payload);

    if (cart) {
      yield put(setCartData(cart));
      // success
      if (message) yield put(cartSuccess(message));
    }
  } catch (error) {
    yield put(cartFailed(error));
  } finally {
    yield put(cartLoadingEnd());
  }
}

function* clearDiscount({ payload }: any) {
  yield put(cartLoadingStart());
  try {
    const { cart, message }: any = yield call(callApi, payload);
    if (cart) {
      yield put(setCartData(cart));
      yield put(change('PromoForm', 'code_promo', null));
      // success
      if (message) yield put(cartSuccess(message));
    }
  } catch (error) {
    yield put(cartFailed(error));
  } finally {
    yield put(cartLoadingEnd());
  }
}

function* getCountries({ payload }: any) {
  try {
    const { countries }: any = yield call(callApi, payload);
    if (countries) {
      yield put(setCountries(countries));
    }
  } catch (error) {
    yield put(cartFailed(error));
  }
}

function* getCities({ payload }: any) {
  try {
    const { cities }: any = yield call(callApi, payload);
    if (cities) {
      yield put(setCities(cities));
    }
  } catch (error) {
    yield put(cartFailed(error));
  }
}

function* addShipping({ payload }: any) {
  const cartItems: CartItemRecord[] | null | undefined = yield select(
    getCartItems,
  );
  if (cartItems && cartItems.length > 0) {
    const isConnected = yield select(getIsConnected);
    if (isConnected) {
      try {
        // call api
        const { cart }: any = yield call(callApi, payload);

        if (cart) {
          // set selected address
          yield put(setSelectedAddress(payload.postData.delivery_address_id));
          // update cart
          yield put(setCartData(cart));

          if (payload.postData.carriere_id)
            yield put(setSelectedCarrier(payload.postData.carriere_id));
          else if (cart.carriers && cart.carriers.length > 0)
            yield put(setSelectedCarrier(cart.carriers[0].id));
        }
      } catch (error) {
        yield put(cartFailed(error));
      }
    }
  }
}

function* checkoutOrders({ payload }: any) {
  if (!payload.isConnected) {
    yield put(toggleModalConnection({ isVisible: true }));
  } else {
    yield put(cartLoadingStart());
    try {
      const cart: CartRecord = yield select(getCart);
      const hasPlan = cart?.get('hasPlan');
      if (hasPlan) {
        yield call(checkPlanTokenExpiration);
      }
      // call api
      const { params, order, step }: any = yield call(callApi, payload);

      // store reference
      document.cookie = `checkoutReference=${order?.reference}`;

      if (step === CheckoutSteps.PAYMENT && params) {
        // const { fpayUrl, orderId, refere ceId, trackId } = params;
        // redirects to FPay
        const paymentId = yield select(getCartSelectedPaymentId);
        const payment: PaymentMethod = yield select(
          getSelectedPaymentMehodById(paymentId),
        );
        if (payment.module === 'paydunya') {
          window.location.href = params.url;
        } else {
          yield put(setPaymentInfo(params));
          (<HTMLFormElement>document.getElementById('redirectForm')).submit();
        }
      } else if (step === CheckoutSteps.FIN) {
        window.fbq('track', 'Purchase', {
          value: cart.get('total'),
          currency: getCurrentCurrency(),
        });
        // finalisation
        yield put(checkoutFin(order?.reference));
      }
    } catch (error) {
      const err: Error = error;
      if (err.status === -2) {
        yield put(destroyCart());
      }
      yield put(cartFailed(error));

      // store reference
      if (error.data?.reference) {
        document.cookie = `checkoutReference=${error.data.reference}`;
      }

      //
      if (error.status === 401 || error.status === 403)
        yield put(toggleModalConnection(false));
    } finally {
      yield put(cartLoadingEnd());
    }
  }
}

function* checkoutFinalization({ payload }: any) {
  try {
    // call api
    const { instruction }: any = yield call(callApi, payload);
    if (instruction) {
      yield put(setInstruction(instruction));
      yield put(destroyCart());
    }
  } catch (error) {
    yield put(cartFailed(error));
  }
}

function* orderCheck({ payload }: any) {
  const cart: CartRecord = yield select(getCart);
  yield put(cartLoadingStart());
  try {
    // call api
    const { reference }: any = yield call(callApi, payload);
    window.fbq('track', 'Purchase', {
      value: cart?.get('total'),
      currency: getCurrentCurrency(),
    });

    // store reference
    document.cookie = `checkoutReference=${reference}`;

    // const cart: CartRecord = yield select(getCart);
    // const hasPlan = cart?.get('hasPlan');

    // if (hasPlan) {
    //   yield call(checkPlanTokenExpiration);
    //   const items = cart?.get('items');
    //   for (const item of items) {
    //     if (item.product.isPlan) {
    //       const places = item.attributes[1].value.split(',');
    //       yield call(bookSeats, item.product.seatsChartId, places);
    //     }
    //   }
    // }

    // destroy
    yield put(destroyCart());

    // finalisation
    yield put(checkoutFin(reference));
  } catch (error) {
    yield put(cartFailed(error));
  } finally {
    yield put(cartLoadingEnd());
  }
}

function* destroyCartSaga({ payload }: any) {
  try {
    // call api
    const { cart }: any = yield call(callApi, payload);
    if (cart) {
      yield put(arrayRemoveAll('CartForm', 'items'));
      yield put(setCartData(cart));
    }
  } catch (error) {
    yield put(cartFailed(error));
  }
}

export default function* cartSaga() {
  yield takeEvery(cartActions.CART_REQUEST, getCartRequest);
  yield takeEvery(cartActions.CART_ITEM_ADDED, setCartRequest);
  yield takeEvery(cartActions.CART_ITEM_UPDATED, updateCartSaga);
  yield takeEvery(cartActions.CART_ITEM_DELETED, deleteCartItem);
  yield takeEvery(cartActions.CART_ITEM_SEAT_DELETE, deleteSeatSaga);
  yield takeEvery(cartActions.CART_DESTROYED, destroyCartSaga);

  yield takeEvery(cartActions.APPLY_DISCOUNT, applyDiscount);
  yield takeEvery(cartActions.CLEAR_DISCOUNT, clearDiscount);
  yield takeEvery(cartActions.GET_COUNTRIES, getCountries);
  yield takeEvery(cartActions.GET_CITIES, getCities);
  yield takeEvery(cartActions.ADD_SHIPPING_ADDRESSES, addShipping);
  yield takeEvery(cartActions.CHECKOUT, checkoutOrders);
  yield takeEvery(cartActions.FINALIZATION, checkoutFinalization);
  yield takeEvery(cartActions.ORDERSCHECK, orderCheck);
}
