import { Record } from 'immutable';
import { CampaignForRec, CampaignsForRec } from '../types';

export class CampaignRecord extends Record<CampaignForRec>(
  {
    id: 0,
    title: null,
    slug: null,
    status: 0,
    theme: null,
    brand: null,
    description: null,
    cover: null,
    visual: null,
    startAt: null,
    endAt: null,
    isFuture: null,
    terms: null,

    metaTitle: null,
    metaDescription: null,
    metaKeywords: null,
  },
  'campaign',
) {}

export class CampaignsRecord extends Record<CampaignsForRec>(
  {
    success: false,
    currentCampaigns: null,
    futureCampaigns: null,
  },
  'campaigns',
) {}
