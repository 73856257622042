import React, { FunctionComponent } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ContactDispatchProps, ContactStateProps } from './types';
import { appActionKeys, AppState } from '../../types';
import {
  submitContact as submitContactAC,
  resetContact as resetContactAC,
  resetContactMessages as resetContactMessagesAC,
} from '../../actions';
import ContactC from '../../components/pages/Contact';
import Message from '../../components/common/Message';
import { simpleFetchResetMessages as simpleFetchResetMessagesAc } from '../../helpers';
import { getContactError, getContactSuccessMessage } from '../../selectors';

interface ContactProps extends ContactDispatchProps, ContactStateProps {}

const Contact: FunctionComponent<ContactProps> = ({
  submitContact,
  user,
  initialize,
  error,
  successMessage,
  simpleFetchResetMessages,
}: any) => {
  return (
    <>
      <Message
        success={
          successMessage
            ? {
                successMessage,
                resetSuccess: () => {
                  simpleFetchResetMessages(appActionKeys.CONTACT);
                },
              }
            : null
        }
        error={
          error
            ? {
                errorMessage: error.error,
                resetError: () => {
                  simpleFetchResetMessages(appActionKeys.CONTACT);
                },
              }
            : null
        }
      />
      <ContactC
        submitContact={submitContact}
        user={user}
        initialize={initialize}
      />
    </>
  );
};

/**
* Contact map state to props
*
* @param {Dispatch} dispatch
@returns {ContactDispatchProps}
*/
const mapDispatchToProps = (dispatch: Dispatch): ContactDispatchProps => {
  return bindActionCreators(
    {
      submitContact: submitContactAC,
      resetContact: resetContactAC,
      resetContactMessages: resetContactMessagesAC,
      simpleFetchResetMessages: simpleFetchResetMessagesAc,
    },
    dispatch,
  );
};

/**
 * Contact map state to props
 *
 * @param {AppState} appState
 * @returns {ContactStateProps}
 */
const mapStateToProps = (appState: AppState): ContactStateProps => {
  return {
    error: getContactError(appState),
    successMessage: getContactSuccessMessage(appState),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
