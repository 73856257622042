import { Reducer, combineReducers } from 'redux';
import { CategoryState, categoryActionsKeys } from '../types';
import { createSimpleFetchStore } from '../helpers';
import { CategoriesResponseRecord } from '../models';
import { serializeCategoriesResponse } from '../providers';

const categoryReducer: Reducer<CategoryState> = combineReducers<CategoryState>({
  eventCategoriesState: createSimpleFetchStore<CategoriesResponseRecord>(
    categoryActionsKeys.EVENT_CATEGORIES,
    serializeCategoriesResponse,
  ).reducer,
});

export default categoryReducer;
