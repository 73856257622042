import { createSelector } from 'reselect';
import { List } from 'immutable';
import { SimpleFetchStateRecord } from '../helpers';
import { AppState } from '../types';
import { SliderResponseRecord, SliderRecord } from '../models';
import { Error } from '../providers';

export const getSliderState = (
  appState: AppState,
): SimpleFetchStateRecord<SliderResponseRecord> => appState.slider;

//* ************************* BEGIN SLIDER DATA *************************** */

export const getSliderDataLoading = createSelector<AppState, any, boolean>(
  [getSliderState],
  (sliderState: SimpleFetchStateRecord<SliderResponseRecord>) =>
    sliderState.get('loading'),
);

export const getSliderDataRefreshing = createSelector<AppState, any, boolean>(
  [getSliderState],
  (sliderState: SimpleFetchStateRecord<SliderResponseRecord>) =>
    sliderState.get('refreshing'),
);

export const getSliderDataError = createSelector<AppState, any, Error | null>(
  [getSliderState],
  (sliderState: SimpleFetchStateRecord<SliderResponseRecord>) =>
    sliderState.get('error'),
);

export const getSliderDataList = createSelector<
  AppState,
  any,
  List<SliderRecord> | undefined | null
>(
  [getSliderState],
  (sliderState: SimpleFetchStateRecord<SliderResponseRecord>) =>
    sliderState.get('data')?.get('slides'),
);

//* ************************* END SLIDER DATA *************************** */
