import { Record } from 'immutable';
import { Address, AddressesData, AddressesListingForRec } from '../types';

/**
 * Address record
 *
 * @export
 * @class AddressRecord
 */
export class AddressRecord extends Record<Address>(
  {
    id: 0,
    name: null,
    firstName: null,
    lastName: null,
    phone: null,
    address: null,
    city: null,
    zipCode: null,
    latitude: null,
    longitude: null,
    country: null,
  },
  'address',
) {}

export class AddressesResponseRecord extends Record<AddressesListingForRec>(
  {
    list: null,
  },
  'address-list',
) {}

export class AddressDataRecord extends Record<AddressesData>(
  {
    addresses: null,
    loading: false,
    error: null,
    successMessage: null,
  },
  'addresses-data',
) {}
