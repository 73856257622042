/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, FunctionComponent, useEffect } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import EventSlider from '../EventSlider';
import BlocHeader from '../BlocHeader';
import Card from '../Card';
import BuyNow from '../BuyNow';
import { EventRecord } from '../../models';
import { formatPrice } from '../../helpers';
import CountDown from '../CountDown';
import ShareBlock from '../common/ShareBlock';
import SelectBox from '../SelectBox';

interface EventPageProps {
  event?: EventRecord | null;
  addToCart?: any | null;
  deleteSeat: (offerId: number, seats: string) => void;
  loading?: boolean;
  error?: any;
  cartHasPlan?: boolean;
  destroyCart?: () => void;
  cartLoading?: boolean;
}

const Event: FunctionComponent<EventPageProps> = ({
  event,
  addToCart,
  deleteSeat,
  loading,
  error,
  cartHasPlan,
  destroyCart,
  cartLoading,
}: EventPageProps) => {
  const history = useHistory();
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [selectedOfferIndex, setSelecteOfferdIndex] = useState<number>(0);
  const [quantity, setQuantity] = useState<number>(1);
  const [soldOut, setSoldOut] = useState<boolean>(true);

  const offers = event?.get('offers');

  useEffect(() => {
    if (error?.status === 404) history.replace('/404');
  }, [error, history]);

  useEffect(() => {
    if (offers) {
      offers.forEach((offer, index) => {
        if (offer.get('isDefault')) {
          setSelecteOfferdIndex(index);
        }
      });
    }
  }, [offers]);

  useEffect(() => {
    if (event) {
      setSoldOut(event.get('soldOut'));
    }
  }, [event]);

  if (!event || loading) return null;

  const similarEvents = event?.get('similarEvents');
  const images = event?.get('images');

  const buyNow = () => {
    if (!event.get('isPlan'))
      addToCart(
        quantity,
        offers.get(selectedOfferIndex).get('id'),
        toggleModal,
      );
    else toggleModal();
  };

  const onChangeQuantity = newQuantity => {
    setQuantity(newQuantity);
  };

  const toggleModal = () => {
    setModalOpened(!modalOpened);
  };

  const renderOffer = () => {
    const items = [];

    offers.forEach((offer, index) => {
      items.push({
        value: index.toString(),
        label: offer.get('title'),
        label2: `${formatPrice(offer.get('price'))}`,
      });
    });

    return (
      <SelectBox
        items={items}
        selectedIndex={selectedOfferIndex}
        handleSelect={item => {
          if (item.value) setSelecteOfferdIndex(item.value);
        }}
      />
    );
  };

  const renderQuantity = () => {
    return (
      <div className="col-5 pr-0">
        <div className="g-input mb-0">
          <input
            id="cart-quanity"
            onChange={e => onChangeQuantity(e.target.value)}
            value={quantity}
            style={{ height: 50 }}
          />
        </div>
        {/* <SelectBox
          items={[
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
            { value: '5', label: '5' },
            { value: '6', label: '6' },
            { value: '7', label: '7' },
            { value: '8', label: '8' },
            { value: '9', label: '9' },
            { value: '10', label: '10' },
          ]}
          handleSelect={item => {
            setQuantity(Number.parseFloat(item.value));
          }}
        /> */}
      </div>
    );
  };

  const renderSimilarEvents = () => {
    if (!similarEvents || similarEvents?.size <= 0) {
      return null;
    }
    return (
      <>
        <BlocHeader
          title="Autres événements"
          subtitle="Agenda exceptionnel"
          iconType="G"
        />
        <div className="row mb-5">
          {similarEvents.toArray().map(sEvent => (
            <div
              className="col-lg-4 col-md-6"
              key={`events-item-${sEvent.get('id')}`}
            >
              <Card event={sEvent} />
            </div>
          ))}
        </div>
      </>
    );
  };

  return (
    <div className="container">
      {/* Acheter maintenant */}
      <BuyNow
        title={event.get('title')}
        img={event.get('cover')}
        price={offers?.get(selectedOfferIndex)?.get('price')}
        quantity={quantity}
        isOpened={modalOpened}
        closeFunc={toggleModal}
        isPlanInfo={
          event.get('isPlan')
            ? {
                seatsChartId: event.get('seatsChartId'),
                addToCart,
                deleteSeat,
                destroyCart,
                cartHasPlan,
                offers,
              }
            : null
        }
      />

      <div className="event-title">
        <h1>{event.get('title')}</h1>
      </div>

      <div className="row mb-5">
        <div className="col-lg-8">
          {!!images && (
            <EventSlider
              title={event.get('title')}
              images={event.get('images').toArray()}
            />
          )}
        </div>
        <div className="col-lg-4">
          <div className="event-info">
            <img
              className="event-logo"
              onClick={() =>
                history.push(
                  `/producteur/${event.get('provider').get('domain')}`,
                )
              }
              src={event.get('provider').get('logo')}
            />

            <div className="event-date">
              {/* <label>À Partir de </label> */}
              <h5>
                {moment(event.get('expiredAt'), 'DD/MM/YYYY HH:mm:ss').format(
                  'dddd DD MMMM YYYY À HH:mm',
                )}
              </h5>
            </div>
            {!soldOut && !event.get('isPlan') && renderOffer()}
            <div className="row mb-3">
              {!soldOut && !event.get('isPlan') && renderQuantity()}
              <div
                className={
                  soldOut || event.get('isPlan') ? 'offset-2 col-8' : 'col-7'
                }
              >
                <button
                  onClick={buyNow}
                  disabled={soldOut || cartLoading}
                  className="i-button"
                >
                  {(soldOut && 'Guichet fermé') ||
                    (event.get('isPlan') && 'Acheter via plan') ||
                    'Acheter maintenant'}
                </button>
              </div>
            </div>
            {!soldOut && <p>Vite !! Achetez rapidement vos tickets</p>}
            <CountDown
              date={moment(
                event.get('expiredAt'),
                'DD/MM/YYYY HH:mm:ss',
              ).format('YYYY-MM-DD HH:mm:ss')}
              handleSoldOut={() => setSoldOut(true)}
            />
            {/* {!!event.get('expiredAt') && (
            )} */}
            <div className="mt-4">
              <ShareBlock
                title={event.get('title')}
                blockTitle="Partagez cet événement"
                light
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-5">
        <div className="col-lg-8 mb-3">
          <div className="description">
            <h3>
              <span>Description</span>
            </h3>
            <div
              dangerouslySetInnerHTML={{ __html: event.get('description') }}
            />
          </div>
        </div>
      </div>
      {renderSimilarEvents()}
    </div>
  );
};

export default Event;
