import React, { FunctionComponent } from 'react';
import { MagazineGrid } from '../../containers';
import AppMeta from '../common/AppMeta';

interface MagazinePageProps {
  topic: any;
}

const MagazinePage: FunctionComponent<MagazinePageProps> = ({
  topic,
}: MagazinePageProps) => {
  return (
    <>
      <AppMeta
        title="Actualités des festivals et recommandations"
        prefix="Guichet Magazine"
      />
      <MagazineGrid topic={topic} />
    </>
  );
};

export default MagazinePage;
