import React, { FunctionComponent, useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { CategoriesTabDispatchProps, CategoriesTabStateProps } from './types';
import CategoriesTabC from '../../components/CategoriesTab';
import { AppState } from '../../types';
import {
  fetchEventCategories as fetchEventCategoriesAC,
  resetEventCategoriesMessages as resetEventCategoriesMessagesAC,
  resetEventCategories as resetEventCategoriesAC,
  fetchThemes as fetchThemesAC,
  resetThemesMessages as resetThemesMessagesAC,
  resetThemes as resetThemesAC,
} from '../../actions';
import {
  getEventCategoriesList,
  getEventCategoriesLoading,
  getEventCategoriesError,
  getThemesList,
  getThemesLoading,
  getThemesError,
  getTopics,
} from '../../selectors';

interface CategoriesTabProps
  extends CategoriesTabDispatchProps,
    CategoriesTabStateProps {}

const CategoriesTab: FunctionComponent<CategoriesTabProps> = ({
  fetchEventCategories,
  categories,

  fetchThemes,
  themes,

  topics,
}: CategoriesTabProps) => {
  useEffect(() => {
    // fetchEventCategories();
    // fetchThemes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CategoriesTabC categories={categories} themes={themes} topics={topics} />
  );
};

/**
* CategoriesTab map state to props
*
* @param {Dispatch} dispatch
@returns {CategoriesTabDispatchProps}
*/
const mapDispatchToProps = (dispatch: Dispatch): CategoriesTabDispatchProps => {
  return bindActionCreators(
    {
      fetchEventCategories: fetchEventCategoriesAC,
      resetEventCategoriesMessages: resetEventCategoriesMessagesAC,
      resetEventCategories: resetEventCategoriesAC,
      fetchThemes: fetchThemesAC,
      resetThemesMessages: resetThemesMessagesAC,
      resetThemes: resetThemesAC,
    },
    dispatch,
  );
};

/**
 * CategoriesTab map state to props
 *
 * @param {AppState} appState
 * @returns {CategoriesTabStateProps}
 */
const mapStateToProps = (appState: AppState): CategoriesTabStateProps => {
  return {
    categories: getEventCategoriesList(appState),
    loading: getEventCategoriesLoading(appState) || getThemesLoading(appState),
    error: getEventCategoriesError(appState) || getThemesError(appState),
    themes: getThemesList(appState),
    topics: getTopics(appState),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesTab);
