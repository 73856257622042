import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Field } from 'redux-form';
import { InputTextField } from '../../common/fields';
import { SignInFormProps } from '../types';

interface LoginViewProps extends SignInFormProps {
  handleSubmit: (formData: any) => void;
  setIsForgotPassword?: any;
}

const LoginView: FunctionComponent<LoginViewProps> = ({
  handleSubmit,
  setIsForgotPassword,
}: LoginViewProps) => {
  return (
    <div className="contact-from">
      <form noValidate onSubmit={handleSubmit}>
        <div>
          <div className="row">
            <Field
              component={InputTextField}
              name="email"
              isRequired
              id="in-email"
              type="email"
              placeholder="Votre adresse e-mail"
            />
          </div>
          <div className="row">
            <Field
              component={InputTextField}
              name="password"
              isRequired
              id="in-pass"
              type="password"
              placeholder="Votre Mot de passe"
              nestedComp={
                <div className="mt-3 mb-3 text-right">
                  <button
                    onClick={() => setIsForgotPassword(true)}
                    className="forgot"
                    type="button"
                  >
                    Mot de passe oublié?
                  </button>
                </div>
              }
            />
          </div>

          <input className="g-submit" type="submit" value="Connexion" />
        </div>
      </form>
    </div>
  );
};
export default LoginView;
