/* eslint-disable no-restricted-syntax */
/* eslint-disable default-case */
/* eslint-disable no-case-declarations */
/* eslint-disable react/no-array-index-key */
import React, { FunctionComponent } from 'react';
import { Field } from 'redux-form';
import InputTextField from './input-text-field';
import InputRadioField from './input-radio-field';
import InputCheckboxField from './input-checkbox-field';
import SelectField from './select-field';
import InputImageField from './input-image-field';
import InputTextareaField from './input-textarea-field';
import { validationCartField } from '../../../helpers';

interface CartBenefitFieldsProps {
  benefitField: any;
  fields?: any[] | null;
}

const BenefitField = ({ field, benefitField, index }: any) => {
  const { name, type, label, validation, values } = field;
  let Comp;

  /**
   * Validation cart field
   *
   * @param {*} value
   * @param {any[]} validations
   * @returns {(string | undefined)}
   */
  const validationCart = (value: any): string | undefined => {
    return validationCartField(value, validation);
  };

  switch (type) {
    case 'text':
    case 'date':
      Comp = (
        <Field
          name={`${benefitField}.${name}`}
          key={`benefitField${index}`}
          component={InputTextField}
          className="col-sm-4 mt-2"
          placeholder={label}
          type={type}
          validate={validationCart}
        />
      );
      break;
    case 'radio':
      Comp = (
        <Field
          name={`${benefitField}.${name}`}
          key={`benefitField${index}`}
          wrapAllClass="d-flex"
          className="col-sm-4"
          component={InputRadioField}
          label={label}
          data={values}
          validate={validationCart}
        />
      );
      break;
    case 'checkbox':
      Comp = (
        <Field
          name={`${benefitField}.${name}`}
          key={`benefitField${index}`}
          wrapAllClass="d-flex"
          className="col-sm-4"
          component={InputCheckboxField}
          label={label}
          data={values}
          validate={validationCart}
        />
      );
      break;
    case 'select':
      Comp = (
        <Field
          name={`${benefitField}.${name}`}
          key={`benefitField${index}`}
          component={SelectField}
          label={label}
          data={values}
          valueSameAsText
          validate={validationCart}
        />
      );
      break;
    case 'image':
      Comp = (
        <Field
          name={`${benefitField}.${name}`}
          key={`benefitField${index}`}
          component={InputImageField}
          label={label}
          data={values}
          validate={validationCart}
        />
      );
      break;
    case 'textarea':
      Comp = (
        <Field
          name={`${benefitField}.${name}`}
          key={`benefitField${index}`}
          component={InputTextareaField}
          label={label}
          data={values}
          validate={validationCart}
        />
      );
      break;
    default:
      Comp = null;
  }
  return Comp;
};

const CartBenefitFields: FunctionComponent<CartBenefitFieldsProps> = ({
  benefitField,
  fields,
}: CartBenefitFieldsProps) => {
  return (
    <div>
      <div className="row">
        {fields.map((field, index) => (
          <BenefitField
            field={field}
            benefitField={benefitField}
            index={index}
            key={`benefitField${index}`}
          />
        ))}
      </div>
    </div>
  );
};

export default CartBenefitFields;
