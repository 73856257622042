import React, { FunctionComponent, useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PastEventsGridDispatchProps, PastEventsGridStateProps } from './types';
import PastEventsGridC from '../../components/PastEventsGrid';
import { AppState } from '../../types';
import {
  fetchPastEvents as fetchPastEventsAC,
  resetPastEventsMessages as resetPastEventsMessagesAC,
  resetPastEvents as resetPastEventsAC,
} from '../../actions';
import {
  getPastEventsList,
  getPastEventsLoading,
  getPastEventsRefreshing,
  getPastEventsError,
  getPastEventsPagination,
} from '../../selectors';

interface PastEventsGridProps
  extends PastEventsGridDispatchProps,
    PastEventsGridStateProps {
  categoryId: number;
}

const PastEventsGrid: FunctionComponent<PastEventsGridProps> = ({
  fetchPastEvents,
  resetPastEvents,
  events,
  pagination,
  categoryId,
}: PastEventsGridProps) => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const page = Number(params.get('page')) || 1;

  useEffect(() => {
    try {
      fetchPastEvents(categoryId, page);
    } catch {
      throw Error('Error while fetch past events');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    return () => {
      resetPastEvents();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PastEventsGridC events={events} pagination={pagination} page={page || 1} />
  );
};

/**
* PastEventsGrid map state to props
*
* @param {Dispatch} dispatch
@returns {PastEventsGridDispatchProps}
*/
const mapDispatchToProps = (
  dispatch: Dispatch,
): PastEventsGridDispatchProps => {
  return bindActionCreators(
    {
      fetchPastEvents: fetchPastEventsAC,
      resetPastEventsMessages: resetPastEventsMessagesAC,
      resetPastEvents: resetPastEventsAC,
    },
    dispatch,
  );
};

/**
 * PastEventsGrid map state to props
 *
 * @param {AppState} appState
 * @returns {PastEventsGridStateProps}
 */
const mapStateToProps = (appState: AppState): PastEventsGridStateProps => {
  return {
    events: getPastEventsList(appState),
    loading: getPastEventsLoading(appState),
    refreshing: getPastEventsRefreshing(appState),
    error: getPastEventsError(appState),
    pagination: getPastEventsPagination(appState),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PastEventsGrid);
