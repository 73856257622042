/* eslint-disable react/no-array-index-key */
import React, { useRef } from 'react';
import Icon from 'react-remixicon';
import { Link } from 'react-router-dom';
import { SeatsioSeatingChart } from '@seatsio/seatsio-react';
import { toast } from 'react-toastify';
import { formatPrice, SEATSIOS_WORKSPACE } from '../helpers';
import 'react-toastify/dist/ReactToastify.css';

interface BuyNowProps {
  title?: string;
  img?: string;
  price?: number;
  quantity?: number;
  isOpened?: boolean;
  closeFunc?: any;
  isPlanInfo?: any;
}

const BuyNow = ({
  title,
  img,
  price,
  quantity,
  isOpened,
  closeFunc,
  isPlanInfo = null,
}: BuyNowProps) => {
  const chartRef = useRef(null);

  return (
    <div className={`buy-now-modal ${isOpened}`}>
      <div className="box">
        <button onClick={closeFunc} className="close">
          <Icon name="close" type="fill" />
        </button>

        {isPlanInfo && (
          <SeatsioSeatingChart
            ref={chartRef}
            workspaceKey={SEATSIOS_WORKSPACE}
            event={isPlanInfo.seatsChartId}
            region="eu"
            session="continue"
            onObjectSelected={({ id, category: { key } }) =>
              isPlanInfo.addToCart(
                1,
                key,
                undefined,
                undefined,
                undefined,
                id,
                isPlanInfo.seatsChartId,
              )
            }
            onObjectDeselected={({ id, category: { key } }) =>
              isPlanInfo.deleteSeat(key, id)
            }
            onHoldTokenExpired={() => {
              chartRef.current.chart.startNewSession();
              if (isPlanInfo.cartHasPlan && isPlanInfo.destroyCart) {
                toast('Votre sélection de places est expirée.', {
                  type: 'warning',
                });
                isPlanInfo.destroyCart();
              }
            }}
            pricing={isPlanInfo.offers.map(({ id, price: offerPrice }) => ({
              category: id,
              price: formatPrice(offerPrice),
            }))}
          />
        )}

        {!isPlanInfo && (
          <div className="row align-items-center">
            <div className="col-md-5">
              <img className="img-fluid" src={img} alt={`event-img-${img}`} />
            </div>
            <div className="col-md-7 text-center">
              <h3>{title}</h3>
              {/* <p className="mb-5">
              Evénement ajouté avec succès <br /> à votre panier
            </p> */}

              <table>
                <thead>
                  <tr>
                    <th>Prix</th>
                    <th>Quantité</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{formatPrice(price)}</td>
                    <td>{quantity}</td>
                    <td>{formatPrice(price * quantity)}</td>
                  </tr>
                </tbody>
              </table>

              <div className="actions">
                <button onClick={closeFunc}>Continuer mes achats</button>
                <Link to="/panier">Finaliser ma commande</Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BuyNow;
