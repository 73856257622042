import { Reducer, combineReducers } from 'redux';
import { CartState } from '../../types';
import cartManagementReducer from './cart-management';
import cartDataReducer from './cart-data';

const cartReducer: Reducer<CartState> = combineReducers<CartState>({
  cartDataState: cartDataReducer,
  cartManagement: cartManagementReducer,
});

export default cartReducer;
