/* eslint-disable react/no-danger */
import React, { FunctionComponent } from 'react';
import { Field, FieldArray } from 'redux-form';
import { formatPrice, required, getCurrentCurrency } from '../../../helpers';
import { CartItemsFieldArray, InputCheckboxField } from '../../common/fields';
import AddressesComposedForm from './addresses';
import PromoComposedForm from './promo';

interface CartFormViewProps {
  items?: any[] | null;
  cart: any;
  isConnected: any;
  submitForm: any;

  adresses: any;
  setSelectedPayment: any;
  selectedPaymentId: any;
  selectedCarrierId: any;
  selectedAddressId: any;
  carriers: any;
  paymentMethods: any;
  addShippingAddress: any;
}

const CartFormView: FunctionComponent<CartFormViewProps> = ({
  items,
  cart,
  isConnected,
  submitForm,

  adresses,
  setSelectedPayment,
  selectedCarrierId,
  selectedPaymentId,
  selectedAddressId,
  carriers,
  paymentMethods,
  addShippingAddress,
}: CartFormViewProps) => {
  const selectShippingAddress = (carrierId: number) => {
    if (selectedAddressId) {
      addShippingAddress(selectedAddressId, carrierId);
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-8">
          {/* Cart Items */}
          <div className="panier box mb-3">
            <div className="heading mb-0">
              <h4>Mon panier</h4>
            </div>

            {/* Items */}
            <FieldArray
              name="items"
              component={CartItemsFieldArray}
              items={items}
            />
          </div>

          {/* Addresses */}
          {isConnected && carriers && carriers.length > 0 && (
            <AddressesComposedForm isCart />
          )}

          {/* mode de livraison */}
          {isConnected &&
            adresses &&
            adresses.length > 0 &&
            carriers &&
            carriers.length > 0 && (
              <div className="box mode-livraison mb-3">
                <div className="heading">
                  <h4>Mode de livraison</h4>
                  <p>Sélectionez votre moyen de livraison</p>
                </div>

                <div>
                  {carriers.map(carrier => (
                    <div key={`carrier-${carrier.id}`}>
                      <div className="mb-4 d-flex align-items-center justify-content-between">
                        <label className="radio-container mr-3">
                          {`${carrier.title} (${carrier.delay})`}
                          <input
                            type="radio"
                            id={`radio${carrier.id}`}
                            value={carrier.id}
                            name="carrier_id"
                            onChange={() => selectShippingAddress(carrier.id)}
                            checked={selectedCarrierId === carrier.id}
                          />
                          <span className="checkmark" />
                        </label>
                        <label>
                          {carrier.price
                            ? `${formatPrice(carrier.price)}`
                            : 'OFFERTE'}
                        </label>
                      </div>
                      {selectedCarrierId === carrier.id && carrier.description && (
                        <div
                          className="hint"
                          dangerouslySetInnerHTML={{
                            __html: carrier.description,
                          }}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}

          {/* mode de paiement */}
          {isConnected && paymentMethods && paymentMethods.length > 0 && (
            <div className="box mode-paiement mb-3">
              <div className="heading">
                <h4>Mode de paiement</h4>
                <p>Sélectionez votre moyen de paiement</p>
              </div>

              {paymentMethods.map(payment => (
                <div key={`payment-${payment.id}`}>
                  <div className="">
                    <label className="radio-container mr-3">
                      {payment.title}
                      <input
                        type="radio"
                        id={`radio${payment.id}`}
                        value={payment.id}
                        name="payment-methods"
                        onChange={() => setSelectedPayment(payment.id)}
                        checked={selectedPaymentId === payment.id}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  {selectedPaymentId === payment.id && payment.description && (
                    <div
                      className="hint"
                      dangerouslySetInnerHTML={{
                        __html: payment.description,
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="col-lg-4">
          {/* Code promo */}
          <PromoComposedForm />

          {/* TOTAL */}
          <div className="box total-box mb-3">
            <div className="heading">
              <h4>TOTAL</h4>
            </div>
            <div className="mb-2 d-flex align-items-center justify-content-between">
              <p>Total des articles</p>
              <p>{formatPrice(cart?.subTotal)}</p>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <p>Frais de livraison</p>
              <p>
                {cart?.totalShipping
                  ? `${formatPrice(cart?.totalShipping)}`
                  : `${getCurrentCurrency()}`}
              </p>
            </div>
            {!!cart?.totalDiscount && (
              <div className="mt-2 d-flex align-items-center justify-content-between">
                <p>Remise</p>
                <p>{cart?.totalDiscountString}</p>
              </div>
            )}

            <hr />
            <div className="d-flex align-items-center justify-content-between">
              <p>Total à payer:</p>
              <h4>{formatPrice(cart?.total)}</h4>
            </div>
            <hr />

            {/* accept terms */}
            <div className="mb-4">
              <Field
                acceptTerms
                name="acceptTerms"
                component={InputCheckboxField}
                validate={required}
                data={{
                  value: 'accepted',
                }}
              />
            </div>

            <button
              className="mb-3 green"
              onClick={() => submitForm('CartForm')}
            >
              Passer ma commande
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartFormView;
