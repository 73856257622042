import React, { FunctionComponent, useEffect, useState } from 'react';
import Icon from 'react-remixicon';
import { Field } from 'redux-form';

import { required } from '../../../helpers';
import { InputTextField, SelectField } from '../../common/fields';
import LoadingPopup from '../../common/LoadingPopup';
import Message from '../../common/Message';

const AddressesFormView: FunctionComponent<any> = ({
  handleSubmit,
  getAdresses,
  getCountries,
  getCities,
  countries,
  cities,
  addressFormData,
  addAddress,
  updateAddress,
  deleteAddress,
  adresses,
  initialize,
  addShippingAddress,
  selectedAddressId,

  carriers,
  isCart,

  user,
  isConnected,

  loading,
  successMessage,
  errorAd,
  addressesResetMessages,
}: any) => {
  const [isAddressModal, setIsAddressModal] = useState(false);
  const [editAddress, setEditAddress] = useState(null);
  const { country: selectedCountry } = addressFormData || {};

  const showAddressModal = (addressV: any) => {
    initialize({
      ...addressFormData,
      country: JSON.stringify({
        text: 'Maroc',
        value: countries?.find(c => c.name === 'Maroc')?.id,
      }),
      city: null,
      lastName: isConnected && user ? user.lastName : null,
      firstName: isConnected && user ? user.firstName : null,
      phone: isConnected && user ? user.phone : null,
      zipCode: null,
      address: null,
      name: null,
    });
    if (addressV) {
      setEditAddress(addressV);
      initialize({
        ...addressV,
        country: JSON.stringify({
          text: addressV.country.name,
          value: addressV.country.id,
        }),
      });
    }
    setIsAddressModal(true);
  };

  const hideAddressModal = () => {
    setEditAddress(null);
    setIsAddressModal(false);
  };

  const deleteAddressFunc = (addressId: number) => {
    deleteAddress(addressId);
  };

  const selectShippingAddress = (event: any) => {
    const delivery_address_id = Number.parseFloat(event.target.value);
    addShippingAddress(delivery_address_id);
  };

  useEffect(() => {
    getAdresses();
    getCountries();
    getCities();
  }, []);

  useEffect(() => {
    if (isCart && adresses && adresses.length > 0) {
      addShippingAddress(adresses[0].id);
    }
  }, [adresses]);

  const mySubmitHandler = data => {
    const {
      name,
      city,
      country,
      address,
      lastName,
      firstName,
      zipCode,
      phone,
    } = data;
    if (editAddress === null)
      addAddress(
        {
          name,
          city,
          country_id: JSON.parse(country).value,
          address,
          last_name: lastName,
          first_name: firstName,
          zipcode: zipCode,
          phone,
        },
        hideAddressModal,
        isCart,
      );
    else
      updateAddress(
        data.id,
        {
          address,
          city,
          country_id: JSON.parse(country).value,
          last_name: lastName,
          first_name: firstName,
          name,
          phone,
          zipcode: zipCode,
        },
        hideAddressModal,
      );
  };

  const addressModal = () => (
    <>
      <form
        noValidate
        onSubmit={handleSubmit(data => {
          mySubmitHandler(data);
        })}
        className={`address-modal modal ${isAddressModal}`}
      >
        <div className="back" onClick={hideAddressModal} />
        <div className="my-container small-container">
          <section>
            <LoadingPopup loading={loading} />

            <div className="row">
              <Field
                name="name"
                component={InputTextField}
                type="text"
                placeholder="Nom d'adresse"
                className="col-sm-4 col-6 mt-2"
                validate={required}
              />
              <Field
                name="country"
                component={SelectField}
                type="text"
                label="Pays"
                data={countries?.map(c => ({
                  text: c.name,
                  value: c.id,
                }))}
                otherOnChange={() => {
                  initialize({
                    ...addressFormData,
                    city: '',
                  });
                }}
                className="col-sm-4 col-6 mt-2"
                validate={required}
              />
              {!selectedCountry ||
              JSON.parse(selectedCountry)?.text !== 'Maroc' ? (
                <Field
                  name="city"
                  component={InputTextField}
                  type="text"
                  placeholder="Ville"
                  className="col-sm-4 col-6 mt-2"
                  validate={required}
                />
              ) : (
                <Field
                  name="city"
                  component={SelectField}
                  type="text"
                  label="Ville"
                  valueSameAsText
                  data={cities?.map(c => c.name)}
                  className="col-sm-4 col-6 mt-2"
                  validate={required}
                />
              )}

              <Field
                name="address"
                component={InputTextField}
                type="text"
                placeholder="Adresse"
                className="col-sm-4 col-6 mt-2"
                validate={required}
              />
              <Field
                name="lastName"
                component={InputTextField}
                type="text"
                placeholder="Nom"
                className="col-sm-4 col-6 mt-2"
                validate={required}
              />
              <Field
                name="firstName"
                component={InputTextField}
                type="text"
                placeholder="Prénom"
                className="col-sm-4 col-6 mt-2"
                validate={required}
              />
              <Field
                name="zipCode"
                component={InputTextField}
                type="text"
                placeholder="Code zip"
                className="col-sm-4 col-6 mt-2"
              />
              <Field
                name="phone"
                component={InputTextField}
                type="text"
                placeholder="Téléphone"
                className="col-sm-4 col-6 mt-2"
                validate={required}
              />
            </div>
            <div className="mt-3">
              <button
                type="submit"
                className={`${editAddress === null ? 'blue' : 'green'}`}
              >
                {editAddress === null ? 'Ajouter' : 'Modifier'}
              </button>
            </div>
          </section>
        </div>
      </form>
    </>
  );

  return (
    <>
      <Message
        success={
          successMessage
            ? {
                successMessage,
                resetSuccess: () => addressesResetMessages(),
              }
            : null
        }
        error={
          errorAd
            ? {
                errorMessage: errorAd.error,
                resetError: () => addressesResetMessages(),
              }
            : null
        }
      />

      {!isCart || (isCart && carriers && carriers.length > 0) ? (
        <>
          {addressModal()}

          <div className="address-box box mb-3">
            <LoadingPopup loading={loading} />

            <div className="heading mb-0">
              <h4>Adresses</h4>
            </div>
            {/* adresses */}
            {adresses && adresses.length > 0 && (
              <div className="table-responsive">
                <table className="mt-3 table">
                  <thead>
                    <tr>
                      {isCart && (
                        <th scope="col">
                          <span className="d-none">.</span>
                        </th>
                      )}
                      <th scope="col">Nom</th>
                      <th scope="col">Prénom</th>
                      <th scope="col">Téléphone</th>
                      <th scope="col">Adresse</th>
                      <th scope="col">
                        <span className="d-none">.</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody onChange={selectShippingAddress}>
                    {adresses.map(adr => (
                      <tr key={adr.id}>
                        {isCart && (
                          <td>
                            <label className="radio-container">
                              <input
                                type="radio"
                                name="selected-address"
                                value={`${adr.id}`}
                                checked={selectedAddressId === adr.id}
                              />
                              <span className="checkmark" />
                            </label>
                          </td>
                        )}

                        <td>{adr.lastName}</td>
                        <td>{adr.firstName}</td>
                        <td>{adr.phone}</td>
                        <td>{adr.address}</td>
                        <td className="d-flex justify-content-end">
                          {!isCart && (
                            <button onClick={() => showAddressModal(adr)}>
                              <Icon name="pencil" type="line" />
                            </button>
                          )}
                          <button onClick={() => deleteAddressFunc(adr.id)}>
                            <Icon name="close" type="line" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            <div className="d-flex">
              <button className="blue" onClick={() => showAddressModal(null)}>
                <Icon name="add" type="line" /> Ajouter une adresse
              </button>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default AddressesFormView;
