/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { useLocation } from 'react-router-dom';
import * as QueryString from 'query-string';
import iconTickets from '../../images/icon-tickets.png';
import iconQuality from '../../images/icon-quality.png';
import iconSupport from '../../images/icon-support.png';
import { HomeSlider, EventsGrid, HomeCampaignsGrid } from '../../containers';
import AppMeta from '../common/AppMeta';

const Home = () => {
  const { search } = useLocation();
  const { oauth_token } = QueryString.parse(search);

  if (oauth_token) return <div className="oauth-loading" />;

  return (
    <div>
      <AppMeta title="Ticket et Billetterie au Maroc" isHome />

      <HomeSlider />
      <EventsGrid hasHeader={false} />
      <HomeCampaignsGrid />

      <section className="features">
        <div className="container">
          <div className="row mb-4">
            <div className="col-sm-4">
              <div className="g-info">
                <img src={iconTickets} alt="" />
                <h3>
                  Achetez <strong>des tickets</strong>
                </h3>
                <p>
                  Achetez des tickets de qualité pour les meilleurs événements
                  en toute sécurité !
                </p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="g-info">
                <img src={iconQuality} alt="" />
                <h3>
                  Notre <strong>garantie 100 %</strong>
                </h3>
                <p>
                  Éliminez les risques et assurez-vous une transaction sécurisée
                  et protégée en faisant affaire avec guichet.com
                </p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="g-info">
                <img src={iconSupport} alt="" />
                <h3>
                  Support <strong>24/24H</strong>
                </h3>
                <p>+212 6 45 765 765 / sav@guichet.ma</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
