import { Record } from 'immutable';
import { PaymentMethod } from '../types';

/**
 * Payement method record
 *
 * @export
 * @class PaymentMethodRecord
 * @extends {Record<PaymentMethod>()}
 */
export class PaymentMethodRecord extends Record<PaymentMethod>(
  {
    id: 0,
    title: null,
    description: null,
    module: null,
  },
  'payment-method',
) {}
