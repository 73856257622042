/* eslint-disable import/extensions */
/* eslint-disable import/no-useless-path-segments */
import React, { FunctionComponent, useEffect, useState } from 'react';
import 'moment/locale/fr';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import '../styles/App.scss';

import { ToastContainer } from 'react-toastify';
// import Zendesk from 'react-zendesk';
import { TawkTo } from '../components/common/TawkTo';
import Header from './common/Header';
import Footer from './common/Footer';
import {
  GUICHET_ROUTES,
  APP_SECURES_ROUTES,
  APP_ROUTES,
  ROUTES_PATHS,
  getStorageData,
  STORAGE_COUNTRIES,
} from '../helpers';
import PrivateRoute from './common/PrivateRoute';
import NoMatchedRoute from './pages/NoMatchedRoute';
import GStoreApp from './GStoreApp';
import { DynamicComponent } from '../containers';
import CookieReminder from './common/CookieReminder';
import { ToggleModalConnectionPayload } from '../types';
import ScrollToTop from './common/ScrollToTop';
import RecoverPasswordPage from './pages/RecoverPasswordPage';

interface AppProps {
  isConnected: boolean;
  authLoading: boolean;
  toggleModalConnection: (payload?: ToggleModalConnectionPayload) => void;
  fetchCountries: () => void;
  countryIsLoading: boolean;
}

// const settingZenDesk = {
//   color: {
//     theme: '#000',
//   },
//   launcher: {
//     chatLabel: {
//       'en-US': 'Need Help',
//     },
//   },
//   contactForm: {
//     fields: [
//       { id: 'description', prefill: { '*': 'My pre-filled description' } },
//     ],
//   },
// };

const App: FunctionComponent<AppProps> = ({
  isConnected,
  toggleModalConnection,
  authLoading,
  countryIsLoading,
  fetchCountries,
}: AppProps) => {
  const [countriesIsChecking, setCountriesIsChecking] = useState<boolean>(true);

  // const matcherCategoryPage = ({ match }) => {
  //   const newMatch: Match<CategoryPageParams> = match;
  //   if (newMatch?.path === ROUTES_PATHS.category) {
  //     if (newMatch?.params) {
  //       const { category } = newMatch.params;
  //       if (^
  //         NO_MATCHED_APP_ROUTES_PATHS.findIndex(
  //           (path) => path === `/${category}`,
  //         ) !== -1
  //       ) {
  //         return null;
  //       }
  //     }
  //     return <Sport />;
  //   }
  //   return undefined;
  // };

  useEffect(() => {
    const tawk = new TawkTo('61def611f7cf527e84d1cbc3', '1fp7f2d21', 4);
    const localCountries = getStorageData(STORAGE_COUNTRIES, true);
    if (!localCountries) {
      fetchCountries();
    }
    setCountriesIsChecking(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (countriesIsChecking || countryIsLoading) return null;

  return (
    <Router>
      <div className="App">
        <ToastContainer />
        <CookieReminder />
        {/* <Zendesk
          defer
          // zendeskKey={ZENDESK_KEY}
          {...settingZenDesk}
          onLoaded={() => console.log('is loaded')}
        /> */}
        <Header />
        <main>
          <ScrollToTop />
          <Switch>
            {/* <Route exact path={ROUTES_PATHS.home} component={Home} /> */}

            {APP_ROUTES.map(rest => (
              <Route {...rest} exact key={`app-routes-${rest.path}`} />
            ))}
            {GUICHET_ROUTES.map(rest => (
              <Route {...rest} exact key={`guichet-routes-${rest.path}`} />
            ))}
            {APP_SECURES_ROUTES.map(rest => {
              return (
                <PrivateRoute
                  toggleModalConnection={toggleModalConnection}
                  exact
                  isConnected={isConnected}
                  {...rest}
                  key={`app-secure-routes-${rest.path}`}
                  authLoading={authLoading}
                />
              );
            })}

            <Route
              path="/password/reset/:token"
              component={() => (
                <RecoverPasswordPage isConnected={isConnected} />
              )}
            />

            <Route path="/store" component={GStoreApp} />
            <Route path={ROUTES_PATHS.dynamic} component={DynamicComponent} />
            <Route path="*" component={NoMatchedRoute} />
          </Switch>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
