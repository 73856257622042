import { reduxForm, FormErrors } from 'redux-form';
import FormView from './view';
import { formsNames, fieldErrors } from '../constants';
import { SignUpFormData, SignUpFormProps } from '../types';
import { regularExpresions } from '../../../helpers';

const validate = ({
  email,
  password,
  confirmPassword,
  firstName,
  lastName,
  phone,
}: SignUpFormData): FormErrors<SignUpFormData> => {
  const errors: FormErrors<SignUpFormData> = {};
  if (!email || email.trim() === '') {
    errors.email = fieldErrors.REQUIRED;
  } else if (!regularExpresions.EMAIL.test(email)) {
    errors.email = fieldErrors.EMAIL;
  }

  if (!password || password.trim() === '') {
    errors.password = fieldErrors.REQUIRED;
  } else if (password.length < 8) {
    errors.password = fieldErrors.PASSWORD;
  }

  if (!confirmPassword || confirmPassword.trim() === '') {
    errors.confirmPassword = fieldErrors.REQUIRED;
  } else if (confirmPassword.length < 8) {
    errors.confirmPassword = fieldErrors.PASSWORD;
  } else if (password !== confirmPassword) {
    errors.confirmPassword = fieldErrors.PASSWORD_CONFIRM;
  }

  if (!firstName || firstName.trim() === '') {
    errors.firstName = fieldErrors.REQUIRED;
  }

  if (!lastName || lastName.trim() === '') {
    errors.lastName = fieldErrors.REQUIRED;
  }

  if (!phone || phone.trim() === '') {
    errors.phone = fieldErrors.REQUIRED;
  } else if (!regularExpresions.PHONE.test(phone)) {
    errors.phone = fieldErrors.PHONE;
  }
  return errors;
};

const SignUpForm = reduxForm<SignUpFormData, SignUpFormProps>({
  form: formsNames.SIGN_UP,
  validate,
})(FormView);

export default SignUpForm;
