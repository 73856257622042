import { List } from 'immutable';
import { createSelector } from 'reselect';
import { SimpleListingStateRecord } from '../helpers';
import { SearchRecord } from '../models';
import { AppState, SearchState } from '../types';

export const getSearchState = (state: AppState): SearchState => state.search;

//* *********************  START SEARCH STATE  ****************** */

export const getSearchListingState = createSelector<
  AppState,
  any,
  SimpleListingStateRecord<SearchRecord>
>(
  [getSearchState],
  (searchState: SearchState) => searchState.searchListingState,
);

export const getSearchList = createSelector<
  AppState,
  any,
  List<SearchRecord> | undefined | null
>(
  [getSearchListingState],
  (searchListingState: SimpleListingStateRecord<SearchRecord>) =>
    searchListingState.get('list'),
);

export const getSearchListLoading = createSelector<
  AppState,
  any,
  boolean | undefined | null
>(
  [getSearchListingState],
  (searchListingState: SimpleListingStateRecord<SearchRecord>) =>
    searchListingState.get('loading'),
);

export const getSearchListInfinitLoading = createSelector<
  AppState,
  any,
  boolean | undefined | null
>(
  [getSearchListingState],
  (searchListingState: SimpleListingStateRecord<SearchRecord>) =>
    searchListingState.get('infinitLoading'),
);

export const getSearchListError = createSelector<
  AppState,
  any,
  any | undefined | null
>(
  [getSearchListingState],
  (searchListingState: SimpleListingStateRecord<SearchRecord>) =>
    searchListingState.get('error'),
);

export const getSearchPagination = createSelector<
  AppState,
  any,
  any | undefined | null
>(
  [getSearchListingState],
  (searchListingState: SimpleListingStateRecord<SearchRecord>) =>
    searchListingState.get('pagination'),
);

//* *********************  END SEARCH STATE  ****************** */
