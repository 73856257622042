/* eslint-disable no-useless-escape */
/* eslint-disable no-restricted-syntax */
import { List } from 'immutable';
import { createAction } from 'redux-actions';
import { SeatsioClient, Region } from 'seatsio';
import { AuthActionTypes, Country } from '../types';
import { fieldErrors } from '../components/forms/constants';
import { SEATSIOS_SECRET_KEY, STORAGE_COUNTRY } from './contants';
import { Error } from '../providers';

/**
 * get String between two string
 *
 * @param {string} stringTo
 * @param {string} stringBetween1
 * @param {string} stringBetween2
 * @param {boolean} include
 * @returns
 */
export const getStringBetween = (
  stringTo: string,
  stringBetween1: string,
  stringBetween2: string,
  include: boolean,
) => {
  const stringBetween1C = stringTo.includes(stringBetween1)
    ? stringBetween1
    : '';
  const stringBetween2C = stringTo.includes(stringBetween2)
    ? stringBetween2
    : '';
  return stringTo.substring(
    stringTo.lastIndexOf(stringBetween1C) +
      (include ? 0 : stringBetween1C.length),
    stringTo.lastIndexOf(stringBetween2C) +
      (include ? stringBetween2C.length : 0),
  );
};

/**
 * Serialize any list to immutable list
 *
 * @export
 * @template InputType
 * @template OutPutType
 * @param {(InputType[] | null)} list
 * @param {(data: InputType) => OutPutType} serializer
 * @returns {(List<OutPutType> | null)}
 */
export function serializeList<InputType, OutPutType>(
  list: InputType[] | null,
  serializer: (data: InputType) => OutPutType,
): List<OutPutType> | null {
  let newList: List<OutPutType> | null = null;
  if (list) {
    const recordList: OutPutType[] = list.map(item => serializer(item));
    newList = List<OutPutType>(recordList);
  }
  return newList;
}

export /**
 * Get Cookie
 *
 * @param {string} cname
 * @return {*}  {string}
 */
const getCookie = (cname: string): string => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

/**
 * Format price
 *
 * @param {number} price
 * @returns {string}
 */
export const formatPrice = (price: number, hideCurrency?: boolean): string => {
  try {
    const country: Country = getStorageData(STORAGE_COUNTRY, true);
    const currencyCode = country ? ` ${country?.currencyCode}` : 'MAD';
    const formatter = new Intl.NumberFormat('fr-FR', {});
    return formatter.format(price) + (hideCurrency ? '' : currencyCode);
  } catch {
    return '';
  }
};

export const getCurrentCurrency = (): string => {
  try {
    const country: Country = getStorageData(STORAGE_COUNTRY, true);
    return country ? country.currencyCode : 'MAD';
  } catch {
    return '';
  }
};

/**
 * Format date number
 *
 * @param {number} n
 * @returns
 */
export const formatDateNumber = (n: number): string => {
  const numString = `${n}`;
  if (numString.length < 3) {
    return `0${n}`.slice(-2);
  }
  return `${n}`;
};

/**
 * Create auth action type
 *
 * @export
 * @template PAYLOAD
 * @param {AuthActionTypes} type
 * @returns {string}
 */
// eslint-disable-next-line import/prefer-default-export
export function createAuthAction<PAYLOAD>(type: AuthActionTypes): string {
  return createAction<PAYLOAD>(type).toString();
}

/**
 * Save to acync storage
 *
 * @param {string} key
 * @param {string} value
 */
export const setStorageData = (key: string, value: any) => {
  try {
    localStorage.setItem(
      key,
      typeof value === 'string' ? value : JSON.stringify(value),
    );
  } catch (err) {
    /** */
  }
};

/**
 * Save to acync storage
 *
 * @param {string} key
 */
export const removeStorageData = async (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (err) {
    /** */
  }
};

/**
 * Get async storage data
 *
 * @param {string} key
 * @returns {Promise<any>}
 */
export const getStorageData = (key: string, parse = false): any => {
  try {
    const data = localStorage.getItem(key);
    if (data) {
      // console.log(typeof data);
      // const dataType = typeof data;
      // const isString = dataType === 'string';
      return parse ? JSON.parse(data) : data;
    }
    return data;
  } catch (err) {
    return null;
  }
};

/**
 * Required validation
 *
 * @param {*} value
 */
export const required = value => (value ? undefined : fieldErrors.REQUIRED);

/**
 *
 *
 * @param {*} appendFormdata
 */
export function appendFormdata(formData, data, name?) {
  // eslint-disable-next-line no-param-reassign
  name = name || '';
  if (typeof data === 'object' && 'uri' in data === false) {
    for (const property in data) {
      if (name === '') {
        appendFormdata(formData, data[property], property);
      } else {
        appendFormdata(formData, data[property], `${name}[${property}]`);
      }
    }
  } else {
    formData.append(name, data);
  }
}

/**
 * Format text to url
 *
 * @param {string} text
 * @returns {string}
 */
export const formatTextToUrl = (text: string | string[]) => {
  let encodedUrl = text.toString().replace(/\s+/g, ' ');
  encodedUrl = encodedUrl.split(/\&+/).join('+');
  encodedUrl = encodedUrl.split(/[^a-zA-Z0-9]/).join('+');
  encodedUrl = encodedUrl.split(/-+/).join('+');
  return encodedUrl;
};

/**
 * Remove plan item
 *
 * @param {string} eventKey
 * @param {string[]} seats
 * @returns
 */
export const removePlanItem = async (eventKey: string, seats: string[]) => {
  try {
    const client = new SeatsioClient(Region.EU(), SEATSIOS_SECRET_KEY);
    await client.events.release(eventKey, seats);
    return Promise.resolve();
  } catch (err) {
    return Promise.reject();
  }
};

export const checkPlanTokenExpiration = async () => {
  const err: Error = {
    status: -2,
    error: 'Votre sélection de places est expirée.',
  };
  try {
    const client = new SeatsioClient(Region.EU(), SEATSIOS_SECRET_KEY);
    const seatioSession = sessionStorage.getItem('seatsio');
    if (!seatioSession) {
      return Promise.reject(err);
    }
    const seatSessionObj = JSON.parse(seatioSession);
    const { holdToken } = seatSessionObj;

    if (!holdToken) {
      return Promise.reject(err);
    }
    await client.holdTokens.expiresInMinutes(holdToken, 10);
    return Promise.resolve();
  } catch {
    return Promise.reject(err);
  }
};

export const bookSeats = async (eventKey, seats) => {
  try {
    const client = new SeatsioClient(Region.EU(), SEATSIOS_SECRET_KEY);
    await client.events.book(eventKey, seats);
    return Promise.resolve();
  } catch (err) {
    return Promise.reject();
  }
};

/**
 * Validation cart field
 *
 * @param {*} value
 * @param {any[]} validations
 * @returns {(string | undefined)}
 */
export const validationCartField = (
  value: any,
  validation: any,
): string | undefined => {
  for (const valid of validation) {
    const validate = required(value);
    switch (valid) {
      case 'required':
        if (validate) return validate;
        break;
      default:
        return undefined;
    }
  }
  return undefined;
};
