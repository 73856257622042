import { RouteProps } from 'react-router-dom';
import Cinema from '../components/pages/Cinema';
import Home from '../components/pages/Home';
import StoreHome from '../components/pages/StoreHome';
import CartPage from '../components/pages/CartPage';
import contact from '../containers/contact/contact';
import ProfilPage from '../components/pages/ProfilePage';
import MyOrdersPage from '../components/pages/MyOrdersPage';
import mySingleOrderPage from '../components/pages/mySingleOrderPage';
import MagazinePage from '../components/pages/MagazinePage';
import SearchPage from '../components/pages/SearchPage';
import PrivateEventPage from '../components/pages/PrivateEventPage';
import PreviewEventPage from '../components/pages/PreviewEventPage';
import Provider from '../components/pages/Provider';

export const ROUTES_PATHS = {
  home: '/',
  dynamic: '/:url',
  dynamicStore: '/:url',
  cinema: '/cinema',
  contact: '/contact',
  inscription: '/inscription',
  connexion: '/connexion',
  cart: '/panier',
  storeHome: '/',
  category: '/categorie',
  catalog: '/catalogue',
  product: '/produit',
  magazine: '/magazine',
  search: '/recherche',
  previewEvent: '/events/preview/:id',
  privateEvent: '/events/private/:token',
  provider: '/producteur/:slug',
};

export const SECURE_ROUTES_PATHS = {
  profil: '/profile',
  myOrders: '/mes-commandes',
  mySingleOrder: '/mes-commandes/:id',
};

export const APP_ROUTES: RouteProps[] = [
  {
    path: ROUTES_PATHS.contact,
    component: contact,
  },
  {
    path: ROUTES_PATHS.magazine,
    component: MagazinePage,
  },
  {
    path: ROUTES_PATHS.cart,
    component: CartPage,
  },
  {
    path: ROUTES_PATHS.search,
    component: SearchPage,
  },

  {
    path: ROUTES_PATHS.previewEvent,
    component: PreviewEventPage,
  },
  {
    path: ROUTES_PATHS.privateEvent,
    component: PrivateEventPage,
  },
  {
    path: ROUTES_PATHS.provider,
    component: Provider,
  },
];

export const GUICHET_ROUTES: RouteProps[] = [
  {
    path: ROUTES_PATHS.home,
    component: Home,
  },
  {
    path: ROUTES_PATHS.cinema,
    component: Cinema,
  },
];

export const GSTORE_ROUTES: RouteProps[] = [
  {
    path: ROUTES_PATHS.storeHome,
    component: StoreHome,
  },
];

export const APP_SECURES_ROUTES: RouteProps[] = [
  {
    path: SECURE_ROUTES_PATHS.profil,
    component: ProfilPage,
  },
  {
    path: SECURE_ROUTES_PATHS.myOrders,
    component: MyOrdersPage,
  },
  {
    path: SECURE_ROUTES_PATHS.mySingleOrder,
    component: mySingleOrderPage,
  },
];
