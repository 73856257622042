import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ProductRecord } from '../models';
import { formatPrice } from '../helpers';

interface ProductItemProps {
  small?: boolean;
  product: ProductRecord;
}

const Product = ({ small = false, product }: ProductItemProps) => {
  const history = useHistory();

  return (
    <article className={`product-article ${small ? 'small' : ''}`}>
      {!small && (
        <header>
          <div>
            <Link to={`/store/${product.get('slug')}`}>
              {product.get('title')}
            </Link>
          </div>
          <div className="text-right">
            <div className="new-price">{formatPrice(product.get('price'))}</div>
            {!!product.get('oldPrice') && (
              <div className="old-price">
                {formatPrice(product.get('oldPrice'))}
              </div>
            )}
            {!!product.get('oldPrice') && (
              <span className="per-price">-{product.get('discount')}%</span>
            )}
          </div>
        </header>
      )}

      <div>
        <Link to={`/store/${product.get('slug')}`}>
          <img
            className="img-fluid"
            src={product.get('slide')}
            alt={`product-${product.get('id')}`}
          />

          {small && (
            <header className="d-block">
              <div className="new-price">
                {formatPrice(product.get('price'))}
              </div>
              {!!product.get('oldPrice') && (
                <div className="old-price">
                  {formatPrice(product.get('oldPrice'))}
                </div>
              )}
              {!!product.get('oldPrice') && (
                <span className="per-price">-{product.get('oldPrice')}%</span>
              )}
            </header>
          )}
        </Link>
      </div>

      {!small && (
        <footer>
          {/* <Link to={`/store/${product.get('slug')}`}>En savoir plus</Link> */}
          <button onClick={() => history.push(`/store/${product.get('slug')}`)}>
            Achat express
          </button>
        </footer>
      )}
    </article>
  );
};

export default Product;
