/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { ToggleModalConnectionPayload } from '../../types';
import LoadingPage from './LoadingPage';

interface PrivateRouteProps extends RouteProps {
  isConnected: boolean;
  toggleModalConnection: (payload?: ToggleModalConnectionPayload) => void;
  authLoading: boolean;
}

const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({
  isConnected,
  component: Component,
  toggleModalConnection,
  authLoading,
  ...rest
}: PrivateRouteProps) => {
  return (
    <Route
      {...rest}
      render={props =>
        isConnected ? (
          <Component {...props} />
        ) : (
          <EmptyComponent
            toggleModalConnection={toggleModalConnection}
            authLoading={authLoading}
          />
        )
      }
    />
  );
};
interface EmptyComponentProps {
  toggleModalConnection: (payload?: ToggleModalConnectionPayload) => void;
  authLoading: boolean;
}

const EmptyComponent = ({
  toggleModalConnection,
  authLoading,
}: EmptyComponentProps) => {
  useEffect(() => {
    toggleModalConnection({ isVisible: !authLoading });
  }, [authLoading]);

  if (authLoading) return <LoadingPage loading />;
  return (
    <div className="not-found-page text-center">
      <div className="container">
        <h1>
          Vous n'êtes pas autorisés à <br /> afficher cette page
        </h1>
      </div>
    </div>
  );
};

export default PrivateRoute;
