import { bindActionCreators, compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import { AppState } from '../../../types';
import PromoFormView from './promoView';
import {
  applyDiscount as applyDiscountAc,
  clearDiscount as clearDiscountAc,
} from '../../../actions';
import { getDiscount } from '../../../selectors/cart';

const selector = formValueSelector('PromoForm');

const mapDispatchToProps = (dispatch: Dispatch): any => {
  return bindActionCreators(
    {
      applyDiscount: applyDiscountAc,
      clearDiscount: clearDiscountAc,
    },
    dispatch,
  );
};

const mapStateToProps = (appState: AppState) => {
  return {
    codePromo: selector(appState, 'code_promo'),
    discount: getDiscount(appState),
  };
};

const PromoComposedForm = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'PromoForm',
    keepValues: true,
    destroyOnUnmount: false,
  }),
)(PromoFormView);

export default PromoComposedForm;
