import {
  ArticleResponse,
  ArticlesListing,
  Article,
  TopicsListing,
  Topic,
} from '../../types';
import {
  ArticleRecord,
  ArticlesListRecord,
  ArticleResponseRecord,
  TopicsListRecord,
  TopicRecord,
} from '../../models';
import { serializeList, PaginationRecord } from '../../helpers';

/**
 * Serialize article
 *
 * @param {Article} { category, ...rest }
 * @returns {ArticleRecord}
 */
export const serializeArticle = ({
  id,
  title,
  content,
  image,
  createdAt,
  slug,
  metaTitle,
  metaDescription,
  metaKeywords,
  tags,
  topic,
  ...rest
}: Article): ArticleRecord => {
  const newArticle = new ArticleRecord({
    id,
    title,
    content,
    image,
    createdAt,
    slug,
    metaTitle,
    metaDescription,
    metaKeywords,
    tags,
    topic,
    ...rest,
  });
  return newArticle;
};

/**
 * Serialize articles listing
 *
 * @param {ArticlesListing} {
 *   articles,
 *   pagination,
 *   ...rest
 * }
 * @returns {ArticlesListRecord}
 */
export const serializeArticlesListing = ({
  articles,
  pagination,
  ...rest
}: ArticlesListing): ArticlesListRecord => {
  const newArticleListing = new ArticlesListRecord({
    list: articles ? serializeList(articles, serializeArticle) : null,
    pagination: pagination ? new PaginationRecord(pagination) : null,
    ...rest,
  });

  return newArticleListing;
};

/**
 * Serialize article details response
 *
 * @param {ArticleResponse} {
 *   article,
 *   ...rest
 * }
 * @returns {ArticleResponseRecord}
 */
export const serializeArticleResponse = ({
  article,
  ...rest
}: ArticleResponse): ArticleResponseRecord => {
  return new ArticleResponseRecord({
    article: article ? serializeArticle(article) : null,
    ...rest,
  });
};

/**
 * Serialize topic
 *
 * @param {Article} { category, ...rest }
 * @returns {ArticleRecord}
 */
export const serializeTopic = ({
  id,
  title,
  createdAt,
  slug,
  metaTitle,
  metaDescription,
  metaKeywords,
  articles,
  ...rest
}: Topic): TopicRecord => {
  const newTopic = new TopicRecord({
    id,
    title,
    createdAt,
    slug,
    metaTitle,
    metaDescription,
    metaKeywords,
    articles,
    ...rest,
  });
  return newTopic;
};

/**
 * Serialize topics listing
 *
 * @param {TopicsListing} {
 *   topics,
 *   pagination,
 *   ...rest
 * }
 * @returns {TopicsListRecord}
 */
export const serializeTopicsListing = ({
  topics,
  pagination,
  ...rest
}: TopicsListing): TopicsListRecord => {
  const newTopicListing = new TopicsListRecord({
    list: topics ? serializeList(topics, serializeTopic) : null,
    pagination: pagination ? new PaginationRecord(pagination) : null,
    ...rest,
  });

  return newTopicListing;
};
