import React from 'react';

import { HomeSlider, CampaignsGrid } from '../../containers';
import AppMeta from '../common/AppMeta';

const StoreHome = () => {
  return (
    <div>
      <AppMeta title="Grandes marques à prix discount" prefix="Guichet Store" />
      <HomeSlider type="product" />
      <CampaignsGrid />
    </div>
  );
};

export default StoreHome;
