import { Reducer } from 'redux';
import { createAction, handleActions, Action } from 'redux-actions';
import { CartManagementRecord } from '../../models';
import {
  SetSelectedAddressPayload,
  CartManagementActions,
  ChangeCartStepPlayload,
  CartManagementPayloads,
  cartActions,
  SetPaymentInfoPlayload,
  SetInstructionPlayload,
} from '../../types';

const createReducer = (): Reducer<
  CartManagementRecord,
  CartManagementActions
> => {
  const chnageCartStep = createAction<ChangeCartStepPlayload>(
    '@@cart_management/CHANGE_STEP',
  ).toString();
  const setSelectedAddress = createAction<SetSelectedAddressPayload>(
    cartActions.SET_SELECTED_ADDRESS,
  ).toString();
  const setPaymentInfo = createAction<SetPaymentInfoPlayload>(
    cartActions.SET_PAYMENT_INFO,
  ).toString();
  const setInstruction = createAction<SetInstructionPlayload>(
    cartActions.SET_INSTRUCTION,
  ).toString();

  const setSelectedCarrierId = createAction<SetSelectedAddressPayload>(
    cartActions.SER_SELECTED_CARRIER_ID,
  ).toString();
  const setSelectedPaymentId = createAction<SetSelectedAddressPayload>(
    cartActions.SET_SELECTED_PAYMENT_ID,
  ).toString();

  const initState = new CartManagementRecord({
    step: 1,
    selectedAddressId: null,
    paymentInfo: null,
    instruction: null,

    selectedCarrierId: null,
    selectedPaymentId: null,
  });

  const reducer: Reducer<
    CartManagementRecord,
    CartManagementActions
  > = handleActions<CartManagementRecord, CartManagementPayloads>(
    {
      [chnageCartStep]: (
        state: CartManagementRecord,
        { payload }: Action<ChangeCartStepPlayload>,
      ) => state.set('step', payload.step),
      [setSelectedAddress]: (state: CartManagementRecord, { payload }: any) =>
        state.set('selectedAddressId', payload),
      [setPaymentInfo]: (state: CartManagementRecord, { payload }: any) =>
        state.set('paymentInfo', payload),
      [setInstruction]: (state: CartManagementRecord, { payload }: any) =>
        state.set('instruction', payload),

      [setSelectedCarrierId]: (state: CartManagementRecord, { payload }: any) =>
        state.set('selectedCarrierId', payload),
      [setSelectedPaymentId]: (state: CartManagementRecord, { payload }: any) =>
        state.set('selectedPaymentId', payload),
    },
    initState,
  );

  return reducer;
};

export default createReducer();
