import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Cinema = () => {
  return (
    <div>
      {/* <div className="main-banner">
        <div className="container">
          <BannerSlider items={bannerItems} />
        </div>
      </div> */}
      {/* <section className="container">
        <BlocHeader
          title="Films à l'affiche"
          subtitle="Agenda exceptionnel"
          iconType="G"
        />
        <div className="row">
          <div className="col-sm-4">
            <Card
              title="Raja Club Athletic VS Zamalek"
              city="Casablanca"
              date="Dimanche 22 Octobre 2020 à 20h00"
              address="Complexe Mohamed V"
              price={45}
              image={cardImage1}
            />
          </div>
          <div className="col-sm-4">
            <Card
              title="Coding Bootcamp pour Enfants"
              city="Agadir"
              date="Dimanche 22 Octobre 2020 à 20h00"
              address="Complexe Mohamed V"
              price={45}
              image={cardImage2}
            />
          </div>
          <div className="col-sm-4">
            <Card
              title="Origins Festival 2020"
              city="Agadir"
              date="Dimanche 22 Octobre 2020 à 20h00"
              address="Complexe Mohamed V"
              price={45}
              image={cardImage3}
            />
          </div>
          <div className="col-sm-4">
            <Card
              title="CAMBODIA"
              city="Agadir"
              date="Dimanche 22 Octobre 2020 à 20h00"
              address="Complexe Mohamed V"
              price={45}
              image={cardImage4}
            />
          </div>
          <div className="col-sm-4">
            <Card
              title="CAMBODIA"
              city="Agadir"
              date="Dimanche 22 Octobre 2020 à 20h00"
              address="Complexe Mohamed V"
              price={45}
              image={cardImage5}
            />
          </div>
          <div className="col-sm-4">
            <Card
              title="Raja Club Athletic VS Zamalek"
              city="Agadir"
              date="Dimanche 22 Octobre 2020 à 20h00"
              address="Complexe Mohamed V"
              price={45}
              image={cardImage6}
            />
          </div>
        </div>
      </section> */}

      {/* <section className="coming-soon">
        <div className="container">
          <BlocHeader
            title="Prochainement"
            subtitle="Toutes les ventes d'aujourd'hui"
            iconType="W"
          />
        </div>
        <ComingSoonSlider items={comingSoonMovies} />
      </section>

      <section className="container">
        <BlocHeader
          title="Films les plus attendues"
          subtitle="Classées par consultations"
          iconType="G"
        />
        <SimpleSlider items={movies} />
      </section> */}
    </div>
  );
};

export default Cinema;
