import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Icon from 'react-remixicon';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as QueryString from 'query-string';
import { getCart, getCartError, getCartLoading } from '../../selectors/cart';
import { deleteCartItem as deleteCartItemAc } from '../../actions';

import logo from '../../images/logo.png';
import logoMagazine from '../../images/guichet-magazine.png';
import logoStore from '../../images/logo-guichet-store.png';

import iconMagazine from '../../images/logo-magazine.png';
import { CategoriesTab, Menu } from '../../containers';
import { formatPrice, formatTextToUrl, ROUTES_PATHS } from '../../helpers';
import DownloadApp from './DownloadApp';
import LoadingPopup from './LoadingPopup';

const Header = ({ cart, deleteCartItem, loading }: any) => {
  const { search, pathname } = useLocation();
  const { q: searchQuery } = QueryString.parse(search);

  const history = useHistory();
  const cartInfoRef = useRef(null);
  const [searchValue, setSearchValue] = useState(searchQuery);

  const [isCartPreview, setIsCartPreview] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const toggleCartPreview = () => {
    setIsCartPreview(!isCartPreview);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (cartInfoRef.current && !cartInfoRef.current.contains(event.target))
        setIsCartPreview(false);
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [cartInfoRef]);

  const isMagazine = pathname.split('/')[1] === 'magazine';
  const isStore = pathname.split('/')[1] === 'store';

  const submitSearch = (e: FormEvent) => {
    e.preventDefault();
    if (!searchValue) return;
    history.push({
      pathname: ROUTES_PATHS.search,
      search: searchValue ? `?q=${formatTextToUrl(searchValue)}` : null,
    });
    setSearchValue('');
  };

  return (
    <header className="header">
      <DownloadApp />

      <div className="header-top">
        <div className="container-fluid">
          {/* search in mobile */}
          {isSearchVisible && (
            <div className="main-search d-block d-lg-none">
              <form noValidate onSubmit={submitSearch}>
                <input
                  className="search-bar"
                  placeholder="Cherchez ce que vous voulez"
                  value={searchValue}
                  onChange={(e: any) => setSearchValue(e.target.value)}
                />
                <div className="text-right">
                  <button
                    onClick={() => setIsSearchVisible(false)}
                    type="button"
                  >
                    <Icon name="close" type="line" />
                  </button>
                </div>
              </form>
            </div>
          )}

          <div className="row align-items-center justify-content-space-between">
            <div className="col-4">
              <div className="d-flex align-items-center">
                <Menu />
                <div className="main-search">
                  {/* search in desktop */}
                  <form
                    className="d-none d-lg-block"
                    noValidate
                    onSubmit={submitSearch}
                  >
                    <input
                      className="search-bar"
                      placeholder="Cherchez ce que vous voulez"
                      value={searchValue}
                      onChange={(e: any) => setSearchValue(e.target.value)}
                    />
                  </form>
                  <button
                    onClick={() => setIsSearchVisible(true)}
                    className="d-block d-lg-none show-search"
                  >
                    S
                  </button>
                </div>
              </div>
            </div>
            <div className="col-4">
              {isMagazine && (
                <Link className="header--brand" to="/magazine">
                  <img
                    className="header--logo img-fluid"
                    src={logoMagazine}
                    alt="Guichet Magazine"
                  />
                </Link>
              )}
              {isStore && (
                <Link className="header--brand" to="/store">
                  <img
                    className="header--logo img-fluid"
                    src={logoStore}
                    alt="Guichet Store"
                  />
                </Link>
              )}
              {!isMagazine && !isStore && (
                <Link className="header--brand" to="/">
                  <img
                    className="header--logo img-fluid"
                    src={logo}
                    alt="Guichet.ma"
                  />
                </Link>
              )}
            </div>
            <div className="col-4">
              <div className="right-menu d-flex justify-content-end">
                <Link to="/magazine" className="right-menu--item">
                  <img
                    className="header--logo"
                    src={iconMagazine}
                    alt="Guichet.ma"
                  />
                  <span>Guichet Magazine</span>
                </Link>
                <Link to="/qui-sommes-nous" className="right-menu--item">
                  <Icon name="question" type="line" />
                  <span>Qui sommes-nous ?</span>
                </Link>

                <div className="cart-box mobile">
                  <Link to="/panier" className="basket-btn">
                    {cart?.items?.length > 0 && (
                      <span className="quantity">{cart.items.length}</span>
                    )}
                    <Icon name="shopping-bag" type="line" />
                  </Link>
                </div>

                <div ref={cartInfoRef} className="cart-box desktop">
                  <button onClick={toggleCartPreview} className="basket-btn">
                    {cart?.items?.length > 0 && (
                      <span className="quantity">{cart.count}</span>
                    )}
                    <Icon name="shopping-bag" type="line" />
                    Panier
                  </button>
                  <div
                    className={`cart-info d-none d-md-block ${isCartPreview}`}
                  >
                    <LoadingPopup loading={loading} />

                    {(!cart?.items || cart?.items?.length === 0) && (
                      <div className="vide text-center">
                        <div>Votre panier est vide.</div>
                        <div>
                          Jetez un coup d'œil à nos événements / produits <br />
                          et trouvez l'inspiration !
                        </div>
                      </div>
                    )}

                    {cart?.items?.length > 0 && (
                      <div className="plein">
                        <h5 className="title">Conservé dans le panier</h5>

                        <div className="items">
                          {cart.items.map((item, index) => (
                            <div className="item" key={index}>
                              <div className="item-image-block">
                                {!item.attributes && (
                                  <span className="q">{item.quantity}</span>
                                )}
                                <div
                                  className="item-image"
                                  style={{
                                    backgroundImage: `url(${item.product.image})`,
                                  }}
                                />
                                {/* <img
                                  className="item-image"
                                  src={item.product.image}
                                  alt="product-image"
                                /> */}
                              </div>
                              <div className="item-info">
                                <div className="item-name">
                                  {item.product.title}
                                </div>
                                {item.attributes && (
                                  <div className="item-sub">
                                    {item.attributes.map(
                                      attr =>
                                        `${item.quantity} x ${attr.value}`,
                                    )}
                                  </div>
                                )}

                                <div className="item-price">
                                  {formatPrice(item.product.price)}
                                </div>
                              </div>
                              <button
                                onClick={() =>
                                  deleteCartItem(item.itemId, index)
                                }
                                className="item-delete"
                              >
                                <Icon name="delete-bin-5" type="fill" />
                              </button>
                            </div>
                          ))}
                        </div>

                        <div className="sous-total">
                          Sous-total {formatPrice(cart.subTotal)}
                        </div>

                        <div className="actions">
                          <Link
                            onClick={toggleCartPreview}
                            className="button green"
                            to="/panier"
                          >
                            Valider la commande
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CategoriesTab />
    </header>
  );
};

/**
* Cart map state to props
*
* @param {Dispatch} dispatch
@returns {EventsGridDispatchProps}
*/
const mapDispatchToProps = (dispatch: Dispatch): any => {
  return bindActionCreators(
    {
      deleteCartItem: deleteCartItemAc,
    },
    dispatch,
  );
};

/**
 * Cart map state to props
 *
 * @param {AppState} appState
 * @returns {EventsGridStateProps}
 */
const mapStateToProps = (appState: any): any => {
  return {
    cart: getCart(appState),
    loading: getCartLoading(appState),
    error: getCartError(appState),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
