/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { FunctionComponent, useMemo } from 'react';
import { List } from 'immutable';
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router-dom';
import { EventRecord } from '../models';
import Card from './Card';
import BlocHeader from './BlocHeader';
import { PaginationRecord } from '../helpers';

interface PastEventsGridProps {
  events?: List<EventRecord> | null;
  pagination?: PaginationRecord | null;
  page: number;
}

const PastEventsGrid: FunctionComponent<PastEventsGridProps> = ({
  events,
  pagination,
  page,
}: PastEventsGridProps) => {
  const history = useHistory();

  const renderPagination = useMemo(() => {
    if (!pagination) return null;
    return (
      <ReactPaginate
        initialPage={page - 1}
        previousLabel=""
        nextLabel=""
        breakLabel="..."
        breakClassName="break-me"
        pageCount={pagination.get('total') / pagination.get('perPage')}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={({ selected }) => {
          const currentPage = selected + 1;
          if (currentPage !== pagination.get('currentPage')) {
            history.push({ pathname: '', search: `?page=${currentPage}` });
          }
        }}
        containerClassName="g-pagination"
        activeLinkClassName="active"
        pageLinkClassName="pagination-item"
      />
    );
  }, [history, page, pagination]);

  if (!events || events?.size <= 0) {
    return null;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  // const renderPagination = useMemo(() => {
  //   if (!pagination || pagination?.get('total') <= 0) {
  //     return null;
  //   }
  //   const total = pagination.get('total');
  //   const totalPages = Math.trunc(total / pagination.get('perPage')) || 1;
  //   let pages: number[] = [];
  //   for (let i = 1; i <= totalPages; i++) pages = [...pages, i];
  //   return (
  //     <ul className="g-pagination">
  //       {pagination.get('currentPage') !== 1 && (
  //         <li>
  //           <button className="pagination-item pg-left" />
  //         </li>
  //       )}
  //       {pages.map(page => {
  //         const active = pagination.get('currentPage') === page ? 'active' : '';
  //         return (
  //           <li key={`pagination-item-${page}`}>
  //             <button
  //               disabled={!!active}
  //               className={`pagination-item ${active}`}
  //             >
  //               {page}
  //             </button>
  //           </li>
  //         );
  //       })}
  //       {pagination.get('currentPage') !== totalPages && (
  //         <li>
  //           <button className="pagination-item pg-right" />
  //         </li>
  //       )}
  //     </ul>
  //   );
  // }, [pagination]);

  return (
    <section className="passed-events">
      <div className="container">
        <BlocHeader
          title="les événements passés"
          subtitle="Tout ce que vous avez raté sur GUICHET"
          iconType="W"
        />
        <div className="row mb-3">
          {events.toArray().map(event => (
            <div
              className="col-lg-3 col-md-6"
              key={`events-items-${event.get('id')}`}
            >
              <Card event={event} />
            </div>
          ))}
        </div>
        {renderPagination}
      </div>
    </section>
  );
};

export default PastEventsGrid;
