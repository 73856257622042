/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppState } from '../../types';
import { PrivateEventDispatchProps, PrivateEventStateProps } from './types';
import EventDetailsC from '../../components/pages/Event';
import LoadingPage from '../../components/common/LoadingPage';
import { getEventData, getEventError, getEventLoading } from '../../selectors';
import {
  addToCart as addToCartAc,
  fetchPrivateEvent as fetchPrivateEventAc,
  destroyCart as destroyCartAC,
} from '../../actions';
import AppMeta from '../../components/common/AppMeta';

interface PrivateEventProps
  extends PrivateEventDispatchProps,
    PrivateEventStateProps {}

const PrivateEvent: FunctionComponent<PrivateEventProps> = ({
  addToCart,
  fetchPrivateEvent,
  eventDetails,
  loading,
  error,
  destroyCart,
}: PrivateEventProps) => {
  const { token } = useParams<any>();

  useEffect(() => {
    fetchPrivateEvent(token);
  }, []);

  return (
    <>
      <LoadingPage loading={loading} />
      <AppMeta
        title={eventDetails?.get('metaTitle')}
        description={eventDetails?.get('metaDescription')}
        keywords={eventDetails?.get('metaKeywords')}
        hideFromRobots
      />
      <EventDetailsC
        addToCart={addToCart}
        event={eventDetails}
        deleteSeat={null}
        loading={loading}
        error={error}
        destroyCart={destroyCart}
      />
    </>
  );
};

/**
* PrivateEvent map state to props
*
* @param {Dispatch} dispatch
@returns {PrivateEventDispatchProps}
*/
const mapDispatchToProps = (dispatch: Dispatch): PrivateEventDispatchProps => {
  return bindActionCreators(
    {
      addToCart: addToCartAc,
      fetchPrivateEvent: fetchPrivateEventAc,
      destroyCart: destroyCartAC,
    },
    dispatch,
  );
};

/**
 * PrivateEvent map state to props
 *
 * @param {AppState} appState
 * @returns {PrivateEventStateProps}
 */
const mapStateToProps = (appState: AppState): PrivateEventStateProps => {
  return {
    eventDetails: getEventData(appState),
    loading: getEventLoading(appState),
    error: getEventError(appState),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateEvent);
