import { List } from 'immutable';
import { Product, ProductForRec, ProductsListing } from '../../types';
import { ProductRecord, ProductsListRecord } from '../../models';
import { serializeCampaign, serializeTerm } from '../campaign';
import { serializeList, PaginationRecord } from '../../helpers';

export const serializeProduct = ({
  id,
  title,
  reference,
  slug,
  term,
  oldPrice,
  discount,
  price,
  cover,
  slide,
  images,
  qty,
  qtyMax,
  qtyMin,
  description,
  combinations,
  campaign,
  similarProducts,
  metaTitle,
  metaDescription,
  metaKeywords,
}: Product): ProductRecord => {
  let newSimilarProducts;
  if (similarProducts) {
    newSimilarProducts = serializeList(similarProducts, serializeProduct);
  }
  const initData: ProductForRec = {
    id,
    title,
    reference,
    slug,
    term: term ? serializeTerm(term) : null,
    oldPrice,
    discount,
    price,
    cover,
    slide,
    images: List(images),
    qtyMin,
    qtyMax,
    qty,
    description,
    combinations,
    campaign: campaign ? serializeCampaign(campaign) : null,
    similarProducts: newSimilarProducts,
    metaTitle,
    metaDescription,
    metaKeywords,
  };

  return new ProductRecord(initData);
};

export const serializeProductsListing = ({
  products,
  pagination,
  ...rest
}: ProductsListing): ProductsListRecord => {
  const newListing = new ProductsListRecord({
    list: products ? serializeList(products, serializeProduct) : null,
    pagination: pagination ? new PaginationRecord(pagination) : null,
    ...rest,
  });

  return newListing;
};
