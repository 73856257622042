import { takeEvery, call, put } from 'redux-saga/effects';
import {
  UserResponse,
  ConnectionUpdatedAction,
  ConnectionUpdatedPayload,
  SignInSuccessPayload,
  authActions,
  authActionKeys,
  userActionKeys,
} from '../types';
import {
  getStorageData,
  STORAGE_TOKEN,
  removeStorageData,
  SimpleActions,
  setStorageData,
  createSimpleActionType,
  STORAGE_IS_CONNECTED,
  STORAGE_USER,
} from '../helpers';
import {
  setTokenToStore,
  connectionUpdated,
  userUpdated,
  toggleModalConnection,
  authloadingEnd,
} from '../actions';
import { callApi, ApiRoutes } from '../providers';

/**
 * Forgot password
 */
function* forgotPasswordSaga(data: any) {
  yield put(authloadingEnd());
}

/**
 * Check connection saga
 */
function* checkConnectionSaga() {
  try {
    // const isConnected: boolean = yield call(
    //   getStorageData,
    //   STORAGE_IS_CONNECTED,
    // );
    const token: string = yield call(getStorageData, STORAGE_TOKEN, false);
    if (token) {
      yield put(setTokenToStore(token));
      // const currentUser: User = yield call(getStorageData, STORAGE_USER);
      // if (isConnected === true) {
      //   yield put(connectionUpdated(true, currentUser));
      //   yield put(setTokenToStore(token));
      const data: UserResponse = yield call(callApi, {
        url: ApiRoutes.user,
        secure: true,
        isCheckConnection: true,
      });
      const { user } = data;

      yield put(connectionUpdated(true, user));
    } else {
      yield put(authloadingEnd());
    }

    // } else {
    //   yield put(connectionUpdated(false, null));
    //   yield call(removeStorageData, STORAGE_TOKEN);
    //   yield put(setTokenToStore(null));
    // }
  } catch (_) {
    //
  }
}

/**
 * Conection update saga
 *
 * @param {ConnectionUpdatedAction} { payload }
 */
function* connectionUpdatedSaga({ payload }: ConnectionUpdatedAction) {
  try {
    const { user }: ConnectionUpdatedPayload = payload;
    yield put(userUpdated(user));
    // eslint-disable-next-line no-empty
  } catch (error) {}
}

/**
 * Sign out saga
 */
function* signOutSaga({ callback }: any) {
  try {
    yield call(callApi, {
      url: ApiRoutes.signOut,
      secure: true,
      method: 'POST',
    });
    yield call(removeStorageData, STORAGE_TOKEN);
    yield call(removeStorageData, STORAGE_IS_CONNECTED);
    yield call(removeStorageData, STORAGE_USER);
    yield put(setTokenToStore(null));
    yield put(connectionUpdated(false, null));

    if (callback) callback();

    yield put(toggleModalConnection({ isVisible: false }));

    // eslint-disable-next-line no-empty
  } catch (e) {}
}

/**
 * When verifiction code is successed
 *
 * @param {SimpleActions<string, CodeVerificationPayload>} {
 *   payload
 * }
 */
function* connectionSuccesssSaga({
  payload,
}: SimpleActions<string, SignInSuccessPayload>) {
  try {
    const { token, user, success }: SignInSuccessPayload = payload;
    yield call(setStorageData, STORAGE_TOKEN, token);
    yield put(setTokenToStore(token));
    if (success) {
      yield put(connectionUpdated(true, user));
      yield put(toggleModalConnection({ isVisible: false }));
    }
  } catch (_) {
    // }
  }
}

/**
 * When edit profil is successed
 *
 * @param {SimpleActions<string, EditProfilPayload>} { payload }
 */
function* profilUpdated({ payload }: SimpleActions<string, any>) {
  const { user }: any = payload;
  yield put(userUpdated(user));
}

export default function* authenticationSaga() {
  yield takeEvery(authActions.CHECK_CONNECTION, checkConnectionSaga);
  yield takeEvery(authActions.CONNECTION_UPDATED, connectionUpdatedSaga);
  yield takeEvery(authActions.SIGN_OUT, signOutSaga);
  yield takeEvery(
    createSimpleActionType(authActionKeys.SIGN_IN, 'SUCCESS_RESPONSE'),
    connectionSuccesssSaga,
  );
  yield takeEvery(
    createSimpleActionType(authActionKeys.SIGN_UP, 'SUCCESS_RESPONSE'),
    connectionSuccesssSaga,
  );
  yield takeEvery(
    createSimpleActionType(authActionKeys.SOCIAL_SIGN_IN, 'SUCCESS_RESPONSE'),
    connectionSuccesssSaga,
  );
  yield takeEvery(
    createSimpleActionType(userActionKeys.PROFIL_UPDATE, 'SUCCESS_RESPONSE'),
    profilUpdated,
  );
  yield takeEvery(
    createSimpleActionType(authActionKeys.FORGOT_PASSWORD, 'SUCCESS_RESPONSE'),
    forgotPasswordSaga,
  );
}
