import { reduxForm, FormErrors } from 'redux-form';
import FormView from './view';
import { formsNames, fieldErrors } from '../constants';
import { NewsletterFormData, NewsletterFormProps } from '../types';
import { regularExpresions } from '../../../helpers';

export const validate = ({
  email,
}: NewsletterFormData): FormErrors<NewsletterFormData> => {
  const errors: FormErrors<NewsletterFormData> = {};
  if (!email || email.trim() === '') {
    errors.email = fieldErrors.REQUIRED;
  } else if (!regularExpresions.EMAIL.test(email)) {
    errors.email = fieldErrors.EMAIL;
  }
  return errors;
};

const NewsletterForm = reduxForm<NewsletterFormData, NewsletterFormProps>({
  form: formsNames.NEWSLETTER,
  validate,
})(FormView);

export default NewsletterForm;
