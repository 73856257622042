import React from 'react';
import BannerSlider from '../BannerSlider';
import { ThemeRecord } from '../../models';
import { CampaignsGrid } from '../../containers';
import AppMeta from '../common/AppMeta';

interface ThemeProps {
  theme?: ThemeRecord | null;
}

const Categorie = ({ theme }: ThemeProps) => {
  return (
    <div>
      <AppMeta
        title={theme.get('metaTitle')}
        description={theme.get('metaDescription')}
        keywords={theme.get('metaKeywords')}
        prefix="Guichet Store"
      />

      <BannerSlider items={theme.get('sliderItems')} />
      <CampaignsGrid themeId={theme.get('id')} />
    </div>
  );
};

export default Categorie;
