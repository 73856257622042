/* eslint-disable class-methods-use-this */
// Definitions by: Steve Junior <https://github.com/stevejunior>

/**
 * Injects TawkTo plugin into react DOM
 *
 * @param string propertyId
 * @param string widgetId
 * are available in TawkTo dashboard
 *
 * @param string zIndex
 * Add style to the widget. Only zIndex is currently supported
 */
export class TawkTo {
  tawk;

  constructor(propertyId, widgetId, zIndex = null) {
    this.tawk = this.init(propertyId, widgetId, zIndex);
  }

  init(propertyId, widgetId, zIndex) {
    if (!window) {
      throw new Error('Unvailable DOM');
    }

    const id = '_t_a_w_k';
    if (document.getElementById(id) === null) {
      // Prevent TawkTo from creating more instances if it already exists
      const element = document.createElement('script');
      element.id = id;
      element.async = true;
      element.src = `https://embed.tawk.to/${propertyId}/${widgetId}`;
      element.char = 'UTF-8';
      element.setAttribute('crossorigin', '*');

      const node = document.getElementsByTagName('script')[0];
      if (!node || !node.parentNode) {
        throw new Error('Unavailable DOM');
      }

      node.parentNode.insertBefore(element, node);
    }

    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_LoadStart = new Date();

    const tawk = window.Tawk_API;

    if (zIndex) {
      tawk.customStyle = { zIndex };
    }

    return tawk;
  }

  /**
   * Hook into Tawk events
   */

  /**
   * Callback function invoked right after the widget is rendered.
   * @returns void
   */
  onLoad(callback) {
    this.tawk.onLoad = () => callback();
  }

  /**
   * Callback function invoked when the page status changes. The function will receive the changed status which will be either online, away or offline.
   * @returns void
   */
  onStatusChange(callback) {
    this.tawk.onStatusChange = status => callback(status);
  }

  /**
   * Callback function invoked right when Tawk_API is ready to be used and before the widget is rendered.
   * @returns void
   */
  onBeforeLoad(callback) {
    this.tawk.onBeforeLoad = () => callback();
  }

  /**
   * Callback function invoked when the widget is maximized.
   * @returns void
   */
  onChatMaximized(callback) {
    this.tawk.onChatMaximized = () => callback();
  }

  /**
   * Callback function invoked when the widget is minimized.
   * @returns void
   */
  onChatMinimized(callback) {
    this.tawk.onChatMinimized = () => callback();
  }

  /**
   * Callback function invoked when the widget is hidden.
   * @returns void
   */
  onChatHidden(callback) {
    this.tawk.onChatHidden = () => callback();
  }

  /**
   * Callback function invoked when the widget is started.
   * @returns void
   */
  onChatStarted(callback) {
    this.tawk.onChatStarted = () => callback();
  }

  /**
   * Callback function invoked when the widget is ended.
   * @returns void
   */
  onChatEnded(callback) {
    this.tawk.onChatEnded = () => callback();
  }

  /**
   * Callback function invoked when the Pre-Chat Form is submitted.
   * @returns void
   */
  onPrechatSubmit(callback) {
    this.tawk.onPrechatSubmit = data => callback(data);
  }

  /**
   * Callback function invoked when the Offline form is submitted.
   * @returns void
   */
  onOfflineSubmit(callback) {
    this.tawk.onOfflineSubmit = data => callback(data);
  }

  /**
   * Callback function invoked when message is sent by the visitor.
   * @returns void
   */
  onChatMessageVisitor(callback) {
    this.tawk.onChatMessageVisitor = message => callback(message);
  }

  /**
   * Callback function invoked when message is sent by the agent.
   * @returns void
   */
  onChatMessageAgent(callback) {
    this.tawk.onChatMessageAgent = message => callback(message);
  }

  /**
   * Callback function invoked when message is sent by the system.
   * @returns void
   */
  onChatMessageSystem(callback) {
    this.tawk.onChatMessageSystem = message => callback(message);
  }

  /**
   * Callback function invoked when an agent joins the chat.
   * @returns void
   */
  onAgentJoinChat(callback) {
    this.tawk.onAgentJoinChat = data => callback(data);
  }

  /**
   * Callback function invoked when an agent leaves the chat.
   * @returns void
   */
  onAgentLeaveChat(callback) {
    this.tawk.onAgentLeaveChat = data => callback(data);
  }

  /**
   * Callback function invoked when an agent leaves the chat. The satisfaction is passed to the callback. -1 = dislike | 0 = neutral | 1 = like.
   * @returns void
   */
  onChatSatisfaction(callback) {
    this.tawk.onChatSatisfaction = satisfaction => callback(satisfaction);
  }

  /**
   * Callback function invoked when the visitor manually changes his name.
   * @returns void
   */
  onVisitorNameChanged(callback) {
    this.tawk.onVisitorNameChanged = visitorName => callback(visitorName);
  }

  /**
   * Callback function invoked when a file is uploaded. The link to the uploaded file is passed to the callback.
   * @returns void
   */
  onFileUpload(callback) {
    this.tawk.onFileUpload = link => callback(link);
  }

  /**
   * Callback function invoked when a tag is updated.
   * @returns void
   */
  onTagsUpdated(callback) {
    this.tawk.onTagsUpdated = data => callback(data);
  }

  /**
   * Maximizes the chat widget.
   * @returns void
   */
  maximize() {
    if (this.tawk.maximize !== undefined) {
      this.tawk.maximize();
    }
  }

  /**
   * Minimises the chat widget.
   * @returns void
   */
  minimize() {
    if (this.tawk.minimize !== undefined) {
      this.tawk.minimize();
    }
  }

  /**
   * Minimizes or Maximizes the chat widget based on the current state.
   * @returns void
   */
  toggle() {
    if (this.tawk.toggle !== undefined) {
      this.tawk.toggle();
    }
  }

  /**
   * Opens the chat widget as a pop out.
   * @returns void
   */
  popup() {
    if (this.tawk.popup !== undefined) {
      this.tawk.popup();
    }
  }

  /**
   * Returns the current widget type whether it’s inline or embed.
   * @returns {string}
   */
  getWindowType() {
    return this.tawk.getWindowType === undefined
      ? ''
      : this.tawk.getWindowType();
  }

  /**
   * Shows the chat widget.
   * @returns void
   */
  showWidget() {
    if (this.tawk.showWidget !== undefined) {
      this.tawk.showWidget();
    }
  }

  /**
   * Hides the chat widget.
   * @returns void
   */
  hideWidget() {
    if (this.tawk.hideWidget !== undefined) {
      this.tawk.hideWidget();
    }
  }

  /**
   * Hides or Shows the chat widget based on the current visibility state.
   * @returns void
   */
  toggleVisibility() {
    if (this.tawk.toggleVisibility !== undefined) {
      this.toggleVisibility();
    }
  }

  /**
   * Returns the current page status (online, away or offline).
   * @returns {string}
   */
  getStatus() {
    return this.tawk.getStatus === undefined ? false : this.tawk.getStatus();
  }

  /**
   * Returns a boolean value (true or false) indicating whether the chat widget is maximized.
   * @returns boolean
   */
  isChatMaximized() {
    return this.tawk.isChatMaximized === undefined
      ? false
      : this.tawk.isChatMaximized();
  }

  /**
   * Returns a boolean value (true or false) indicating whether the chat widget is minimized.
   * @returns boolean
   */
  isChatMinimized() {
    return this.tawk.isChatMinimized === undefined
      ? false
      : this.tawk.isChatMinimized();
  }

  /**
   * Returns a boolean value (true or false) indicating whether the chat widget is hidden.
   * @returns boolean
   */
  isChatHidden() {
    return this.tawk.isChatHidden === undefined
      ? false
      : this.tawk.isChatHidden();
  }

  /**
   * Returns a boolean value (true or false) indicating whether currently there is an ongoing chat.
   * @returns boolean
   */
  isChatOngoing() {
    return this.tawk.isChatOngoing === undefined
      ? false
      : this.tawk.isChatOngoing();
  }

  /**
   * Returns a boolean value (true or false) indicating whether the visitor is currently chatting or has requested a chat.
   * @returns boolean
   */
  isVisitorEngaged() {
    return this.tawk.isVisitorEngaged === undefined
      ? false
      : this.tawk.isVisitorEngaged();
  }

  /**
   * Ends the current ongoing chat.
   * @returns void
   */
  endChat() {
    if (this.tawk.endChat !== undefined) {
      this.tawk.endChat();
    }
  }

  /**
   * Set custom metadata regarding this chat/visitor.
   * @param object attribute
   * @param function callback
   * @returns void
   */
  setAttributes(attribute, callback) {
    if (this.tawk.setAttributes !== undefined) {
      this.tawk.setAttributes(attribute, callback);
    }
  }

  /**
   * Set a custom event to chat.
   * @param string name
   * @param object data optional
   * @param function callback
   * @returns void
   */
  addEvent(name, callback, data = null) {
    if (this.tawk.addEvent !== undefined) {
      if (data === null) {
        this.tawk.addEvent(name, callback);
      } else {
        this.tawk.addEvent(name, data, callback);
      }
    }
  }

  /**
   * Add tags to the chat.
   * @param array tags
   * @param function callback
   * @returns void
   */
  addTags(tags, callback) {
    if (this.tawk.addTags !== undefined) {
      this.tawk.addTags(tags, callback);
    }
  }

  /**
   * Remove tags from the chat.
   * @param array tags
   * @param function callback
   * @returns void
   */
  removeTags(tags, callback) {
    if (this.tawk.removeTags !== undefined) {
      this.tawk.removeTags(tags, callback);
    }
  }
}
