import { Reducer, combineReducers } from 'redux';
import { ProductState, productActionsKeys } from '../types';
import { createSimpleListingStore } from '../helpers';
import { CategoriesResponseRecord } from '../models';
import { serializeProductsListing } from '../providers/product';

const productReducer: Reducer<ProductState> = combineReducers<ProductState>({
  productsState: createSimpleListingStore<CategoriesResponseRecord>(
    productActionsKeys.PRODUCTS,
    serializeProductsListing,
  ).reducer,
});

export default productReducer;
