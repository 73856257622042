import React, { FunctionComponent } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../types';
import { getMyOrders as getMyOrdersAc } from '../../actions';
import {
  getMyOrders as getMyOrdersSelector,
  getToken,
  getMyOrdersLoading,
} from '../../selectors';
import MyOrdersC from '../../components/pages/MyOrdersC';
import LoadingPage from '../../components/common/LoadingPage';

const MyOrders: FunctionComponent<any> = ({
  loading,
  orders,
  getMyOrders,
  token,
}: any) => {
  return (
    <>
      <LoadingPage loading={loading} />
      <MyOrdersC
        loading={loading}
        orders={orders}
        getMyOrders={getMyOrders}
        token={token}
      />
    </>
  );
};

/**
 * MyOrders map state to props
 *
 * @param {Dispatch} dispatch
 */
const mapDispatchToProps = (dispatch: Dispatch): any => {
  return bindActionCreators(
    {
      getMyOrders: getMyOrdersAc,
    },
    dispatch,
  );
};

/**
 * MyOrders map state to props
 *
 * @param {AppState} appState
 */
const mapStateToProps = (appState: AppState): any => {
  return {
    orders: getMyOrdersSelector(appState),
    token: getToken(appState),
    loading: getMyOrdersLoading(appState),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyOrders);
