import React, { FunctionComponent } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../types';
import { MagazineGridDispatchProps, MagazineGridStateProps } from './types';
import MagazineGridC from '../../components/MagazineGridC';
import {
  fetchArticles as fetchArticlesAc,
  fetchTopics as fetchTopicsAc,
} from '../../actions';
import {
  getArticles,
  getArticlesInfinitLoading,
  getArticlesPagination,
  getArticlesLoading,
} from '../../selectors';
import LoadingPage from '../../components/common/LoadingPage';

interface MagazineGridProps
  extends MagazineGridDispatchProps,
    MagazineGridStateProps {
  fetchArticles: (
    topicId: number,
    page?: number,
    loadMore?: boolean,
    refresh?: boolean,
  ) => void;
}

const MagazineGrid: FunctionComponent<MagazineGridProps> = ({
  fetchArticles,
  fetchTopics,
  articles,
  topic,

  pagination,
  infinitLoading,
  loading,
}: MagazineGridProps) => {
  return (
    <>
      <LoadingPage loading={loading} />
      <MagazineGridC
        fetchArticles={fetchArticles}
        fetchTopics={fetchTopics}
        articles={articles}
        currentTopic={topic}
        onLoadMore={topicId => {
          fetchArticles(topicId, pagination.get('nextPage'), true);
        }}
        pagination={pagination}
        infinitLoading={infinitLoading}
        loading={loading}
      />
    </>
  );
};

/**
* MagazineGrid map state to props
*
* @param {Dispatch} dispatch
@returns {MagazineGridDispatchProps}
*/
const mapDispatchToProps = (dispatch: Dispatch): MagazineGridDispatchProps => {
  return bindActionCreators(
    {
      fetchArticles: fetchArticlesAc,
      fetchTopics: fetchTopicsAc,
    },
    dispatch,
  );
};

/**
 * MagazineGrid map state to props
 *
 * @param {AppState} appState
 * @returns {MagazineGridStateProps}
 */
const mapStateToProps = (
  appState: AppState,
  { topic },
): MagazineGridStateProps => {
  return {
    articles: getArticles(appState),
    topic,
    infinitLoading: getArticlesInfinitLoading(appState),
    pagination: getArticlesPagination(appState),
    loading: getArticlesLoading(appState),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MagazineGrid);
