/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FunctionComponent } from 'react';
import { AnyFielProps } from '../../../types';

interface InputTextFieldProps extends AnyFielProps {
  nestedComp?: any;
}

const InputTextField: FunctionComponent = ({
  input: { onChange, onFocus, onBlur, value },
  meta: { error, touched, valid },
  label,
  id,
  isRequired,
  className,
  nestedComp: NestedComp,
  ...rest
}: InputTextFieldProps) => {
  return (
    <div className={className || 'col g-input'}>
      {!!label && (
        <label htmlFor={id}>
          {label} {!!isRequired && <span>*</span>}
        </label>
      )}
      <input
        id={id}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        {...rest}
      />
      {!valid && touched && !!error && (
        <p className="text-danger mt-1">{error}</p>
      )}
      {NestedComp}
    </div>
  );
};

export default InputTextField;
