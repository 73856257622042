import React from 'react';
import { Cart } from '../../containers';
import AppMeta from '../common/AppMeta';

export default function CartPage() {
  return (
    <>
      <AppMeta title="Mon panier" prefix="" />
      <Cart />
    </>
  );
}
