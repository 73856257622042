import React, { FunctionComponent } from 'react';
import { MySingleOrder } from '../../containers';
import AppMeta from '../common/AppMeta';

interface mySingleOrderPageProps {}

const mySingleOrderPage: FunctionComponent<mySingleOrderPageProps> = () => {
  return (
    <>
      <AppMeta title="Détails commande" prefix="" />
      <MySingleOrder />
    </>
  );
};

export default mySingleOrderPage;
