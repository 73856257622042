import { Reducer } from 'redux';
import { handleActions, Action, createAction } from 'redux-actions';
import { AddressDataRecord } from '../models';
import { addressActionKeys } from '../types';

const createReducer = (): Reducer<AddressDataRecord, any> => {
  const setAddress = createAction<any>(
    addressActionKeys.SET_ADDRESS,
  ).toString();
  const setAddresses = createAction<any>(
    addressActionKeys.SET_ADDRESSES,
  ).toString();
  const removeAddress = createAction<any>(
    addressActionKeys.REMOVE_ADDRESSES,
  ).toString();
  const putAddress = createAction<any>(
    addressActionKeys.PUT_ADDRESSES,
  ).toString();

  const addressesLoadingStart = createAction<any>(
    addressActionKeys.ADDRESSES_LOADING_START,
  ).toString();
  const addressesLoadingEnd = createAction<any>(
    addressActionKeys.ADDRESSES_LOADING_END,
  ).toString();
  const addressesFailed = createAction<any>(
    addressActionKeys.ADDRESSES_FAILED,
  ).toString();
  const addressesSuccess = createAction<any>(
    addressActionKeys.ADDRESSES_SUCCESS,
  ).toString();
  const addressesResetMessages = createAction<any>(
    addressActionKeys.RESET_ADDRESSES_MESSAGES,
  ).toString();

  //
  const newAddresses = new AddressDataRecord({
    addresses: null,
    loading: false,
    error: null,
    successMessage: null,
  });

  const reducer: Reducer<AddressDataRecord, any> = handleActions<
    AddressDataRecord,
    any
  >(
    {
      [setAddress]: (state: AddressDataRecord, { payload }: Action<any>) => {
        return state.update('addresses', (addresses: any[]) => {
          return [payload, ...addresses];
        });
      },
      [removeAddress]: (
        state: AddressDataRecord,
        { payload: id }: Action<any>,
      ) => {
        return state.update('addresses', (addresses: any[]) => {
          return [...addresses.filter(adr => adr.id !== id)];
        });
      },
      [putAddress]: (state: AddressDataRecord, { payload }: Action<any>) => {
        return state.update('addresses', (addresses: any[]) => {
          const adresses = [...addresses];
          adresses[addresses.findIndex(ad => ad.id === payload.id)] = payload;
          return adresses;
        });
      },
      [setAddresses]: (state: AddressDataRecord, { payload }: Action<any>) =>
        state.set('addresses', payload),

      [addressesLoadingStart]: (state: AddressDataRecord) =>
        state.set('loading', true),
      [addressesLoadingEnd]: (state: AddressDataRecord) =>
        state.set('loading', false),
      [addressesFailed]: (state: AddressDataRecord, { payload }: Action<any>) =>
        state.set('error', payload),
      [addressesSuccess]: (
        state: AddressDataRecord,
        { payload }: Action<any>,
      ) => state.set('successMessage', payload),

      [addressesResetMessages]: (state: AddressDataRecord) =>
        state.set('error', null).set('successMessage', null),
    },
    newAddresses,
  );

  return reducer;
};

export default createReducer();
