import { reduxForm, FormErrors } from 'redux-form';
import FormView from './view';
import { formsNames, fieldErrors } from '../constants';
import { SignInFormData, SignInFormProps } from '../types';
import { regularExpresions } from '../../../helpers';

export const validate = ({
  email,
  password,
}: SignInFormData): FormErrors<SignInFormData> => {
  const errors: FormErrors<SignInFormData> = {};
  if (!email || email.trim() === '') {
    errors.email = fieldErrors.REQUIRED;
  } else if (!regularExpresions.EMAIL.test(email)) {
    errors.email = fieldErrors.EMAIL;
  }
  if (!password || password.trim() === '') {
    errors.password = fieldErrors.REQUIRED;
  } else if (password.length < 8) {
    errors.password = fieldErrors.PASSWORD;
  }
  return errors;
};

const SignInForm = reduxForm<SignInFormData, SignInFormProps>({
  form: formsNames.SIGN_IN,
  validate,
})(FormView);

export default SignInForm;
