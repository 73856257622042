import React, { useState } from 'react';
import Icon from 'react-remixicon';
import { useHistory } from 'react-router-dom';
import { List } from 'immutable';
import { TermRecord } from '../models';

interface TermAccordionsProps {
  terms: List<TermRecord>;
  selectedTermId?: number;
}

const TermAccordions = ({ terms, selectedTermId }: TermAccordionsProps) => {
  const history = useHistory();
  const [selectedParentIndex, setSelectedParentIndex] = useState(-1);

  return (
    <>
      {terms.map((term, index) => (
        <div className="accordion" key={`terms-item-${term.get('id')}`}>
          <h6
            onClick={() => {
              if (!term.get('subTerms')) {
                history.replace({ pathname: `/store/${term.get('slug')}` });
              } else {
                setSelectedParentIndex(
                  index === selectedParentIndex ? -1 : index,
                );
              }
            }}
            className={selectedTermId === term.get('id') ? 'text-danger' : ''}
          >
            {term.get('title')}
            {!!term.get('subTerms') && (
              <Icon
                name={`arrow-${
                  selectedParentIndex === index ? 'up' : 'down'
                }-s`}
                type="line"
              />
            )}
          </h6>
          {index === selectedParentIndex && !!term.get('subTerms') && (
            <ul className="list">
              {term.get('subTerms').map(subTerm => (
                <li
                  className={
                    selectedTermId === subTerm.get('id') ? 'text-danger' : ''
                  }
                  key={`subTerms-item-${term.get('id')}`}
                  onClick={() => {
                    history.replace({
                      pathname: `/store/${subTerm.get('slug')}`,
                    });
                  }}
                >
                  {subTerm.get('title')}
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </>
  );
};

export default TermAccordions;
