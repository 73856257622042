import { ActionCreator } from 'redux';
import { FormAction, startAsyncValidation, submit } from 'redux-form';
import { SimpleActions, simpleFetchRequest } from '../helpers';
import { ApiRoutes, ConfigCallApi, GlobalCountriesParams } from '../providers';
import { appActionKeys } from '../types';

export const submitReduxForm: ActionCreator<FormAction> = (formName: string) =>
  submit(formName);

export const startValidation: ActionCreator<FormAction> = (formName: string) =>
  startAsyncValidation(formName);

export const fetchGlobalCountries: ActionCreator<SimpleActions> = (
  successCallback?: () => void,
): SimpleActions => {
  const confiApi: ConfigCallApi = {
    url: ApiRoutes.countries,
    callGlobalError: false,
    params: { available: 1 } as GlobalCountriesParams,
    successCallback,
  };
  return simpleFetchRequest(appActionKeys.GLOBAL_COUNTRIES, confiApi);
};
