import React from 'react';
import Slider from 'react-slick';
import { List } from 'immutable';
import Product from './Product';
import { ProductRecord } from '../models';

interface ProductsSliderProps {
  products: List<ProductRecord>;
}

const SETTINGS = {
  dots: false,
  infinite: false,
  speed: 500,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 2000,
  slidesToScroll: 2,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

const ProductsSlider = ({ products }: ProductsSliderProps) => {
  const renderItems = () => {
    return products.map(product => {
      return (
        <div className="slide-item" key={`products-item-${product.get('id')}`}>
          <Product product={product} small />
        </div>
      );
    });
  };
  return (
    <Slider {...SETTINGS} slidesToShow={products.size}>
      {renderItems()}
    </Slider>
  );
};

export default ProductsSlider;
