import { createSelector } from 'reselect';
import { AppState } from '../types';
import {
  DynamicComponentRecord,
  EventRecord,
  CategoryRecord,
  ProductRecord,
  ThemeRecord,
  TermRecord,
  CampaignRecord,
  TopicRecord,
  ArticleRecord,
} from '../models';
import { SimpleFetchStateRecord } from '../helpers';
import { Error } from '../providers';
import { PageRecord } from '../models/page';

export const getDynamicComponentState = (appState: AppState) =>
  appState.dynamicComponent;

//* ************************* BEGIN DYNAMIC COMPONENT STATE *************************** */

export const getDynamicComponentLoading = createSelector<
  AppState,
  any,
  boolean
>(
  [getDynamicComponentState],
  (dynamicComponentState: SimpleFetchStateRecord<DynamicComponentRecord>) =>
    dynamicComponentState.get('loading'),
);

export const getDynamicComponentError = createSelector<
  AppState,
  any,
  Error | null
>(
  [getDynamicComponentState],
  (dynamicComponentState: SimpleFetchStateRecord<DynamicComponentRecord>) =>
    dynamicComponentState.get('error'),
);

export const getEventComponent = createSelector<
  AppState,
  any,
  EventRecord | undefined | null
>(
  [getDynamicComponentState],
  (dynamicComponentState: SimpleFetchStateRecord<DynamicComponentRecord>) =>
    dynamicComponentState.get('data')?.get('event'),
);

export const getCampaignComponent = createSelector<
  AppState,
  any,
  CampaignRecord | undefined | null
>(
  [getDynamicComponentState],
  (dynamicComponentState: SimpleFetchStateRecord<DynamicComponentRecord>) =>
    dynamicComponentState.get('data')?.get('campaign'),
);

export const getCategoryComponent = createSelector<
  AppState,
  any,
  CategoryRecord | undefined | null
>(
  [getDynamicComponentState],
  (dynamicComponentState: SimpleFetchStateRecord<DynamicComponentRecord>) =>
    dynamicComponentState.get('data')?.get('category'),
);

export const getProductComponent = createSelector<
  AppState,
  any,
  ProductRecord | undefined | null
>(
  [getDynamicComponentState],
  (dynamicComponentState: SimpleFetchStateRecord<DynamicComponentRecord>) =>
    dynamicComponentState.get('data')?.get('product'),
);

export const getThemeComponent = createSelector<
  AppState,
  any,
  ThemeRecord | undefined | null
>(
  [getDynamicComponentState],
  (dynamicComponentState: SimpleFetchStateRecord<DynamicComponentRecord>) =>
    dynamicComponentState.get('data')?.get('theme'),
);

export const getTermComponent = createSelector<
  AppState,
  any,
  TermRecord | undefined | null
>(
  [getDynamicComponentState],
  (dynamicComponentState: SimpleFetchStateRecord<DynamicComponentRecord>) =>
    dynamicComponentState.get('data')?.get('term'),
);

export const getTopicComponent = createSelector<
  AppState,
  any,
  TopicRecord | undefined | null
>(
  [getDynamicComponentState],
  (dynamicComponentState: SimpleFetchStateRecord<DynamicComponentRecord>) =>
    dynamicComponentState.get('data')?.get('topic'),
);

export const getArticleComponent = createSelector<
  AppState,
  any,
  ArticleRecord | undefined | null
>(
  [getDynamicComponentState],
  (dynamicComponentState: SimpleFetchStateRecord<DynamicComponentRecord>) =>
    dynamicComponentState.get('data')?.get('article'),
);

export const getPageComponent = createSelector<
  AppState,
  any,
  PageRecord | undefined | null
>(
  [getDynamicComponentState],
  (dynamicComponentState: SimpleFetchStateRecord<DynamicComponentRecord>) =>
    dynamicComponentState.get('data')?.get('page'),
);

//* ************************* END DYNAMIC COMPONENT STATE *************************** */
