import { ActionCreator } from 'redux';
import {
  SimpleActions,
  simpleListingRequest,
  simpleListingReset,
  simpleListingResetMessages,
} from '../helpers';
import { ConfigCallApi, ApiRoutes, ProductsParams } from '../providers';
import { productActionsKeys } from '../types';

/**
 * Fetch products
 *
 * @param {boolean} [refresh]
 * @returns {SimpleActions}
 */
export const fetchProducts: ActionCreator<SimpleActions> = (
  termId?: number,
  campaignId?: number,
  page?: number,
  loadMore?: boolean,
  refresh?: boolean,
): SimpleActions => {
  const confiApi: ConfigCallApi = {
    url: ApiRoutes.products,
    callGlobalError: false,
    refresh,
    params: {
      term_id: termId,
      campaign_id: campaignId,
      page,
    } as ProductsParams,
  };
  return simpleListingRequest(productActionsKeys.PRODUCTS, confiApi, loadMore);
};

/**
 * Reset Products action creator
 *
 * @returns {SimpleActions}
 */
export const resetProducts: ActionCreator<SimpleActions> = (): SimpleActions =>
  simpleListingReset(productActionsKeys.PRODUCTS);

/**
 * Reset Products messages action creator
 *
 * @returns {SimpleActions}
 */
export const resetProductsMessages: ActionCreator<SimpleActions> = (): SimpleActions =>
  simpleListingResetMessages(productActionsKeys.PRODUCTS);
