import { ActionCreator } from 'redux';
import { SimpleActions } from '../helpers';
import { AddAddressPostData, ApiRoutes, ConfigCallApi } from '../providers';
import { addressActionKeys } from '../types';

/**
 * Set Address
 *
 * @param {any} payload
 * @returns {any}
 */
export const setAddress: ActionCreator<any> = (payload: any): any => ({
  type: addressActionKeys.SET_ADDRESS,
  payload,
});

/**
 * Set Addresses
 *
 * @param {any} payload
 * @returns {any}
 */
export const setAddresses: ActionCreator<any> = (payload: any): any => ({
  type: addressActionKeys.SET_ADDRESSES,
  payload,
});

/**
 * Remove Address
 *
 * @param {any} payload
 * @returns {any}
 */
export const removeAddress: ActionCreator<any> = (payload: any): any => ({
  type: addressActionKeys.REMOVE_ADDRESSES,
  payload,
});

/**
 * Put Address
 *
 * @param {any} payload
 * @returns {any}
 */
export const putAddress: ActionCreator<any> = (payload: any): any => ({
  type: addressActionKeys.PUT_ADDRESSES,
  payload,
});

/**
 * Get addresses action creator
 *
 * @param {boolean} [refresh]
 * @returns {SimpleActions}
 */
export const getAdresses: ActionCreator<SimpleActions> = () => {
  const confiApi: ConfigCallApi = {
    url: ApiRoutes.addresses,
    callGlobalError: false,
    secure: true,
    method: 'get',
  };
  return {
    type: addressActionKeys.GET_ADDRESSES,
    payload: confiApi,
  };
};

/**
 * Add address action creator
 *
 * @param {boolean} [refresh]
 * @returns {SimpleActions}
 */
export const addAddress: ActionCreator<SimpleActions> = (
  { address, city, country_id, first_name, last_name, name, phone, zipcode },
  callback?: () => void,
  isCart?: boolean,
): SimpleActions => {
  const confiApi: ConfigCallApi = {
    url: ApiRoutes.addAddress,
    callGlobalError: false,
    secure: true,
    method: 'post',
    callback,
    isCart,
    postData: {
      name,
      city,
      country_id,
      address,
      last_name,
      first_name,
      zipcode,
      phone,
    } as AddAddressPostData,
  };
  return {
    type: addressActionKeys.ADD_ADDRESS,
    payload: confiApi,
  };
};

/**
 * Delete Address action creator
 *
 * @param {boolean} [refresh]
 * @returns {SimpleActions}
 */
export const deleteAddress: ActionCreator<SimpleActions> = (
  itemId: number,
): SimpleActions => {
  const confiApi: ConfigCallApi = {
    url: `${ApiRoutes.deleteAddress}/${itemId}`,
    callGlobalError: false,
    secure: true,
    method: 'delete',
    itemIndex: itemId,
  };
  return {
    type: addressActionKeys.ADDRESS_DELETED,
    payload: confiApi,
  };
};

/**
 * Update address action creator
 *
 * @param {boolean} [refresh]
 * @returns {SimpleActions}
 */
export const updateAddress: ActionCreator<SimpleActions> = (
  itemId: number,
  { address, city, country_id, first_name, last_name, name, phone, zipcode },
  callback?: () => void,
): SimpleActions => {
  const confiApi: ConfigCallApi = {
    url: `${ApiRoutes.updateAddress}/${itemId}`,
    callGlobalError: false,
    secure: true,
    method: 'post',
    callback,
    postData: {
      name,
      city,
      country_id,
      address,
      last_name,
      first_name,
      zipcode,
      phone,
    } as AddAddressPostData,
  };
  return {
    type: addressActionKeys.ADDRESS_UPDATED,
    payload: confiApi,
  };
};

/**
 * Addresses loading start
 *
 * @returns {any}
 */
export const addressesLoadingStart: ActionCreator<any> = (): any => ({
  type: addressActionKeys.ADDRESSES_LOADING_START,
});

/**
 * Addresses loading end
 *
 * @returns {any}
 */
export const addressesLoadingEnd: ActionCreator<any> = (): any => ({
  type: addressActionKeys.ADDRESSES_LOADING_END,
});

/**
 *  Addresses failed
 *
 * @param {any} payload
 * @returns {any}
 */
export const addressesFailed: ActionCreator<any> = (payload: any): any => ({
  type: addressActionKeys.ADDRESSES_FAILED,
  payload,
});

/**
 *  Addresses success
 *
 * @param {any} payload
 * @returns {any}
 */
export const addressesSuccess: ActionCreator<any> = (payload: any): any => ({
  type: addressActionKeys.ADDRESSES_SUCCESS,
  payload,
});

/**
 *  Addresses reset messages
 *
 * @param {any} payload
 * @returns {any}
 */
export const addressesResetMessages: ActionCreator<any> = (
  payload: any,
): any => ({
  type: addressActionKeys.RESET_ADDRESSES_MESSAGES,
  payload,
});
