import React, { FunctionComponent } from 'react';
import { Field } from 'redux-form';
import { InputTextField } from '../../common/fields';
import { SignInFormProps } from '../types';

interface LoginViewProps extends SignInFormProps {
  handleSubmit: (formData: any) => void;
}

const ForgotPassView: FunctionComponent<LoginViewProps> = ({
  handleSubmit,
}: LoginViewProps) => {
  return (
    <div className="contact-from">
      <form noValidate onSubmit={handleSubmit}>
        <div>
          <div className="row">
            <Field
              component={InputTextField}
              name="email"
              isRequired
              id="in-email"
              type="email"
              placeholder="Votre adresse e-mail"
            />
          </div>

          <input
            className="g-submit"
            type="submit"
            value="Envoyer le lien de réinitialisation"
          />
        </div>
      </form>
    </div>
  );
};
export default ForgotPassView;
