import React, { FunctionComponent, useRef } from 'react';
import Icon from 'react-remixicon';
import { UserRecord } from '../../models';
import AddressesComposedForm from '../forms/cart/addresses';
import { ProfilUpdate } from '../../containers';

interface ProfilProps {
  user?: UserRecord | null;
}

const Profil: FunctionComponent<ProfilProps> = ({ user }: ProfilProps) => {
  const mesInformationsRef = useRef(null);
  const modifierMesInformationsRef = useRef(null);
  const mesAddressesRef = useRef(null);

  const menu = [
    {
      button: 'Mes informations',
      section: mesInformationsRef,
      icon: 'folder-info',
    },
    {
      button: 'Modifier mes informations',
      section: modifierMesInformationsRef,
      icon: 'file-edit',
    },
    {
      button: 'Mes addresses',
      section: mesAddressesRef,
      icon: 'home',
    },
  ];

  return (
    <section className="profile-page">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="box menu mb-3">
              <ul>
                {menu.map((m, index) => (
                  <li key={index}>
                    <button
                      onClick={() =>
                        window.scrollTo(0, m.section.current.offsetTop)
                      }
                    >
                      <Icon name={m.icon} type="line" /> {m.button}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="col-lg-9">
            <div ref={mesInformationsRef}>
              <div className="box mb-3">
                <div className="heading mb-0">
                  <h4>Mes informations</h4>
                </div>
                {user && (
                  <ul className="mt-4 user-info">
                    <li>Nom: {user.lastName}</li>
                    <li>Prénom: {user.firstName}</li>
                    <li>Email: {user.email}</li>
                    <li>Téléphone: {user.phone}</li>
                  </ul>
                )}
              </div>
            </div>
            <div ref={modifierMesInformationsRef}>
              <div className="box mb-3">
                <div className="heading mb-0">
                  <h4>Modifier mes informations</h4>
                </div>
                <ProfilUpdate />
              </div>
            </div>
            <div ref={mesAddressesRef}>
              <AddressesComposedForm />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Profil;
