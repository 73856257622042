import React, { FunctionComponent, useState, useEffect } from 'react';
import Icon from 'react-remixicon';
import { formatDateNumber } from '../helpers';

interface CountDownProps {
  date: string;
  isSmall?: boolean;
  handleSoldOut: () => void;
}

interface TimeCount {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
}

let interval = null;

const CountDown: FunctionComponent<CountDownProps> = ({
  date,
  handleSoldOut,
  isSmall,
}: CountDownProps) => {
  const [timeCount, setTimeCount] = useState<TimeCount | null>(null);

  useEffect(() => {
    const convertedDate = date.replace(' ', 'T');
    const countDownTime = new Date(convertedDate).getTime();

    let now = new Date().getTime();
    let distance = countDownTime - now <= 0 ? 0 : countDownTime - now;

    setTimeCount(getCountDownTime(distance));
    interval = setInterval(() => {
      if (distance <= 1000) {
        handleSoldOut();
        clearInterval(interval);
        interval = null;
      } else {
        now = new Date().getTime();
        distance = countDownTime - now;
        setTimeCount(getCountDownTime(distance));
      }
    }, 1000);

    if (distance <= 0) {
      handleSoldOut();
    }

    return () => {
      clearInterval(interval);
      interval = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCountDownTime = (distance): TimeCount => {
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return {
      days: formatDateNumber(days),
      hours: formatDateNumber(hours),
      minutes: formatDateNumber(minutes),
      seconds: formatDateNumber(seconds),
    };
  };

  if (!timeCount) {
    return null;
  }

  if (isSmall) {
    return (
      <div className="g-time-down">
        <Icon name="time" type="line" />
        <div className="days">
          {timeCount.days} <span>j</span>
        </div>
        <div className="hours">
          {timeCount.hours} <span>h</span>
        </div>
        <div className="minutes">
          {timeCount.minutes} <span>m</span>
        </div>
        <div className="seconds">
          {timeCount.seconds} <span>s</span>
        </div>
      </div>
    );
  }

  return (
    <div className="event-countdown">
      <div className="countdown-item">
        <span>{timeCount.days}</span>
        <span>Jours</span>
      </div>
      <div className="countdown-item">
        <span>{timeCount.hours}</span>
        <span>Heure</span>
      </div>
      <div className="countdown-item">
        <span>{timeCount.minutes}</span>
        <span>Minute</span>
      </div>
      <div className="countdown-item">
        <span>{timeCount.seconds}</span>
        <span>Second</span>
      </div>
    </div>
  );
};
export default CountDown;
