import { List } from 'immutable';
import {
  EventResponse,
  EventsListing,
  Event,
  EventProvider,
  Offer,
} from '../../types';
import {
  EventRecord,
  EventsListRecord,
  EventResponseRecord,
  EventProviderRecord,
  OfferRecord,
} from '../../models';
import { serializeCategory } from '../category';
import { serializeList, PaginationRecord } from '../../helpers';

/**
 * Serialize event
 *
 * @param {Event} { category, ...rest }
 * @returns {EventRecord}
 */
export const serializeEvent = ({
  category,
  provider,
  images,
  offers,
  similarEvents,
  ...rest
}: Event): EventRecord => {
  const newEvent = new EventRecord({
    category: category ? serializeCategory(category) : null,
    provider: provider ? serializeEventProvider(provider) : null,
    images: images ? List(images) : null,
    offers: offers ? serializeList(offers, serializeOffer) : null,
    similarEvents: similarEvents
      ? serializeList(similarEvents, serializeEvent)
      : null,
    ...rest,
  });
  return newEvent;
};

/**
 * Serialize events listing
 *
 * @param {EventsListing} {
 *   events,
 *   pagination,
 *   ...rest
 * }
 * @returns {EventsListRecord}
 */
export const serializeEventsListing = ({
  events,
  pagination,
  provider,
  ...rest
}: EventsListing): EventsListRecord => {
  const newEventListing = new EventsListRecord({
    list: events ? serializeList(events, serializeEvent) : null,
    pagination: pagination ? new PaginationRecord(pagination) : null,
    provider: provider ? serializeEventProvider(provider) : null,
    ...rest,
  });

  return newEventListing;
};

/**
 * Serialize event details response
 *
 * @param {EventResponse} {
 *   event,
 *   ...rest
 * }
 * @returns {EventResponseRecord}
 */
export const serializeEventResponse = ({
  event,
  ...rest
}: EventResponse): EventResponseRecord => {
  return new EventResponseRecord({
    event: event ? serializeEvent(event) : null,
    ...rest,
  });
};

/**
 * Serialize event provider
 *
 * @param {EventProvider} provider
 * @returns {EventProviderRecord}
 */
export const serializeEventProvider = (
  provider: EventProvider,
): EventProviderRecord => {
  return new EventProviderRecord(provider);
};

/**
 * Serialize offer
 *
 * @param {Offer} offer
 * @returns {OfferRecord}
 */
export const serializeOffer = (offer: Offer): OfferRecord => {
  return new OfferRecord(offer);
};
