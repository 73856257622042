/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionCreator } from 'redux';
import {
  SimpleActions,
  SimpleListingSuccessPayload,
  SimpleListingRequestPayload,
} from './actions-types';
import { createSimpleActionType, createSimpleListingActionType } from './utils';
import { ConfigCallApi } from '../../providers';

/**
 * REset simple data ActionCreator
 *
 * @param {string} key
 * @returns {SimpleActions<string>}
 */
export const simpleFetchReset: ActionCreator<SimpleActions<string>> = (
  key: string,
): SimpleActions<string> => ({
  type: createSimpleActionType(key, 'RESET'),
});

/**
 * Simple fetch reset succcess messages and error
 *
 * @param {string} key
 * @returns {SimpleActions<string>}
 */
export const simpleFetchResetMessages: ActionCreator<SimpleActions<string>> = (
  key: string,
): SimpleActions<string> => ({
  type: createSimpleActionType(key, 'RESET_MESSAGES'),
});

/**
 * Request simple data ActionCreator
 *
 * @param {string} key
 * @returns {SimpleActions<string>}
 */
export const simpleFetchRequest: ActionCreator<SimpleActions<string>> = (
  key: string,
  meta?: ConfigCallApi,
  suppData?: any,
): SimpleActions<string> => ({
  type: createSimpleActionType(key, 'REQUEST'),
  meta,
  suppData,
});

/**
 * Success response of data ActionCreator
 *
 * @param {string} key
 * @param {*} data
 * @returns {SimpleActions<string, any>}
 */
export const simpleFetchSuccess: ActionCreator<SimpleActions<string, any>> = <
  DATA
>(
  key: string,
  data: DATA,
  suppData?: any,
): SimpleActions<string, any> => ({
  type: createSimpleActionType(key, 'SUCCESS_RESPONSE'),
  payload: data,
  suppData,
});

/**
 * Failed response ActionCreator
 *
 * @param {string} key
 * @param {*} error
 * @returns {SimpleActions<string, string>}
 */
export const simpleFetchFailed: ActionCreator<SimpleActions<string, string>> = (
  key: string,
  error: any,
  suppData?: any,
): SimpleActions<string, any> => ({
  type: createSimpleActionType(key, 'FAILED_RESPONSE'),
  payload: error,
  suppData,
});

/**
 * Loading Start ActionCreator
 *
 * @param {string} key
 * @returns {SimpleActions<string>}
 */
export const simpleFetchLoadingStart: ActionCreator<SimpleActions<string>> = (
  key: string,
): SimpleActions<string> => ({
  type: createSimpleActionType(key, 'LOADING_START'),
});

/**
 * Loading End ActionCreator
 *
 * @param {string} key
 * @returns {SimpleActions<string>}
 */
export const simpleFetchLoadingEnd: ActionCreator<SimpleActions<string>> = (
  key: string,
): SimpleActions<string> => ({
  type: createSimpleActionType(key, 'LOADING_END'),
});

/**
 * Refreshing Start ActionCreator
 *
 * @param {string} key
 * @returns {SimpleActions<string>}
 */
export const simpleFetchRefreshingStart: ActionCreator<
  SimpleActions<string>
> = (key: string): SimpleActions<string> => ({
  type: createSimpleActionType(key, 'REFRESHING_START'),
});

/**
 * Refreshing End ActionCreator
 *
 * @param {string} key
 * @returns {SimpleActions<string>}
 */
export const simpleFetchRefreshingEnd: ActionCreator<SimpleActions<string>> = (
  key: string,
): SimpleActions<string> => ({
  type: createSimpleActionType(key, 'REFRESHING_END'),
});

/**
 * Reset simple listing ActionCreator
 *
 * @param {string} key
 * @returns {SimpleActions<string>}
 */
export const simpleListingReset: ActionCreator<SimpleActions<string>> = (
  key: string,
): SimpleActions<string> => ({
  type: createSimpleListingActionType(key, 'RESET'),
});

/**
 * Simple listing reset success messages and error
 *
 * @param {string} key
 * @returns {SimpleActions<string>}
 */
export const simpleListingResetMessages: ActionCreator<
  SimpleActions<string>
> = (key: string): SimpleActions<string> => ({
  type: createSimpleListingActionType(key, 'RESET_MESSAGES'),
});

/**
 * Request simple listing ActionCreator
 *
 * @param {string} key
 * @returns {SimpleActions<string>}
 */
export const simpleListingRequest: ActionCreator<
  SimpleActions<string, SimpleListingRequestPayload>
> = (
  key: string,
  meta?: ConfigCallApi,
  loadMore = false,
): SimpleActions<string, SimpleListingRequestPayload> => {
  return {
    type: createSimpleListingActionType(key, 'REQUEST'),
    meta,
    payload: {
      loadMore,
    },
  };
};

/**
 * Success response of listing ActionCreator
 *
 * @param {string} key
 * @param {*} data
 * @returns {SimpleActions<string, any>}
 */
export const simpleListingSuccess: ActionCreator<SimpleActions<string, any>> = <
  LISTITEM
>(
  key: string,
  data: SimpleListingSuccessPayload<LISTITEM>,
): SimpleActions<string, any> => ({
  type: createSimpleListingActionType(key, 'SUCCESS_RESPONSE'),
  payload: data,
});

/**
 * Failed listing response ActionCreator
 *
 * @param {string} key
 * @param {*} error
 * @returns {SimpleActions<string, string>}
 */
export const simpleListingFailed: ActionCreator<SimpleActions<string, any>> = (
  key: string,
  error: any,
): SimpleActions<string, any> => ({
  type: createSimpleListingActionType(key, 'FAILED_RESPONSE'),
  payload: error,
});

/**
 * Loading Start  of listing ActionCreator
 *
 * @param {string} key
 * @returns {SimpleActions<string>}
 */
export const simpleListingLoadingStart: ActionCreator<SimpleActions<string>> = (
  key: string,
): SimpleActions<string> => ({
  type: createSimpleListingActionType(key, 'LOADING_START'),
});

/**
 * Loading End of listing ActionCreator
 *
 * @param {string} key
 * @returns {SimpleActions<string>}
 */
export const simpleListingLoadingEnd: ActionCreator<SimpleActions<string>> = (
  key: string,
): SimpleActions<string> => ({
  type: createSimpleListingActionType(key, 'LOADING_END'),
});

/**
 * Refreshing Start of listing ActionCreator
 *
 * @param {string} key
 * @returns {SimpleActions<string>}
 */
export const simpleListingRefreshingStart: ActionCreator<
  SimpleActions<string>
> = (key: string): SimpleActions<string> => ({
  type: createSimpleListingActionType(key, 'REFRESHING_START'),
});

/**
 * Refreshing End of listing ActionCreator
 *
 * @param {string} key
 * @returns {SimpleActions<string>}
 */
export const simpleListingRefreshingEnd: ActionCreator<
  SimpleActions<string>
> = (key: string): SimpleActions<string> => ({
  type: createSimpleListingActionType(key, 'REFRESHING_END'),
});

/**
 * Infinit loading start  of listing ActionCreator
 *
 * @param {string} key
 * @returns {SimpleActions<string>}
 */
export const simpleListingInfinitLoadingStart: ActionCreator<
  SimpleActions<string>
> = (key: string): SimpleActions<string> => ({
  type: createSimpleListingActionType(key, 'INFINIT_LOADING_START'),
});

/**
 * Infinit loading end  of listing ActionCreator
 *
 * @param {string} key
 * @returns {SimpleActions<string>}
 */
export const simpleListingInfinitLoadingEnd: ActionCreator<
  SimpleActions<string>
> = (key: string): SimpleActions<string> => ({
  type: createSimpleListingActionType(key, 'INFINIT_LOADING_END'),
});
