/* eslint-disable @typescript-eslint/no-explicit-any */
import { handleActions, Action } from 'redux-actions';
import { Record } from 'immutable';
import { Reducer } from 'redux';
import {
  SerializeSimpleFetchCallBack,
  SimpleFetchStore,
  SimpleFetchState,
  SimpleFetchStateRecord,
} from './types';
import { createSimpleAction } from './utils';

const KEY_REGEX = /^[a-z]+(_[a-z]+)*$/i;

/**
 * Create simple fetch stores
 *
 * @template DATA
 * @param {string} key
 * @param {SerializeSimpleDataCallBack} [serializeData]
 * @returns {SimpleFetchStore}
 */
const simpleFetchStore = <DATA>(
  key: string,
  serializeData?: SerializeSimpleFetchCallBack,
): SimpleFetchStore => {
  if (!(typeof key === 'string' && KEY_REGEX.test(key))) {
    throw new Error(`Cannot use invalid key: '${key}'.`);
  }

  const KEY = key.toUpperCase();

  const reset = createSimpleAction<void>(KEY, 'RESET');
  const resetMessages = createSimpleAction<void>(KEY, 'RESET_MESSAGES');
  const loadingStart = createSimpleAction<void>(KEY, 'LOADING_START');
  const loadingEnd = createSimpleAction<void>(KEY, 'LOADING_END');
  const refreshingStart = createSimpleAction<void>(KEY, 'REFRESHING_START');
  const refreshingEnd = createSimpleAction<void>(KEY, 'REFRESHING_END');
  const successResponse = createSimpleAction<DATA>(KEY, 'SUCCESS_RESPONSE');
  const failedResponse = createSimpleAction<Error | string>(
    KEY,
    'FAILED_RESPONSE',
  );

  const initData: SimpleFetchState<DATA> = {
    loading: false,
    refreshing: false,
    data: null,
    error: null,
    successMessage: null,
  };

  const SimpleFetch = Record<SimpleFetchState<DATA>>(initData);
  const newSimpleFetch: SimpleFetchStateRecord<DATA> = new SimpleFetch(
    initData,
  );

  const actionsHandled = {
    [reset]: () => newSimpleFetch,
    [resetMessages]: (state: SimpleFetchStateRecord<DATA>) =>
      state.set('error', null).set('successMessage', null),
    [loadingStart]: (state: SimpleFetchStateRecord<DATA>) =>
      state.set('loading', true),
    [loadingEnd]: (state: SimpleFetchStateRecord<DATA>) =>
      state.set('loading', false),
    [refreshingStart]: (state: SimpleFetchStateRecord<DATA>) =>
      state.set('refreshing', true),
    [refreshingEnd]: (state: SimpleFetchStateRecord<DATA>) =>
      state.set('refreshing', false),
    [successResponse]: (
      state: SimpleFetchStateRecord<DATA>,
      { payload }: Action<any>,
    ) => {
      let newState = state.set('error', null);
      if (payload?.message) {
        newState = newState.set('successMessage', payload?.message);
      }
      if (serializeData && payload) {
        return newState.set('data', serializeData(payload));
      }
      return newState.set('data', payload);
    },
    [failedResponse]: (
      state: SimpleFetchStateRecord<DATA>,
      { payload }: Action<any>,
    ) => state.set('error', payload),
  };

  const simpleFetchReducer: Reducer<
    SimpleFetchStateRecord<DATA>,
    any
  > = handleActions<SimpleFetchStateRecord<DATA>, any>(
    actionsHandled,
    newSimpleFetch,
  );

  return {
    state: newSimpleFetch,
    reducer: simpleFetchReducer,
    actionsHandled,
  };
};

export default simpleFetchStore;
