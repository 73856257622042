import { Theme, ThemesResponse } from '../../types';
import { ThemeRecord, ThemesResponseRecord } from '../../models';
import { serializeList } from '../../helpers';
import { serializeSlider } from '../slider';

/**
 * Serialize theme
 *
 * @param {Theme} theme
 * @returns {ThemeRecord}
 */
export const serializeTheme = ({
  sliderItems,
  ...rest
}: Theme): ThemeRecord => {
  const newTheme: ThemeRecord = new ThemeRecord({
    sliderItems: sliderItems
      ? serializeList(sliderItems, serializeSlider)
      : null,
    ...rest,
  });
  return newTheme;
};

/**
 * Serialize themes response
 *
 * @param {ThemesResponse} {
 *   themes,
 *   ...rest
 * }
 * @returns {ThemesResponseRecord}
 */
export const serializeThemesResponse = ({
  themes,
  ...rest
}: ThemesResponse): ThemesResponseRecord => {
  const newThemesResponse: ThemesResponseRecord = new ThemesResponseRecord({
    themes: serializeList(themes || null, serializeTheme),
    ...rest,
  });
  return newThemesResponse;
};
