import { Action } from 'redux';
import { List } from 'immutable';
import {
  PaginationRecord,
  GlobalSuccessPayload,
  GlobalFailedPayload,
} from '../../../helpers';
import { EventRecord } from '../../../models';

export const eventActionsKeys = {
  EVENTS: 'EVENTS',
  PAST_EVENTS: 'PAST_EVENTS',
  EVENT: 'EVENT',
  PROVIDER_EVENTS: 'PROVIDER_EVENTS',
  PROVIDER_PAST_EVENTS: 'PROVIDER_PAST_EVENTS',
};

export const eventActions = {
  DYNAMIC_EVENT_RESET: '@@event/DYANMIC_EVENT_RESET',
  DYANMIC_EVENT_REQUEST: '@@event/DYANMIC_EVENT_REQUEST',
  DYANMIC_EVENT_LOADING_START: '@@event/DYANMIC_EVENT_LOADING_START',
  DYANMIC_EVENT_LOADING_END: '@@event/DYANMIC_EVENT_LOADING_END',
  DYANMIC_EVENT_REFRESHING_START: '@@event/DYANMIC_EVENT_REFRESHING_START',
  DYANMIC_EVENT_REFRESHING_END: '@@event/DYANMIC_EVENT_REFRESHING_END',
  DYANMIC_SET_EVENT_DETAIL: '@@event/DYANMIC_SET_EVENT_DETAIL',
  DYANMIC_SET_EVENTS_LIST: '@@event/DYANMIC_SET_EVENTS_LIST',
  DYANMIC_EVENT_FAILED: '@@event/DYANMIC_EVENT_FAILED',
  DYANMIC_EVENT_RESET_ERROR: '@@event/DYANMIC_EVENT_RESET_ERROR',
};

export type EventActionsTypes =
  | '@@event/DYANMIC_EVENT_RESET'
  | '@@event/DYANMIC_EVENT_REQUEST'
  | '@@event/DYANMIC_EVENT_LOADING_START'
  | '@@event/DYANMIC_EVENT_LOADING_END'
  | '@@event/DYANMIC_EVENT_REFRESHING_START'
  | '@@event/DYANMIC_EVENT_REFRESHING_END'
  | '@@event/DYANMIC_SET_EVENT_DETAIL'
  | '@@event/DYANMIC_SET_EVENTS_LIST'
  | '@@event/DYANMIC_EVENT_FAILED'
  | '@@event/DYANMIC_EVENT_RESET_ERROR';

/**
 * Dynamic event request payload
 *
 * @export
 * @interface DynamicEventRequestPayload
 */
export interface DynamicEventRequestPayload {
  path: string;
  refresh?: boolean;
}

/**
 * Dynamic set event details playload
 *
 * @export
 * @interface DynamicSetEventDetailsPayload
 * @extends {GlobalSuccessPayload}
 */
export interface DynamicSetEventDetailsPayload extends GlobalSuccessPayload {
  event?: EventRecord | null;
}

/**
 * Dynamic set events list payload
 *
 * @export
 * @interface DynamicSetEventsListPayload
 * @extends {GlobalSuccessPayload}
 */
export interface DynamicSetEventsListPayload extends GlobalSuccessPayload {
  pagination?: PaginationRecord | null;
  events?: List<EventRecord> | null;
}

/**
 * Dynamic event failed payload
 *
 * @export
 * @interface DynamicEventFailedPayload
 * @extends {GlobalFailedPayload}
 */
export interface DynamicEventFailedPayload extends GlobalFailedPayload {}

/**
 * Dynamic event reset action
 *
 * @export
 * @interface DynamicEventResetAction
 * @extends {Action<EventActionsTypes>}
 */
export interface DynamicEventResetAction extends Action<EventActionsTypes> {
  type: '@@event/DYANMIC_EVENT_RESET';
}

/**
 * Dynamic event request action
 *
 * @export
 * @interface DynamicEventRequestAction
 * @extends {Action<EventActionsTypes>}
 */
export interface DynamicEventRequestAction extends Action<EventActionsTypes> {
  type: '@@event/DYANMIC_EVENT_REQUEST';
  payload: DynamicEventRequestPayload;
}

/**
 * Dynamic event loading start action
 *
 * @export
 * @interface DynamicEventLoadingStartAction
 * @extends {Action<EventActionsTypes>}
 */
export interface DynamicEventLoadingStartAction
  extends Action<EventActionsTypes> {
  type: '@@event/DYANMIC_EVENT_LOADING_START';
}

/**
 * Dynamic event loading end action
 *
 * @export
 * @interface DynamicEventLoadingEndAction
 * @extends {Action<EventActionsTypes>}
 */
export interface DynamicEventLoadingEndAction
  extends Action<EventActionsTypes> {
  type: '@@event/DYANMIC_EVENT_LOADING_END';
}

/**
 * Dynamic event refreshing start action
 *
 * @export
 * @interface DynamicEventRefreshingStartAction
 * @extends {Action<EventActionsTypes>}
 */
export interface DynamicEventRefreshingStartAction
  extends Action<EventActionsTypes> {
  type: '@@event/DYANMIC_EVENT_REFRESHING_START';
}

/**
 * Dynamic event refreshing end action
 *
 * @export
 * @interface DynamicEventRefreshingEndAction
 * @extends {Action<EventActionsTypes>}
 */
export interface DynamicEventRefreshingEndAction
  extends Action<EventActionsTypes> {
  type: '@@event/DYANMIC_EVENT_REFRESHING_END';
}

/**
 * Dynamic set event details action
 *
 * @export
 * @interface DynamicSetEventDetailsAction
 * @extends {Action<EventActionsTypes>}
 */
export interface DynamicSetEventDetailsAction
  extends Action<EventActionsTypes> {
  type: '@@event/DYANMIC_SET_EVENT_DETAIL';
  payload: DynamicSetEventDetailsPayload;
}

/**
 * Dynamic set events list action
 *
 * @export
 * @interface DynamicSetEventsListAction
 * @extends {Action<EventActionsTypes>}
 */
export interface DynamicSetEventsListAction extends Action<EventActionsTypes> {
  type: '@@event/DYANMIC_SET_EVENTS_LIST';
  payload: DynamicSetEventsListPayload;
}

/**
 * Dynamic event failed action
 *
 * @export
 * @interface DynamicEventFailedAction
 * @extends {Action<EventActionsTypes>}
 */
export interface DynamicEventFailedAction extends Action<EventActionsTypes> {
  type: '@@event/DYANMIC_EVENT_FAILED';
  payload: DynamicEventFailedPayload;
}

/**
 * Dynamic event reset error action
 *
 * @export
 * @interface DynamicEventResetErrorAction
 * @extends {Action<EventActionsTypes>}
 */
export interface DynamicEventResetErrorAction
  extends Action<EventActionsTypes> {
  type: '@@event/DYANMIC_EVENT_RESET_ERROR';
}

export type DynamicEventPayloads =
  | DynamicEventRequestPayload
  | DynamicSetEventDetailsPayload
  | DynamicSetEventsListPayload
  | DynamicEventFailedPayload;

export type DynamicEventActions =
  | DynamicEventResetAction
  | DynamicEventRequestAction
  | DynamicEventLoadingStartAction
  | DynamicEventLoadingEndAction
  | DynamicEventRefreshingStartAction
  | DynamicEventRefreshingEndAction
  | DynamicSetEventDetailsAction
  | DynamicSetEventsListAction
  | DynamicEventFailedAction
  | DynamicEventResetErrorAction;

export type EventPayloads = DynamicEventPayloads;

export type EventActions = DynamicEventActions;
