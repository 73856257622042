import { Category, CategoriesResponse } from '../../types';
import { CategoryRecord, CategoriesResponseRecord } from '../../models';
import { serializeList } from '../../helpers';
import { serializeSlider } from '../slider';

/**
 * Serialize category
 *
 * @param {Category} category
 * @returns {CategoryRecord}
 */
export const serializeCategory = ({
  sliderItems,
  ...rest
}: Category): CategoryRecord => {
  const newCategory: CategoryRecord = new CategoryRecord({
    sliderItems: sliderItems
      ? serializeList(sliderItems, serializeSlider)
      : null,
    ...rest,
  });
  return newCategory;
};

/**
 * Serialize categories response
 *
 * @param {CategoriesResponse} {
 *   categories,
 *   ...rest
 * }
 * @returns {CategoriesResponseRecord}
 */
export const serializeCategoriesResponse = ({
  categories,
  ...rest
}: CategoriesResponse): CategoriesResponseRecord => {
  const newCategoriesResponse: CategoriesResponseRecord = new CategoriesResponseRecord(
    {
      categories: serializeList(categories || null, serializeCategory),
      ...rest,
    },
  );
  return newCategoriesResponse;
};
