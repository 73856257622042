import { reduxForm, FormErrors } from 'redux-form';
import FormView from './forgot-pass-view';
import { formsNames, fieldErrors } from '../constants';
import { SignInFormData, SignInFormProps } from '../types';
import { regularExpresions } from '../../../helpers';

export const validate = ({
  email,
}: SignInFormData): FormErrors<SignInFormData> => {
  const errors: FormErrors<SignInFormData> = {};
  if (!email || email.trim() === '') {
    errors.email = fieldErrors.REQUIRED;
  } else if (!regularExpresions.EMAIL.test(email)) {
    errors.email = fieldErrors.EMAIL;
  }
  return errors;
};

const ForgotPassForm = reduxForm<SignInFormData, SignInFormProps>({
  form: formsNames.FORGOT_PASS,
  validate,
})(FormView);

export default ForgotPassForm;
