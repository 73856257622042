import React from 'react';
import iconG from '../images/icon-g.png';
import iconStore from '../images/icon-store.png';
import iconWhite from '../images/logo-g-white.png';

interface BlocHeaderProps {
  title?: string;
  subtitle?: string;
  iconType?: string;
}

const BlocHeader = (props: BlocHeaderProps) => {
  const { title, subtitle, iconType } = props;

  let icon;
  switch (iconType) {
    case 'S':
      icon = iconStore;
      break;
    case 'W':
      icon = iconWhite;
      break;
    default:
      icon = iconG;
  }
  return (
    <header className="section-header">
      <img src={icon} alt="guichet" />
      <h2>{title}</h2>
      <h4>{subtitle}</h4>
    </header>
  );
};

export default BlocHeader;
