import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import {
  AppState,
  sliderActionsKeys,
  dynamicComponentActionsKeys,
  appActionKeys,
  CountriesResponse,
} from '../types';
import categoryReducer from './category';
import themeReducer from './theme';
import eventReducer from './event';
import {
  createSimpleFetchStore,
  serializeGlobalResponse,
  GlobalResponseRecord,
} from '../helpers';
import { SliderResponseRecord } from '../models';
import {
  serializeSliderResponse,
  serializeDynamicComponent,
} from '../providers';
import authenticationRedcuer from './authentication';
import cartRedudcer from './cart';
import campaignReducer from './campaign';
import productReducer from './product';
import userReducer from './user';
import magazineReducer from './magazine';
import searchReducer from './search';

export default combineReducers<AppState>({
  form: formReducer,
  authentication: authenticationRedcuer,
  category: categoryReducer,
  theme: themeReducer,
  event: eventReducer,
  slider: createSimpleFetchStore<SliderResponseRecord>(
    sliderActionsKeys.SLIDER,
    serializeSliderResponse,
  ).reducer,
  cart: cartRedudcer,
  dynamicComponent: createSimpleFetchStore<SliderResponseRecord>(
    dynamicComponentActionsKeys.DYNAMIC_COMPONENT,
    serializeDynamicComponent,
  ).reducer,
  campaign: campaignReducer,
  product: productReducer,
  contact: createSimpleFetchStore<GlobalResponseRecord>(
    appActionKeys.CONTACT,
    serializeGlobalResponse,
  ).reducer,
  user: userReducer,
  magazine: magazineReducer,
  search: searchReducer,
  globalCountries: createSimpleFetchStore<CountriesResponse>(
    appActionKeys.GLOBAL_COUNTRIES,
  ).reducer,
});
