/* eslint-disable no-shadow */
import { List } from 'immutable';
import {
  PaymentMethodRecord,
  CartRecord,
  CartManagementRecord,
  EventRecord,
  OfferRecord,
  CartItemRecord,
  CartDataRecord,
} from '../../../models';
import { GlobalResponse } from '../../../helpers';
import { Event, Offer } from '../event';

/**
 * Cart state
 *
 * @export
 * @interface CartState
 */
export interface CartState {
  cartDataState: CartDataRecord;
  cartManagement?: CartManagementRecord;
}

/**
 * Cart
 *
 * @export
 * @interface Cart
 */
export interface Cart {
  carriers: any[] | null;
  discount: null;
  count: number;
  subTotal: number | null;
  total: number | null;
  totalShipping: number | null;
  items: CartItem[] | null;
  paymentMethods: PaymentMethod[] | null;
  countries: any[] | null;
  cities: any[] | null;
  adresses: any[] | null;
  hasPlan?: boolean;
}

/**
 * Cart for record
 *
 * @export
 * @interface CartForRec
 */
export interface CartForRec {
  carriers: List<any> | null;
  discount: null;
  count: number;
  subTotal: number | null;
  total: number | null;
  totalShipping: number | null;
  totalDiscount: number | null;
  totalDiscountString: string | null;
  items: List<CartItemRecord> | null;
  paymentMethods: List<PaymentMethodRecord> | null;
  hasPlan?: boolean;
}

/**
 * Carrier
 *
 * @export
 * @interface CarrierItem
 */
export interface CarrierItem {
  id: number;
  title: string | null;
  description?: string | null;
  delay?: string | null;
  logo?: string | null;
  price?: string | null;
}

/**
 * Payment method
 *
 * @export
 * @interface PaymentMethod
 */
export interface PaymentMethod {
  id: number;
  title: string | null;
  description?: string | null;
  module?: 'cmi' | 'fpay' | 'paydunya' | null;
}

export interface CartData {
  cart: any;
  loading: boolean;
  loadingPage: boolean;
  error: any;
  successMessage: any;
  countries: any;
  cities: any;
}

export interface CartManagement {
  step: number;
  selectedAddressId: number;
  paymentInfo: any;
  instruction: string;

  selectedCarrierId: number;
  selectedPaymentId: number;
}

export type UpdateCartType = 'up' | 'down';

/**
 * Carte item
 *
 * @export
 * @interface CartItem
 */
export interface CartItem {
  itemId: string | null;
  quantity: number;
  total: string | null;
  fields?: CartField[] | null;
  product: {
    id: string | null;
    title: string | null;
    image: string | null;
    price: string | null;
    seatsChartId?: string | null;
    isPlan?: boolean;
  };
  attributes?:
    | {
        name: string;
        value: string;
      }[]
    | null;
}

/**
 *
 *
 * @export
 * @enum {number}
 */
export enum CheckoutSteps {
  PAYMENT = 'payment',
  FIN = 'finalization',
}

/**
 * Cart item for rec
 *
 * @export
 * @interface CartItemForRec
 */
export interface CartItemForRec {
  itemId: string | null;
  quantity: number;
  total: string | null;
  fields?: CartField[] | null;
  product: {
    id: string | null;
    title: string | null;
    image: string | null;
    price: string | null;
    seatsChartId?: string | null;
    isPlan?: boolean;
  };
  attributes?:
    | {
        name: string;
        value: string;
      }[]
    | null;
}

/// ///////////////// BEGIN CART FIELD //////////////////////

/**
 * Cart field
 *
 * @export
 * @interface CartField
 */
export interface CartField {
  name: string;
  type: string;
  label: string;
  validations: CartFiedValidation[] | null;
  values: CartFieldValue[] | null;
  is_multiple: boolean;
}

/**
 * Cart fild value
 *
 * @export
 * @interface CartFieldValue
 */
export interface CartFieldValue {
  label: string;
  value: string | number;
}

export type CartFiedValidation = 'required' | string;

/**
 * Cart field type
 *
 * @export
 * @enum {number}
 */
export enum CartFieldType {
  Text = 'text',
  Date = 'date',
  Radio = 'radio',
  Checkbox = 'checkbox',
  Select = 'select',
}

/**
 * Cart response
 *
 * @export
 * @interface CartResponse
 * @extends {GlobalResponse}
 */
export interface CartResponse extends GlobalResponse {
  cart?: Cart | null;
}

/**
 * Cart response for rec
 *
 * @export
 * @interface CartResponseForRec
 * @extends {GlobalResponse}
 */
export interface CartResponseForRec extends GlobalResponse {
  cart?: CartRecord | null;
}

/// ///////////////// END CART FIELD //////////////////////
