import React, { FunctionComponent, useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { SliderDispatchProps, SliderStateProps } from './types';
import { AppState, sliderType } from '../../types';
import {
  fetchSliderData as fetchSliderDataAC,
  resetSliderDataMessages as resetSliderDataMessagesAC,
  resetSliderData as resetSliderDataAC,
} from '../../actions';
import {
  getSliderDataLoading,
  getSliderDataRefreshing,
  getSliderDataList,
  getSliderDataError,
} from '../../selectors';
import BannerSlider from '../../components/BannerSlider';

interface SliderProps extends SliderDispatchProps, SliderStateProps {
  type?: sliderType;
}

const Slider: FunctionComponent<SliderProps> = ({
  fetchSliderData,
  resetSliderData,
  sliderData,
  type,
}: SliderProps) => {
  useEffect(() => {
    fetchSliderData(undefined, type);
    return () => {
      resetSliderData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* <LoadingPage loading={loading} /> */}
      <BannerSlider items={sliderData} />
    </>
  );
};

/**
* Slider map state to props
*
* @param {Dispatch} dispatch
@returns {SliderDispatchProps}
*/
const mapDispatchToProps = (dispatch: Dispatch): SliderDispatchProps => {
  return bindActionCreators(
    {
      fetchSliderData: fetchSliderDataAC,
      resetSliderDataMessages: resetSliderDataMessagesAC,
      resetSliderData: resetSliderDataAC,
    },
    dispatch,
  );
};

/**
 * Slider map state to props
 *
 * @param {AppState} appState
 * @returns {SliderStateProps}
 */
const mapStateToProps = (appState: AppState): SliderStateProps => {
  return {
    sliderData: getSliderDataList(appState),
    loading: getSliderDataLoading(appState),
    refreshing: getSliderDataRefreshing(appState),
    error: getSliderDataError(appState),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Slider);
