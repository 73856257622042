import React, { FunctionComponent } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { SignUpStateProps } from './types';
import { AppState } from '../../types';
import {
  getForgotPasswordLoading,
  getForgotPasswordError,
  getForgotPasswordSuccess,
} from '../../selectors';
import Message from '../../components/common/Message';
import {
  forgotPassword as forgotPasswordAc,
  resetForgotPasswordMessages as resetForgotPasswordMessagesAc,
} from '../../actions';
import ForgotPassC from '../../components/pages/ForgotPassC';

const ForgotPass: FunctionComponent<any> = ({
  loading,
  resetForgotPasswordMessages,
  forgotPassword,
  error,
  successMessage,
}: any) => {
  return (
    <>
      <Message
        success={
          successMessage
            ? {
                successMessage,
                resetSuccess: () => resetForgotPasswordMessages(),
              }
            : null
        }
        error={
          error
            ? {
                errorMessage: error.error,
                resetError: () => resetForgotPasswordMessages(),
              }
            : null
        }
      />
      <ForgotPassC onSubmit={forgotPassword} loading={loading} />
    </>
  );
};

/**
 * SignUp map state to props
 *
 * @param {Dispatch} dispatch
 */
const mapDispatchToProps = (dispatch: Dispatch): any => {
  return bindActionCreators(
    {
      forgotPassword: forgotPasswordAc,
      resetForgotPasswordMessages: resetForgotPasswordMessagesAc,
    },
    dispatch,
  );
};

/**
 * SignUp map state to props
 *
 * @param {AppState} appState
 * @returns {SignUpStateProps}
 */
const mapStateToProps = (appState: AppState): any => {
  return {
    loading: getForgotPasswordLoading(appState),
    error: getForgotPasswordError(appState),
    successMessage: getForgotPasswordSuccess(appState),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPass);
