import React from 'react';
import MetaTags from 'react-meta-tags';

interface AppMetaProps {
  title: string;
  description?: string;
  hideFromRobots?: boolean;
  isHome?: boolean;
  prefix?: string;
  keywords?: string;
}

export default function AppMeta({
  title,
  description = 'Guichet.com: Tickets &amp; Billetterie concerts, spectacles, cinéma, festivals, sport et théâtre au Maroc',
  hideFromRobots = false,
  isHome = false,
  prefix = 'Guichet.com',
  keywords = 'Guichet.com, ticket, billetterie, concerts, casablanca, rabat, marrakech, agadir, tanger, spectacles, festivals, sport, theatre, humour, maroc',
}: AppMetaProps) {
  let fTitle = title || '';
  if (prefix)
    fTitle = isHome
      ? `${prefix} - ${title || ''}`
      : `${title || ''} - ${prefix}`;

  return (
    <MetaTags>
      <title>{fTitle}</title>
      <meta
        name="description"
        content={
          description ||
          'Guichet.com: Tickets &amp; Billetterie concerts, spectacles, cinéma, festivals, sport et théâtre au Maroc'
        }
      />
      <meta
        name="keywords"
        content={
          keywords ||
          'Guichet.com, ticket, billetterie, concerts, casablanca, rabat, marrakech, agadir, tanger, spectacles, festivals, sport, theatre, humour, maroc'
        }
      />

      {!hideFromRobots ? (
        <meta name="robots" content="index, follow" />
      ) : (
        <meta name="robots" content="noindex, nofollow" />
      )}

      <meta property="og:title" content={fTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content="https://www.guichet.com" />
      <meta property="og:type" content="articles" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@guichetcom" />
      <meta name="twitter:title" content={fTitle} />
      <meta name="twitter:description" content={description} />
    </MetaTags>
  );
}
