import { Reducer, combineReducers } from 'redux';
import connectionReducer from './connection';
import {
  AuthenticationState,
  AuthenticationActions,
  authActionKeys,
} from '../types';
import {
  createSimpleFetchStore,
  GlobalResponseRecord,
  serializeGlobalResponse,
} from '../helpers';

const reducers: Reducer<
  AuthenticationState,
  AuthenticationActions
> = combineReducers<AuthenticationState, AuthenticationActions>({
  connection: connectionReducer,
  signIn: createSimpleFetchStore<GlobalResponseRecord>(
    authActionKeys.SIGN_IN,
    serializeGlobalResponse,
  ).reducer,
  signUp: createSimpleFetchStore<GlobalResponseRecord>(
    authActionKeys.SIGN_UP,
    serializeGlobalResponse,
  ).reducer,
  socialSignIn: createSimpleFetchStore<GlobalResponseRecord>(
    authActionKeys.SOCIAL_SIGN_IN,
    serializeGlobalResponse,
  ).reducer,
  forgotPassword: createSimpleFetchStore<GlobalResponseRecord>(
    authActionKeys.FORGOT_PASSWORD,
    serializeGlobalResponse,
  ).reducer,
  recoverPassword: createSimpleFetchStore<GlobalResponseRecord>(
    authActionKeys.RECOVER_PASSWORD,
    serializeGlobalResponse,
  ).reducer,
});

export default reducers;
