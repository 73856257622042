import React, { FunctionComponent, useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { useHistory } from 'react-router-dom';
import {
  ProviderEventsGridDispatchProps,
  ProviderEventsGridStateProps,
} from './types';
import { AppState } from '../../types';
import {
  fetchProviderEvents as fetchEventsAC,
  resetProviderEventsMessages as resetEventsMessagesAC,
  resetProviderEvents as resetEventsAC,
} from '../../actions';
import {
  getProviderEventsInfinitLoading,
  getProviderEventsList,
  getProviderEventsLoading,
  getProviderEventsError,
  getProviderEventsRefreshing,
  getProviderEventsPagination,
} from '../../selectors';
import EventsGridC from '../../components/EventsGrid';

interface EventsGridProps
  extends ProviderEventsGridDispatchProps,
    ProviderEventsGridStateProps {
  providerSlug?: string;
}

const EventsGrid: FunctionComponent<EventsGridProps> = ({
  fetchEvents,
  resetEvents,
  events,

  providerSlug,
  pagination,
  infinitLoading,
  loading,
  error,
}: EventsGridProps) => {
  const history = useHistory();

  useEffect(() => {
    if (error?.status === 404) history.replace('/404');
  }, [error, history]);

  useEffect(() => {
    fetchEvents(providerSlug);
    return () => {
      resetEvents();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerSlug]);

  return (
    <InfiniteScroll
      pageStart={0}
      loadMore={() => {
        if (!infinitLoading && !loading) {
          fetchEvents(providerSlug, true, pagination.get('nextPage'));
        }
      }}
      hasMore={
        !infinitLoading &&
        !loading &&
        pagination &&
        pagination.get('nextPage') &&
        pagination.get('nextPage') !== -1
      }
    >
      <EventsGridC events={events} hasHeader={false} />
    </InfiniteScroll>
  );
};

/**
* EventsGrid map state to props
*
* @param {Dispatch} dispatch
@returns {EventsGridDispatchProps}
*/
const mapDispatchToProps = (
  dispatch: Dispatch,
): ProviderEventsGridDispatchProps => {
  return bindActionCreators(
    {
      fetchEvents: fetchEventsAC,
      resetEventsMessages: resetEventsMessagesAC,
      resetEvents: resetEventsAC,
    },
    dispatch,
  );
};

/**
 * EventsGrid map state to props
 *
 * @param {AppState} appState
 * @returns {EventsGridStateProps}
 */
const mapStateToProps = (appState: AppState): ProviderEventsGridStateProps => {
  return {
    events: getProviderEventsList(appState),
    loading: getProviderEventsLoading(appState),
    error: getProviderEventsError(appState),
    refreshing: getProviderEventsRefreshing(appState),
    pagination: getProviderEventsPagination(appState),
    infinitLoading: getProviderEventsInfinitLoading(appState),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsGrid);
