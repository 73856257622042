import { Record } from 'immutable';
import { DynamicComponentForRec } from '../types';

/**
 * Dynamic component record
 *
 * @export
 * @class DynamicComponentRecord
 * @extends {Record<DynamicComponentForRec>()}
 */
export class DynamicComponentRecord extends Record<DynamicComponentForRec>(
  {
    event: null,
    category: null,
    campaign: null,
    product: null,
    theme: null,
    term: null,
    topic: null,
    article: null,
    page: null,
  },
  'dynamic-component',
) {}
