import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';

import { Image } from '../types';

const SETTINGS_NAV = {
  vertical: false,
  arrows: false,
  slidesToScroll: 1,
};
const SETTINGS_MAIN = {
  slidesToShow: 1,
  arrows: false,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

interface EventImagesProps {
  images: Image[];
  title: string;
}

const AsNavFor = ({ images, title }: EventImagesProps) => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const slider1Ref = useRef(null);
  const slider2Ref = useRef(null);

  useEffect(() => {
    setNav1(slider1Ref.current);
    setNav2(slider2Ref.current);
  }, []);

  return (
    <div>
      <div>
        <Slider
          {...SETTINGS_MAIN}
          className="evnet-slider-main"
          asNavFor={nav1}
          ref={slider2Ref}
        >
          {images.map((img, index) => (
            <div key={index}>
              <img src={img.path} alt={title} />
            </div>
          ))}
        </Slider>
      </div>

      {images && (
        <div
          className={
            images.length <= 1 ? 'small-events-slider-hidden-on-mobile' : null
          }
        >
          <Slider
            {...SETTINGS_NAV}
            slidesToShow={images.length}
            asNavFor={nav2}
            responsive={[
              {
                breakpoint: 768,
                settings: {
                  vertical: false,
                  slidesToShow: images.length,
                  slidesToScroll: 1,
                },
              },
            ]}
            ref={slider1Ref}
            focusOnSelect
            className="evnet-slider-nav"
          >
            {images.map((img, index) => (
              <div key={index}>
                <div className="img-container">
                  <img src={img.path} alt={`event-silide-img-${img.id}`} />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      )}
    </div>
  );
};

export default AsNavFor;
