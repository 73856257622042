import { call, put, takeEvery } from 'redux-saga/effects';
import {
  addressesFailed,
  addressesLoadingEnd,
  addressesLoadingStart,
  addressesSuccess,
  addShippingAddress,
  putAddress,
  removeAddress,
  setAddress,
  setAddresses,
} from '../actions';
import { callApi } from '../providers';
import { addressActionKeys } from '../types';

function* addAddress({ payload }: any) {
  yield put(addressesLoadingStart());
  try {
    const { address, message }: any = yield call(callApi, payload);
    if (address) {
      yield put(setAddress(address));

      // add shipping address
      if (payload.isCart) yield put(addShippingAddress(address.id));

      // success
      if (message) yield put(addressesSuccess(message));

      //
      payload.callback();
    }
  } catch (error) {
    yield put(addressesFailed(error));
  } finally {
    yield put(addressesLoadingEnd());
  }
}

function* getAddresses({ payload }: any) {
  yield put(addressesLoadingStart());
  try {
    const { addresses }: any = yield call(callApi, payload);
    if (addresses) {
      yield put(setAddresses(addresses));
    }
  } catch (error) {
    yield put(addressesFailed(error));
  } finally {
    yield put(addressesLoadingEnd());
  }
}

function* deleteAddress({ payload }: any) {
  yield put(addressesLoadingStart());
  const { itemIndex } = payload;
  try {
    const { success, message }: any = yield call(callApi, payload);
    if (success) {
      yield put(removeAddress(itemIndex));
      // success
      if (message) yield put(addressesSuccess(message));
    }
  } catch (error) {
    yield put(addressesFailed(error));
  } finally {
    yield put(addressesLoadingEnd());
  }
}

function* updateAddress({ payload }: any) {
  yield put(addressesLoadingStart());
  try {
    const { address, message }: any = yield call(callApi, payload);
    if (address) {
      yield put(putAddress(address));
      // success
      if (message) yield put(addressesSuccess(message));

      payload.callback();
    }
  } catch (error) {
    yield put(addressesFailed(error));
  } finally {
    yield put(addressesLoadingEnd());
  }
}

export default function* addressesSaga() {
  yield takeEvery(addressActionKeys.ADD_ADDRESS, addAddress);
  yield takeEvery(addressActionKeys.GET_ADDRESSES, getAddresses);
  yield takeEvery(addressActionKeys.ADDRESS_DELETED, deleteAddress);
  yield takeEvery(addressActionKeys.ADDRESS_UPDATED, updateAddress);
}
