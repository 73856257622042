import React from 'react';

interface LoadingPopupProps {
  loading?: boolean;
}

export default function LoadingPopup({ loading }: LoadingPopupProps) {
  if (loading)
    return (
      <div className="loading-popup">
        <div className="lds-spinner">
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    );

  return null;
}
