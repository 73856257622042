import React from 'react';
import iconG from '../../images/icon-g.png';

interface LoadingPageProps {
  loading?: boolean;
}

export default function LoadingPage({ loading }: LoadingPageProps) {
  if (loading)
    return (
      <div className="loading-page">
        <img src={iconG} />
      </div>
    );

  return null;
}
