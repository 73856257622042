/* eslint-disable react/no-danger */
import React from 'react';
import AppMeta from '../common/AppMeta';

export default function Page({ page }: any) {
  return (
    <>
      <AppMeta
        title={page.get('metaTitle')}
        description={page.get('metaDescription')}
        keywords={page.get('metaKeywords')}
      />
      <div className="container pt-5 pb-5">
        <h1 className="text-center mb-5">{page.get('title')}</h1>
        <div dangerouslySetInnerHTML={{ __html: page.get('content') }} />
      </div>
    </>
  );
}
