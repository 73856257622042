import React, { FunctionComponent } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../types';
import MySingleOrdersC from '../../components/pages/mySingleOrderC';
import { getSingleOrder as getSingleOrderAc } from '../../actions';
import { getOrderDetailsLoading, getOrderDetailsState } from '../../selectors';
import LoadingPage from '../../components/common/LoadingPage';

const MySingleOrder: FunctionComponent<any> = ({
  loading,
  getSingleOrder,
  orderDetails,
}: any) => {
  return (
    <>
      <LoadingPage loading={loading} />
      <MySingleOrdersC
        loading={loading}
        getSingleOrder={getSingleOrder}
        orderDetails={orderDetails}
      />
    </>
  );
};

/**
 * MyOrders map state to props
 *
 * @param {Dispatch} dispatch
 */
const mapDispatchToProps = (dispatch: Dispatch): any => {
  return bindActionCreators(
    {
      getSingleOrder: getSingleOrderAc,
    },
    dispatch,
  );
};

/**
 * MyOrders map state to props
 *
 * @param {AppState} appState
 */
const mapStateToProps = (appState: AppState): any => {
  return {
    orderDetails: getOrderDetailsState(appState),
    loading: getOrderDetailsLoading(appState),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MySingleOrder);
