import { ActionCreator } from 'redux';
import {
  cartActions,
  cartActionKeys,
  UpdateCartType,
  ChangeCartStepAction,
  ChangeCartStepPlayload,
} from '../types';
import {
  SimpleActions,
  simpleFetchResetMessages,
  simpleFetchReset,
} from '../helpers';
import {
  ConfigCallApi,
  ApiRoutes,
  AddToCartPostData,
  UpdateCartPostData,
  ApplyDiscountPostData,
  AddShippingPostData,
  CheckoutPostData,
  orderCheckPostData,
  DeleteSeatPostData,
} from '../providers';

/**
 * Fetch Cart action creator
 *
 * @param {boolean} [refresh]
 * @returns {SimpleActions}
 */
export const fetchCart: ActionCreator<SimpleActions> = (
  refresh?: boolean,
): SimpleActions => {
  const confiApi: ConfigCallApi = {
    url: ApiRoutes.cart,
    callGlobalError: false,
    refresh,
    secure: true,
  };
  return {
    type: cartActions.CART_REQUEST,
    payload: confiApi,
  };
};

/**
 * Set cart data
 *
 * @param {any} payload
 * @returns {any}
 */
export const setCartData: ActionCreator<any> = (payload: any): any => ({
  type: cartActions.SET_CART_DATA,
  payload,
});

/**
 * Set selected address
 *
 * @param {any} payload
 * @returns {any}
 */
export const setSelectedAddress: ActionCreator<any> = (payload: any): any => ({
  type: cartActions.SET_SELECTED_ADDRESS,
  payload,
});

/**
 * setSelectedCarrier
 *
 * @param {any} payload
 * @returns {any}
 */
export const setSelectedCarrier: ActionCreator<any> = (payload: any): any => ({
  type: cartActions.SER_SELECTED_CARRIER_ID,
  payload,
});

/**
 * setSelectedPayment
 *
 * @param {any} payload
 * @returns {any}
 */
export const setSelectedPayment: ActionCreator<any> = (payload: any): any => ({
  type: cartActions.SET_SELECTED_PAYMENT_ID,
  payload,
});

/**
 * Set countries
 *
 * @param {any} payload
 * @returns {any}
 */
export const setCountries: ActionCreator<any> = (payload: any): any => ({
  type: cartActions.SET_COUNTRIES,
  payload,
});

/**
 * Set cities
 *
 * @param {any} payload
 * @returns {any}
 */
export const setCities: ActionCreator<any> = (payload: any): any => ({
  type: cartActions.SET_CITIES,
  payload,
});

/**
 * Cart loading start
 *
 * @returns {any}
 */
export const cartLoadingStart: ActionCreator<any> = (): any => ({
  type: cartActions.CART_LOADING_START,
});

/**
 * Cart loading end
 *
 * @returns {any}
 */
export const cartLoadingEnd: ActionCreator<any> = (): any => ({
  type: cartActions.CART_LOADING_END,
});

/**
 * Cart loading page start
 *
 * @returns {any}
 */
export const cartLoadingPageStart: ActionCreator<any> = (): any => ({
  type: cartActions.CART_LOADING_PAGE_START,
});

/**
 * Cart loading page end
 *
 * @returns {any}
 */
export const cartLoadingPageEnd: ActionCreator<any> = (): any => ({
  type: cartActions.CART_LOADING_PAGE_END,
});

/**
 *  event failed
 *
 * @param {any} payload
 * @returns {any}
 */
export const cartFailed: ActionCreator<any> = (payload: any): any => ({
  type: cartActions.CART_FAILED,
  payload,
});

/**
 *  event success
 *
 * @param {any} payload
 * @returns {any}
 */
export const cartSuccess: ActionCreator<any> = (payload: any): any => ({
  type: cartActions.CART_SUCCESS,
  payload,
});

/**
 * Reset Cart messages action creator
 *
 * @returns {SimpleActions}
 */
export const resetCartMessages: ActionCreator<SimpleActions> = (): SimpleActions =>
  simpleFetchResetMessages(cartActionKeys.CART);

/**
 * Reset Cart action creator
 *
 * @returns {SimpleActions}
 */
export const resetCart: ActionCreator<SimpleActions> = (): SimpleActions =>
  simpleFetchReset(cartActionKeys.CART);

/**
 * Add to Cart action creator
 *
 * @param {boolean} [refresh]
 * @returns {SimpleActions}
 */
export const addToCart: ActionCreator<SimpleActions> = (
  quantity: number,
  offerId?: number,
  callback?: () => void,
  productId?: number,
  combinationId?: number,
  seats?: string,
  eventPlanKey?: string,
): SimpleActions => {
  const postData = (offerId
    ? {
        offer_id: offerId,
        quantity,
        seats,
      }
    : {
        quantity,
        product_id: productId,
        combination_id: combinationId,
      }) as AddToCartPostData;

  const confiApi: ConfigCallApi = {
    url: ApiRoutes.addToCart,
    callGlobalError: false,
    method: 'post',
    secure: true,
    postData,
    callback,
    isPlan: !!seats,
    eventPlanKey,
  };
  return {
    type: cartActions.CART_ITEM_ADDED,
    payload: confiApi,
  };
};

export const deleteSeat: ActionCreator<any> = (
  offerId: number,
  seats: string,
): any => {
  const postData: DeleteSeatPostData = {
    offer_id: offerId,
    seat: seats,
  };

  const confiApi: ConfigCallApi = {
    url: ApiRoutes.deleteSeat,
    callGlobalError: false,
    method: 'post',
    secure: true,
    postData,
  };
  return {
    type: cartActions.CART_ITEM_SEAT_DELETE,
    payload: confiApi,
  };
};

/**
 * Destroy Cart action creator
 *
 * @param {boolean} [refresh]
 * @returns {SimpleActions}
 */
export const destroyCart: ActionCreator<SimpleActions> = (): SimpleActions => {
  const confiApi: ConfigCallApi = {
    url: ApiRoutes.destroyCart,
    callGlobalError: false,
    secure: true,
    method: 'post',
  };
  return {
    type: cartActions.CART_DESTROYED,
    payload: confiApi,
  };
};

/**
 * Add to Cart action creator
 *
 * @param {boolean} [refresh]
 * @returns {SimpleActions}
 */
export const checkout: ActionCreator<SimpleActions> = (
  items: any,
  payment_method_id: number,
  reference: any,
  isConnected: boolean,
): SimpleActions => {
  const confiApi: ConfigCallApi = {
    url: ApiRoutes.checkout,
    secure: true,
    callGlobalError: false,
    method: 'post',
    isConnected,
    postData: {
      items,
      payment_method_id,
      reference,
      source: 'guichet',
    } as CheckoutPostData,
  };
  return {
    type: cartActions.CHECKOUT,
    payload: confiApi,
  };
};

/**
 * Finalization action creator
 *
 * @param {boolean} [refresh]
 * @returns {SimpleActions}
 */
export const checkoutFin: ActionCreator<SimpleActions> = (
  reference: string,
): SimpleActions => {
  const confiApi: ConfigCallApi = {
    url: `${ApiRoutes.finalization}/${reference}`,
    secure: true,
    callGlobalError: false,
  };
  return {
    type: cartActions.FINALIZATION,
    payload: confiApi,
  };
};

/**
 * Apply discount action creator
 *
 * @param {boolean} [refresh]
 * @returns {SimpleActions}
 */
export const applyDiscount: ActionCreator<SimpleActions> = (
  code: string,
): SimpleActions => {
  const confiApi: ConfigCallApi = {
    url: ApiRoutes.applyDiscount,
    callGlobalError: false,
    secure: true,
    method: 'post',
    postData: { code } as ApplyDiscountPostData,
  };
  return {
    type: cartActions.APPLY_DISCOUNT,
    payload: confiApi,
  };
};

/**
 * Clear discount action creator
 *
 * @param {boolean} [refresh]
 * @returns {SimpleActions}
 */
export const clearDiscount: ActionCreator<SimpleActions> = (): SimpleActions => {
  const confiApi: ConfigCallApi = {
    url: ApiRoutes.clearDiscount,
    callGlobalError: false,
    secure: true,
    method: 'post',
  };
  return {
    type: cartActions.CLEAR_DISCOUNT,
    payload: confiApi,
  };
};

/**
 * Add shipping address action creator
 *
 * @param {boolean} [refresh]
 * @returns {SimpleActions}
 */
export const addShippingAddress: ActionCreator<SimpleActions> = (
  delivery_address_id?: number,
  carriere_id?: number,
): SimpleActions => {
  const confiApi: ConfigCallApi = {
    url: ApiRoutes.addShippingAddress,
    callGlobalError: false,
    secure: true,
    method: 'post',
    postData: { delivery_address_id, carriere_id } as AddShippingPostData,
  };
  return {
    type: cartActions.ADD_SHIPPING_ADDRESSES,
    payload: confiApi,
  };
};

/**
 * Get countries action creator
 *
 * @param {boolean} [refresh]
 * @returns {SimpleActions}
 */
export const getCountries: ActionCreator<SimpleActions> = () => {
  const confiApi: ConfigCallApi = {
    url: ApiRoutes.countries,
    callGlobalError: false,
    method: 'get',
  };
  return {
    type: cartActions.GET_COUNTRIES,
    payload: confiApi,
  };
};

/**
 * Get cities action creator
 *
 * @param {boolean} [refresh]
 * @returns {SimpleActions}
 */
export const getCities: ActionCreator<SimpleActions> = () => {
  const confiApi: ConfigCallApi = {
    url: ApiRoutes.cities,
    callGlobalError: false,
    method: 'get',
  };
  return {
    type: cartActions.GET_CITIES,
    payload: confiApi,
  };
};

/**
 * Reset Cartadd action creator
 *
 * @returns {SimpleActions}
 */
export const resetCartadd: ActionCreator<SimpleActions> = (): SimpleActions =>
  simpleFetchReset(cartActionKeys.ADD_TO_CART);

/**
 * Reset Cartadd messages action creator
 *
 * @returns {SimpleActions}
 */
export const resetCartaddMessages: ActionCreator<SimpleActions> = (): SimpleActions =>
  simpleFetchResetMessages(cartActionKeys.ADD_TO_CART);

/**
 * Update Cart action creator
 *
 * @param {boolean} [refresh]
 * @returns {SimpleActions}
 */
export const updateCart: ActionCreator<SimpleActions> = (
  itemId: number,
  type: UpdateCartType,
  quantity: number,
  itemIndex: number,
): SimpleActions => {
  const confiApi: ConfigCallApi = {
    url: `${ApiRoutes.updateCart}/${itemId}`,
    callGlobalError: false,
    method: 'post',
    secure: true,
    postData: {
      quantity,
      type,
      itemIndex,
    } as UpdateCartPostData,
  };
  return {
    type: cartActions.CART_ITEM_UPDATED,
    payload: confiApi,
  };
};

/**
 * Reset CartUpdate action creator
 *
 * @returns {SimpleActions}
 */
export const resetCartUpdate: ActionCreator<SimpleActions> = (): SimpleActions =>
  simpleFetchReset(cartActionKeys.UPDATE_CART);

/**
 * Reset CartUpdate messages action creator
 *
 * @returns {SimpleActions}
 */
export const resetCartUpdateMessages: ActionCreator<SimpleActions> = (): SimpleActions =>
  simpleFetchResetMessages(cartActionKeys.UPDATE_CART);

/**
 * Delete Cart Item action creator
 *
 * @param {boolean} [refresh]
 * @returns {SimpleActions}
 */
export const deleteCartItem: ActionCreator<SimpleActions> = (
  itemId: number,
  itemIndex: number,
): SimpleActions => {
  const confiApi: ConfigCallApi = {
    url: `${ApiRoutes.deleteCartIem}/${itemId}`,
    callGlobalError: false,
    method: 'delete',
    secure: true,
    itemIndex,
  };
  return {
    type: cartActions.CART_ITEM_DELETED,
    payload: confiApi,
  };
};

/**
 * Reset CartDelete action creator
 *
 * @returns {SimpleActions}
 */
export const resetCartDelete: ActionCreator<SimpleActions> = (): SimpleActions =>
  simpleFetchReset(cartActionKeys.DELETE_CART_IEM);

/**
 * Reset CartDelete messages action creator
 *
 * @returns {SimpleActions}
 */
export const resetCartDeleteMessages: ActionCreator<SimpleActions> = (): SimpleActions =>
  simpleFetchResetMessages(cartActionKeys.DELETE_CART_IEM);

/**
 * Change cart step action creator
 *
 * @param {ChangeCartStepPlayload} payload
 * @returns {ChangeCartStepAction}
 */
export const changeCartStep: ActionCreator<ChangeCartStepAction> = (
  payload: ChangeCartStepPlayload,
): ChangeCartStepAction => ({
  type: '@@cart_management/CHANGE_STEP',
  payload,
});

/**
 * Set Payment Info
 *
 * @param {any} payload
 * @returns {any}
 */
export const setPaymentInfo: ActionCreator<any> = (payload: any): any => ({
  type: cartActions.SET_PAYMENT_INFO,
  payload,
});

/**
 * Set Instruction
 *
 * @param {any} payload
 * @returns {any}
 */
export const setInstruction: ActionCreator<any> = (payload: any): any => ({
  type: cartActions.SET_INSTRUCTION,
  payload,
});

/**
 * Order Check action creator
 *
 * @param {ChangeCartStepPlayload} payload
 * @returns {ChangeCartStepAction}
 */
export const orderCheck: ActionCreator<SimpleActions> = (
  responseCode: number,
  orderId: string,
): SimpleActions => {
  const confiApi: ConfigCallApi = {
    url: ApiRoutes.ordersCheck,
    secure: true,
    callGlobalError: false,
    method: 'post',
    postData: {
      RESPONSE_CODE: responseCode,
      ORDER_ID: orderId,
    } as orderCheckPostData,
  };
  return {
    type: cartActions.ORDERSCHECK,
    payload: confiApi,
  };
};
