import React, { FunctionComponent, useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EventDetailsDispatchProps, EventDetailsStateProps } from './types';
import EventDetailsC from '../../components/pages/Event';
import {
  resetEventMessages as resetEventMessagesAC,
  resetEvent as resetEventAC,
  addToCart as addToCartAc,
  deleteSeat as deleteSeatAC,
  destroyCart as destroyCartAC,
} from '../../actions';
import {
  getEventLoading,
  getEventRefreshing,
  getEventError,
  getCart,
  getCartLoading,
} from '../../selectors';
import { AppState, EventPageParams } from '../../types';
import AppMeta from '../../components/common/AppMeta';

interface EventDetailsProps
  extends EventDetailsDispatchProps,
    EventDetailsStateProps {}

const EventDetails: FunctionComponent<EventDetailsProps> = ({
  addToCart,
  eventDetails,
  deleteSeat,
  cartHasPlan,
  destroyCart,
  cartLoading,
}: EventDetailsProps) => {
  const { event: eventParam } = useParams<EventPageParams>();

  useEffect(() => {
    return () => {
      // resetEvent();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventParam]);

  return (
    <>
      <AppMeta
        title={eventDetails.get('metaTitle')}
        description={eventDetails.get('metaDescription')}
        keywords={eventDetails.get('metaKeywords')}
      />
      <EventDetailsC
        event={eventDetails}
        addToCart={addToCart}
        deleteSeat={deleteSeat}
        cartHasPlan={cartHasPlan}
        destroyCart={destroyCart}
        cartLoading={cartLoading}
      />
    </>
  );
};

/**
* EventDetails map state to props
*
* @param {Dispatch} dispatch
@returns {EventDetailsDispatchProps}
*/
const mapDispatchToProps = (dispatch: Dispatch): EventDetailsDispatchProps => {
  return bindActionCreators(
    {
      resetEventMessages: resetEventMessagesAC,
      resetEvent: resetEventAC,
      addToCart: addToCartAc,
      deleteSeat: deleteSeatAC,
      destroyCart: destroyCartAC,
    },
    dispatch,
  );
};

/**
 * EventDetails map state to props
 *
 * @param {AppState} appState
 * @returns {EventDetailsStateProps}
 */
const mapStateToProps = (
  appState: AppState,
  ownProps,
): EventDetailsStateProps => {
  return {
    // event: getEventData(appState),
    loading: getEventLoading(appState),
    refreshing: getEventRefreshing(appState),
    error: getEventError(appState),
    eventDetails: ownProps.event,
    cartHasPlan: getCart(appState)?.get('hasPlan'),
    cartLoading: getCartLoading(appState),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventDetails);
