import { combineReducers, Reducer } from 'redux';
import {
  createSimpleFetchStore,
  createSimpleListingStore,
  GlobalResponseRecord,
  serializeGlobalResponse,
} from '../helpers';
import { OrderRecord, OrderResponseRecord } from '../models';
import { serializeOrdersListing, serializeOrderResponse } from '../providers';
import { orderActionKeys, UserState, userActionKeys } from '../types';
import myAddressesReducer from './addresses';

const userReducer: Reducer<UserState> = combineReducers<UserState>({
  profilUpdate: createSimpleFetchStore<GlobalResponseRecord>(
    userActionKeys.PROFIL_UPDATE,
    serializeGlobalResponse,
  ).reducer,
  myOrders: createSimpleListingStore<OrderRecord>(
    orderActionKeys.MY_ORDERS,
    serializeOrdersListing,
  ).reducer,
  orderDetailsState: createSimpleFetchStore<OrderResponseRecord>(
    orderActionKeys.DETAIL_ORDER,
    serializeOrderResponse,
  ).reducer,
  myAddresses: myAddressesReducer,
  newsletters: createSimpleFetchStore<GlobalResponseRecord>(
    userActionKeys.NEWSLETTER_SUBSCRIBE,
    serializeGlobalResponse,
  ).reducer,
});

export default userReducer;
