import React, { FunctionComponent } from 'react';
import { List } from 'immutable';
import StorePr from './StorePr';

import BlocHeader from './BlocHeader';
import { CampaignRecord } from '../models';

interface CampaignsGridprops {
  currentCampaigns?: List<CampaignRecord> | null;
  futureCampaigns?: List<CampaignRecord> | null;
}

const CampaignsGrid: FunctionComponent<CampaignsGridprops> = ({
  currentCampaigns,
  futureCampaigns,
}: CampaignsGridprops) => {
  return (
    <div>
      {!!currentCampaigns && (
        <div className="categories">
          <div className="container container-sm">
            <div className="categorie">
              <header className="section-header">
                <h2>Encore plus de nouveautés</h2>
                <h4>Les marques que vous avez pu manquer.</h4>
              </header>

              <div className="row">
                {currentCampaigns.map(camp => (
                  <div
                    className="col-sm-6"
                    key={`campaigns-item-${camp.get('id')}`}
                  >
                    <StorePr campaign={camp} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {!!futureCampaigns && (
        <section className="coming-soon">
          <div className="container container-sm">
            <BlocHeader
              title="Prochainement"
              subtitle="Un aperçu des prochaines ventes."
              iconType="W"
            />
            <div className="row">
              {futureCampaigns.map(camp => (
                <div
                  className="col-sm-6"
                  key={`campaigns-item-${camp.get('id')}`}
                >
                  <StorePr campaign={camp} />
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default CampaignsGrid;
