import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { formValueSelector } from 'redux-form';
import CartC from '../../components/pages/Cart';
import {
  cartSuccess as cartSuccessAc,
  checkout as checkoutAc,
  orderCheck as orderCheckAc,
  submitReduxForm,
  setInstruction as setInstructionAc,
} from '../../actions';
import {
  getCart,
  getCartLoading,
  getCartPaymentInfo,
  getCartInstruction,
  getCartSuccessMessage,
  getCartLoadingPage,
} from '../../selectors/cart';
import Message from '../../components/common/Message';
import LoadingPage from '../../components/common/LoadingPage';
import {
  getAuthLoading,
  getCartSelectedPaymentId,
  getIsConnected,
  getSelectedPaymentMehodById,
} from '../../selectors';

// interface CartProps
//   extends CartDispatchProps,
//     CartStateProps {}

const Cart: FunctionComponent<any> = ({
  cart,
  checkout,
  itemsField,
  payment_method_id,
  orderCheck,
  submitForm,
  paymentInfo,
  instruction,
  successMessage,
  cartSuccess,
  loading,
  loadingPage,
  isConnected,
  setInstruction,
  paymentMethod,
}: any) => {
  useEffect(() => {
    return () => {
      setInstruction(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <LoadingPage loading={loadingPage} />

      <Message
        success={
          successMessage
            ? {
                successMessage,
                resetSuccess: () => cartSuccess(null),
              }
            : null
        }
      />
      <CartC
        cart={cart}
        checkoutAc={checkout}
        itemsField={itemsField}
        payment_method_id={payment_method_id}
        orderCheck={orderCheck}
        submitForm={submitForm}
        paymentInfo={paymentInfo}
        instruction={instruction}
        loading={loading}
        loadingPage={loadingPage}
        isConnected={isConnected}
        paymentMethod={paymentMethod}
      />
    </>
  );
};

/**
* Cart map state to props
*
* @param {Dispatch} dispatch
@returns {EventsGridDispatchProps}
*/
const mapDispatchToProps = (dispatch: Dispatch): any => {
  return bindActionCreators(
    {
      checkout: checkoutAc,
      orderCheck: orderCheckAc,
      submitForm: submitReduxForm,
      cartSuccess: cartSuccessAc,
      setInstruction: setInstructionAc,
    },
    dispatch,
  );
};

/**
 * Cart map state to props
 *
 * @param {AppState} appState
 * @returns {EventsGridStateProps}
 */
const mapStateToProps = (appState: any): any => {
  const paymnetMethodId = getCartSelectedPaymentId(appState);
  const paymentMethod = getSelectedPaymentMehodById(paymnetMethodId)(appState);
  return {
    cart: getCart(appState),
    loading: getCartLoading(appState),
    loadingPage: getCartLoadingPage(appState),
    successMessage: getCartSuccessMessage(appState),
    itemsField: formValueSelector('CartForm')(appState, 'items'),
    payment_method_id: paymnetMethodId,
    paymentMethod,
    paymentInfo: getCartPaymentInfo(appState),
    instruction: getCartInstruction(appState),

    isConnected: getIsConnected(appState),
    authLoading: getAuthLoading(appState),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
