import React, { useState } from 'react';
import Icon from 'react-remixicon';
import { SignIn, SignUp, ForgotPass } from '../containers';
import LoadingPopup from './common/LoadingPopup';

interface AccountModalI {
  isAccountModal: boolean;
  closeAccountModal: () => void;
  loading?: boolean;
}

const AccountModal = ({
  isAccountModal,
  closeAccountModal,
  loading,
}: AccountModalI) => {
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  return (
    <div className={`account-modal modal ${isAccountModal}`}>
      <div className="back" />

      <div className="my-container">
        <div className="row modal-container">
          <LoadingPopup loading={loading} />

          {!loading && (
            <>
              {!isForgotPassword ? (
                <>
                  <button
                    className="close-btn"
                    onClick={() => {
                      setIsForgotPassword(false);
                      closeAccountModal();
                    }}
                  >
                    <Icon name="close" type="line" />
                  </button>

                  <span className="or">Ou</span>
                  <div className="col-md-6">
                    <SignIn setIsForgotPassword={setIsForgotPassword} />
                  </div>
                  <div className="col-md-6">
                    <SignUp />
                  </div>
                </>
              ) : (
                <div className="offset-md-3 col-md-6">
                  <button
                    className="close-btn"
                    onClick={() => {
                      setIsForgotPassword(false);
                    }}
                  >
                    <Icon name="close" type="line" />
                  </button>

                  <ForgotPass />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccountModal;
