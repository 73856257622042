import React, { FunctionComponent } from 'react';
import { List } from 'immutable';
import InfiniteScroll from 'react-infinite-scroller';
import { ProductRecord } from '../models';
import Product from './Product';
import { PaginationRecord } from '../helpers';

interface ProductsGridProps {
  products?: List<ProductRecord> | null;
  onLoadMore: () => void;
  infinitLoading?: boolean;
  pagination?: PaginationRecord | null;
  loading?: boolean;
}

const ProductsGrid: FunctionComponent<ProductsGridProps> = ({
  products,
  onLoadMore,
  infinitLoading,
  pagination,
  loading,
}: ProductsGridProps) => {
  if (!products || products.size < 0) return null;

  return (
    <div className="col-md-8">
      <div className="mb-4 row">
        <div className="col-8" />
        {/* <div className="col-4">
          <SelectBox
            items={[
              { value: 'per', label: 'Trier par pertinence' },
              {
                value: 'asc',
                label: 'Trier par prix croissant',
              },
              {
                value: 'desc',
                label: 'Trier par prix décroissant',
              },
            ]}
          />
        </div> */}
      </div>

      <InfiniteScroll
        pageStart={0}
        loadMore={() => {
          if (!infinitLoading && !loading) {
            onLoadMore();
          }
        }}
        hasMore={
          !infinitLoading &&
          !loading &&
          pagination &&
          pagination.get('nextPage') &&
          pagination.get('nextPage') !== -1
        }
      >
        <div className="row">
          {products.map(product => (
            <div
              className="mb-4 col-md-6"
              key={`products-item-${product.get('id')}`}
            >
              <Product product={product} />
            </div>
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default ProductsGrid;
