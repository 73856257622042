/* eslint-disable quotes */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { EventRecord } from '../models';
import { formatPrice, getCurrentCurrency } from '../helpers';
import CountDown from './CountDown';

interface CardProps {
  event: EventRecord;
}

const Card = ({ event }: CardProps) => {
  const category = event.get('category');
  const place = event.get('place');

  return (
    <div className="g-card">
      <div className="img-container">
        {event.get('sticker') && (
          <span className="g-card-status">{event.get('sticker')}</span>
        )}
        <Link to={`/${event.get('slug')}`}>
          <img src={event.get('cover')} alt={event.get('title')} />
        </Link>
      </div>
      <div className="g-card-desc">
        {!!category && (
          <div className="mb-3">
            <span className="g-category">{category.get('title')}</span>
          </div>
        )}
        <h3 className="g-title">
          <Link to={`/${event.get('slug')}`}>{event.get('title')}</Link>
        </h3>
        <p className="g-address">
          <strong>{place?.address?.city}</strong>, {place?.address?.address}
        </p>

        <CountDown
          date={moment(event.get('expiredAt'), 'DD/MM/YYYY HH:mm:ss').format(
            'YYYY-MM-DD HH:mm:ss',
          )}
          isSmall
          handleSoldOut={() => {
            //
          }}
        />

        <div className="d-flex justify-content-between">
          <div className="g-price">
            <label>À partir de :</label>
            <p>
              {formatPrice(event.get('price'), true)}
              <span>{getCurrentCurrency()}</span>
            </p>
          </div>
          <Link
            to={`/${event.get('slug')}`}
            className={!event.get('soldOut') ? 'g-btn-orange' : 'g-btn-grey'}
          >
            {!event.get('soldOut') ? "J'achète" : 'Guichet Fermé'}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Card;
