import { SearchsListing, Search } from '../../types';
import { SearchRecord, SearchsListRecord } from '../../models';
import { serializeList, PaginationRecord } from '../../helpers';

/**
 * Serialize search
 *
 * @param {Search} { category, ...rest }
 * @returns {SearchRecord}
 */
export const serializeSearch = ({
  id,
  title,
  image,
  url,
  ...rest
}: Search): SearchRecord => {
  const newSearch = new SearchRecord({
    id,
    title,
    image,
    url,
    ...rest,
  });
  return newSearch;
};

/**
 * Serialize searchs listing
 *
 * @param {SearchsListing} {
 *   searchs,
 *   pagination,
 *   ...rest
 * }
 * @returns {SearchsListRecord}
 */
export const serializeSearchsListing = ({
  result,
  pagination,
  ...rest
}: SearchsListing): SearchsListRecord => {
  const newSearchListing = new SearchsListRecord({
    list: result ? serializeList(result, serializeSearch) : null,
    pagination: pagination ? new PaginationRecord(pagination) : null,
    ...rest,
  });

  return newSearchListing;
};
