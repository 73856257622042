import React, { FunctionComponent } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { EventsGrid, PastEventsGrid } from '../../containers';
import { CategoryRecord } from '../../models';
import BannerSlider from '../BannerSlider';
import AppMeta from '../common/AppMeta';

export interface CategoryPageProps {
  category?: CategoryRecord;
}

const Sport: FunctionComponent<CategoryPageProps> = ({
  category,
}: CategoryPageProps) => {
  return (
    <div>
      <AppMeta
        title={category.get('metaTitle')}
        description={category.get('metaDescription')}
        keywords={category.get('metaKeywords')}
      />

      <BannerSlider items={category.get('sliderItems')} />
      {!!category && <EventsGrid categoryId={category.get('id')} />}
      {!!category && <PastEventsGrid categoryId={category.get('id')} />}
    </div>
  );
};

export default Sport;
