/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FunctionComponent } from 'react';
import { AnyFielProps } from '../../../types';

interface SelectFieldProps extends AnyFielProps {
  data: any[];
  valueSameAsText?: boolean;
}

const SelectField: FunctionComponent = ({
  input: { onChange, onBlur, onFocus, value },
  meta: { error, touched, valid },
  label,
  isRequired,
  className,
  data,
  valueSameAsText = false,
  otherOnChange,
  ...rest
}: SelectFieldProps) => {
  return (
    <div className={className || 'col-4 mt-2'}>
      <select
        className="i-select"
        value={value}
        onChange={e => {
          if (otherOnChange) otherOnChange();
          onChange(e.target.value);
        }}
        onBlur={onBlur}
        onFocus={onFocus}
        {...rest}
      >
        <option value="">
          {label} {!!isRequired && <span>*</span>}
        </option>
        {!valueSameAsText &&
          data?.map((dataValue, index) => {
            return (
              <option key={index} value={JSON.stringify(dataValue)}>
                {dataValue.text}
              </option>
            );
          })}
        {valueSameAsText &&
          data?.map((dataValue, index) => {
            return (
              <option key={index} value={dataValue}>
                {dataValue}
              </option>
            );
          })}
      </select>

      {!valid && touched && !!error && (
        <p className="text-danger mt-1">{error}</p>
      )}
    </div>
  );
};

export default SelectField;
