import React, { FunctionComponent, useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ProductsGridDispatchProps, ProductsGridStateProps } from './types';
import { AppState } from '../../types';
import {
  fetchProducts as fetchProductsAC,
  resetProductsMessages as resetProductsMessagesAC,
  resetProducts as resetProductsAC,
} from '../../actions';
import {
  getProductsList,
  getProductsLoading,
  getProductsError,
  getProductsRefreshing,
  getProductsInfinitLoading,
  getProductsPagination,
} from '../../selectors';
import ProductsGridC from '../../components/ProductsGrid';

interface ProductsGridProps
  extends ProductsGridDispatchProps,
    ProductsGridStateProps {
  termId?: number;
  campaignId?: number;
}

const ProductsGrid: FunctionComponent<ProductsGridProps> = ({
  fetchProducts,
  resetProducts,
  products,
  infinitLoading,

  termId,
  campaignId,
  pagination,
  loading,
}: ProductsGridProps) => {
  useEffect(() => {
    fetchProducts(termId, campaignId);
    return () => {
      resetProducts();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [termId, campaignId]);

  return (
    <ProductsGridC
      products={products}
      onLoadMore={() => {
        fetchProducts(termId, campaignId, pagination.get('nextPage'), true);
      }}
      pagination={pagination}
      infinitLoading={infinitLoading}
      loading={loading}
    />
  );
};

/**
* ProductsGrid map state to props
*
* @param {Dispatch} dispatch
@returns {ProductsGridDispatchProps}
*/
const mapDispatchToProps = (dispatch: Dispatch): ProductsGridDispatchProps => {
  return bindActionCreators(
    {
      fetchProducts: fetchProductsAC,
      resetProductsMessages: resetProductsMessagesAC,
      resetProducts: resetProductsAC,
    },
    dispatch,
  );
};

/**
 * ProductsGrid map state to props
 *
 * @param {AppState} appState
 * @returns {ProductsGridStateProps}
 */
const mapStateToProps = (appState: AppState): ProductsGridStateProps => {
  return {
    products: getProductsList(appState),
    loading: getProductsLoading(appState),
    error: getProductsError(appState),
    refreshing: getProductsRefreshing(appState),
    infinitLoading: getProductsInfinitLoading(appState),
    pagination: getProductsPagination(appState),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsGrid);
