import { Record } from 'immutable';
import { Pagination, GlobalResponse } from './types';

/**
 * Pagination record
 *
 * @export
 * @class PaginationRecord
 * @extends {Record<Pagination>()}
 */
export class PaginationRecord extends Record<Pagination>(
  {
    total: 0,
    nextPage: null,
    perPage: null,
    currentPage: null,
  },
  'pagination',
) {}

/**
 * Global response record
 *
 * @export
 * @class GlobalResponseRecord
 * @extends {Record<GlobalResponse>()}
 */
export class GlobalResponseRecord extends Record<GlobalResponse>(
  {
    success: false,
    message: undefined,
  },
  'global-response',
) {}
