/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FunctionComponent } from 'react';
import { AnyFielProps } from '../../../types';

interface InputCheckboxFieldProps extends AnyFielProps {
  data: any;
  acceptTerms?: boolean;
  wrapAllClass?: string;
}

const InputCheckboxField: FunctionComponent = ({
  input: { onChange, value },
  meta: { error, touched, valid },
  label,
  isRequired,
  className,
  data,
  acceptTerms = false,
  wrapAllClass = '',
  ...rest
}: InputCheckboxFieldProps) => {
  const isValueExist = (comparableValue: string | number): boolean =>
    value
      ? value?.findIndex(currentValue => comparableValue === currentValue) !==
        -1
      : false;

  return (
    <div className={className || ''}>
      {label && (
        <label>
          {label} {!!isRequired && <span>*</span>}
        </label>
      )}
      <div className={`${wrapAllClass}`}>
        {!acceptTerms &&
          data.map((dataValue, index) => (
            <label
              key={`checkbox-${dataValue.value}`}
              className="radio-container mr-3"
            >
              {dataValue.label}
              <input
                type="checkbox"
                id={`checkbox${index}`}
                value={dataValue.value}
                onChange={e => {
                  const newValue = e.target.value;
                  if (isValueExist(newValue)) {
                    onChange(
                      value.filter(currentValue => currentValue !== newValue),
                    );
                  } else {
                    onChange([...value, newValue]);
                  }
                }}
                {...rest}
              />
              <span className="checkmark" />
            </label>
          ))}

        {acceptTerms && (
          <label
            key={`checkbox-${data.value}`}
            className="radio-container mr-3"
          >
            J'ai lu{' '}
            <strong>
              <a target="_blank" href="/mentions-legales">
                les conditions générales de vente
              </a>
            </strong>{' '}
            et j'y adhère sans réserve.
            <input
              type="checkbox"
              id={`checkbox${data.value}`}
              value={data.value}
              onChange={e => {
                const newValue = e.target.checked;
                onChange(newValue);
              }}
              {...rest}
            />
            <span className="checkmark" />
          </label>
        )}
      </div>
      {!valid && touched && !!error && (
        <p className="text-danger mt-1">{error}</p>
      )}
    </div>
  );
};

export default InputCheckboxField;
