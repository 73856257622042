import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { ProviderEventsGrid, ProviderPastEventsGrid } from '../../containers';
import AppMeta from '../common/AppMeta';
import { EventProviderRecord } from '../../models';
import { AppState } from '../../types';
import { getProvider } from '../../selectors';

export interface ProviderPageProps {
  provider?: EventProviderRecord | null;
}

const Provider: FunctionComponent<ProviderPageProps> = ({
  provider,
}: ProviderPageProps) => {
  const params = useParams<any>();
  return (
    <div className="provider">
      <AppMeta
        title={provider?.get('title')}
        description={provider?.get('description')}
      />
      <header className="section-header">
        <img src={provider?.get('logo')} alt={provider?.get('title')} />
        <h2>{provider?.get('title')}</h2>
        {!!provider?.get('description') && (
          <h4>{provider?.get('description')}</h4>
        )}
      </header>
      <ProviderEventsGrid providerSlug={params?.slug} />
      <ProviderPastEventsGrid providerSlug={params?.slug} />
    </div>
  );
};

const mapStateToProps = (appState: AppState): ProviderPageProps => {
  return {
    provider: getProvider(appState),
  };
};

export default connect(mapStateToProps)(Provider);
