import React from 'react';
import { connect } from 'react-redux';
import Icon from 'react-remixicon';
import { Link } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import {
  resetNewslettersMessages as resetNewslettersMessagesAc,
  subscribeToNewsletter as subscribeToNewsletterAc,
} from '../../actions';
import logo from '../../images/logo.png';
import logosPayment from '../../images/payment-logos.png';
import {
  getEventCategoriesList,
  getNewslettersError,
  getNewslettersLoading,
  getNewslettersSuccessMessage,
  getThemesList,
} from '../../selectors';
import { NewsletterForm } from '../forms';
import LoadingPopup from './LoadingPopup';
import Message from './Message';
import { FAKE_CATEGOIRES, FAKE_THEMES } from '../../helpers';
import CountryDropdown from '../CountryDropdown';

const Footer = ({
  categories,
  themes,
  subscribeToNewsletter,
  newsletterError,
  newsletterSuccess,
  newsletterLoading,

  resetNewslettersMessages,
}: any) => {
  return (
    <>
      <Message
        success={
          newsletterSuccess
            ? {
                successMessage: newsletterSuccess,
                resetSuccess: () => resetNewslettersMessages(),
              }
            : null
        }
        error={
          newsletterError
            ? {
                errorMessage: newsletterError.error
                  ? newsletterError.error
                  : newsletterError.data.message,
                resetError: () => resetNewslettersMessages(),
              }
            : null
        }
      />

      <footer className="main-footer">
        <div className="top-footer">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-4">
                <div className="footer-left">
                  <div className="footer-logo">
                    <img src={logo} alt="Guichet" />
                  </div>
                  <p>
                    Un site de billetterie nouvelle génération vous offrant la
                    possibilité d’acheter en ligne des tickets pour les
                    événements de votre choix. Sa vocation est de vous proposer
                    l’offre de concerts, sports, spectacles, cinéma, festivals,
                    théâtre, humour, voyages et evasions la plus large du Maroc
                    en collaboration avec les organisateurs d’événements.
                  </p>
                  <h3 className="footer-title">Nos applications</h3>

                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-6 col-xs-6">
                      <a
                        className="btn-app orange"
                        href="https://play.google.com/store/apps/details?id=com.guichet.app&hl=fr&gl=US"
                      >
                        Guichet Android
                      </a>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-6 col-xs-6">
                      <a
                        className="btn-app orange"
                        href="https://apps.apple.com/fr/app/guichet/id1497007435"
                      >
                        Guichet iOS
                      </a>
                    </div>
                  </div>

                  {/* <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-6 col-xs-6">
                      <a className="btn-app purple" href="/">
                        Guichet Store Android
                      </a>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-6 col-xs-6">
                      <a className="btn-app purple" href="/">
                        Guichet Store iOS
                      </a>
                    </div>
                  </div> */}
                </div>
                <div className="pt-1 pb-4 col-sm-12 pl-0 pr-0">
                  <CountryDropdown />
                </div>
              </div>
              <div className="col-md-6">
                <div className="row footer-menu-bloc">
                  <div className="col-sm-4">
                    <div className="footer-menu">
                      <h3 className="footer-title">Guichet</h3>
                      <ul>
                        {FAKE_CATEGOIRES?.map(category => (
                          <li
                            className="main-menu--item"
                            key={`categories-item-${category.id}`}
                          >
                            <Link to={`/${category.slug}`} replace>
                              {category.title}
                            </Link>
                          </li>
                        ))}
                        {/* {categories?.map(category => (
                          <li
                            className="main-menu--item"
                            key={`categories-item-${category.get('id')}`}
                          >
                            <Link to={`/${category.get('slug')}`} replace>
                              {category.get('title')}
                            </Link>
                          </li>
                        ))} */}
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="footer-menu">
                      <h3 className="footer-title">Gstore</h3>
                      <ul>
                        {FAKE_THEMES?.map(theme => (
                          <li
                            className="main-menu--item"
                            key={`themes-item-${theme.id}`}
                          >
                            <Link to={`/store/${theme.slug}`} replace>
                              {theme.title}
                            </Link>
                          </li>
                        ))}
                        {/* {themes?.map(theme => (
                          <li
                            className="main-menu--item"
                            key={`themes-item-${theme.get('id')}`}
                          >
                            <Link to={`/store/${theme.get('slug')}`} replace>
                              {theme.get('title')}
                            </Link>
                          </li>
                        ))} */}
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="footer-menu">
                      <h3 className="footer-title">à propos</h3>
                      <ul>
                        <li>
                          <Link to="/qui-sommes-nous">Qui sommes nous ?</Link>
                        </li>
                        <li>
                          <Link to="/contact">Contactez-nous</Link>
                        </li>
                        <li>
                          <Link to="/magazine">Guichet Magazine</Link>
                        </li>
                        <li>
                          <Link to="/mentions-legales">Mentions légales</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="position-relative">
                  <LoadingPopup loading={newsletterLoading} />
                  <NewsletterForm onSubmit={subscribeToNewsletter} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-footer">
          <div className="d-flex align-items-center justify-content-around">
            <p>Copyright 2021 © Guichet.ma - Tous droits réservés.</p>
            <div className="payment-methods">
              <img src={logosPayment} alt="paiments" />
            </div>
            <div className="sm-links d-flex">
              <a
                href="https://www.facebook.com/guichetCom/"
                className="facebook"
                target="_blank"
                rel="noreferrer"
              >
                <Icon name="facebook" type="fill" />
              </a>
              {/* <a
                href="https://plus.google.com/u/0/116600951703676552113"
                className="google"
                target="_blank"
                rel="noreferrer"
              >
                <Icon name="google" type="fill" />
              </a> */}
              <a
                href="https://www.instagram.com/guichet.ma/"
                className="instagram"
                target="_blank"
                rel="noreferrer"
              >
                <Icon name="instagram" type="line" />
              </a>
              <a
                href="https://www.linkedin.com/company/guichet-ma/"
                className="linkedin"
                target="_blank"
                rel="noreferrer"
              >
                <Icon name="linkedin" type="fill" />
              </a>
              <a
                href="https://twitter.com/guichetcom"
                className="twitter"
                target="_blank"
                rel="noreferrer"
              >
                <Icon name="twitter" type="line" />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

/**
 * Footer map state to prop
 *
 * @param {Dispatch} dispatch
 */
const mapDispatchToProps = (dispatch: Dispatch): any => {
  return bindActionCreators(
    {
      subscribeToNewsletter: subscribeToNewsletterAc,
      resetNewslettersMessages: resetNewslettersMessagesAc,
    },
    dispatch,
  );
};

/**
 * Footer map state to props
 *
 * @param {AppState} appState
 */
const mapStateToProps = (appState: any): any => {
  return {
    categories: getEventCategoriesList(appState),
    themes: getThemesList(appState),
    newsletterError: getNewslettersError(appState),
    newsletterSuccess: getNewslettersSuccessMessage(appState),
    newsletterLoading: getNewslettersLoading(appState),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
