import { reduxForm, FormErrors } from 'redux-form';
import FormView from './recover-password-view';
import { formsNames, fieldErrors } from '../constants';
import { SignUpFormData, SignUpFormProps } from '../types';
import { regularExpresions } from '../../../helpers';

export const validate = ({
  email,
  password,
  confirmPassword,
}: SignUpFormData): FormErrors<SignUpFormData> => {
  const errors: FormErrors<SignUpFormData> = {};
  if (!email || email.trim() === '') {
    errors.email = fieldErrors.REQUIRED;
  } else if (!regularExpresions.EMAIL.test(email)) {
    errors.email = fieldErrors.EMAIL;
  }
  if (!password || password.trim() === '') {
    errors.password = fieldErrors.REQUIRED;
  } else if (password.length < 8) {
    errors.password = fieldErrors.PASSWORD;
  }

  if (!confirmPassword || confirmPassword.trim() === '') {
    errors.confirmPassword = fieldErrors.REQUIRED;
  } else if (confirmPassword.length < 8) {
    errors.confirmPassword = fieldErrors.PASSWORD;
  } else if (password !== confirmPassword) {
    errors.confirmPassword = fieldErrors.PASSWORD_CONFIRM;
  }

  return errors;
};

const RecoverPassForm = reduxForm<SignUpFormData, SignUpFormProps>({
  form: formsNames.RECOVER_PASS,
  validate,
})(FormView);

export default RecoverPassForm;
