import React, { FunctionComponent } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { MenuDispatchProps, MenuStateProps } from './types';
import MenuC from '../../components/common/Menu';
import { AppState } from '../../types';
import {
  getIsConnected,
  getUser,
  getConnectionModalIsVisible,
  getSignInLoading,
  getSignUpLoading,
  getSocialSignInLoading,
} from '../../selectors';
import {
  signOut as signOutAC,
  toggleModalConnection as toggleModalConnectionAC,
} from '../../actions';

interface MenuProps extends MenuDispatchProps, MenuStateProps {}

const Menu: FunctionComponent<MenuProps> = ({
  isConnected,
  // user,
  connectionModalIsVisible,
  toggleModalConnection,
  signOut,
  loadingSignIn,
  loadingSignUp,
  loadingSocialSignIn,
}: MenuProps) => {
  return (
    <>
      <MenuC
        isConnected={isConnected}
        // user={user}
        toggleModalConnection={toggleModalConnection}
        connectionModalIsVisible={connectionModalIsVisible}
        onSignOutPressed={signOut}
        loadingAuth={loadingSignIn || loadingSignUp || loadingSocialSignIn}
      />
    </>
  );
};

/**
* Menu map state to props
*
* @param {Dispatch} dispatch
@returns {MenuDispatchProps}
*/
const mapDispatchToProps = (dispatch: Dispatch): MenuDispatchProps => {
  return bindActionCreators(
    {
      signOut: signOutAC,
      toggleModalConnection: toggleModalConnectionAC,
    },
    dispatch,
  );
};

/**
 * Menu map state to props
 *
 * @param {AppState} appState
 * @returns {MenuStateProps}
 */
const mapStateToProps = (appState: AppState): MenuStateProps => {
  return {
    isConnected: getIsConnected(appState),
    user: getUser(appState),
    connectionModalIsVisible: getConnectionModalIsVisible(appState),

    loadingSignIn: getSignInLoading(appState),
    loadingSignUp: getSignUpLoading(appState),
    loadingSocialSignIn: getSocialSignInLoading(appState),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
