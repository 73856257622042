/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/control-has-associated-label */
import moment from 'moment';
import React, { FunctionComponent, useEffect } from 'react';
import Icon from 'react-remixicon';
import { Link } from 'react-router-dom';
import ShareBlock from './common/ShareBlock';

interface MagazineDetailsCProps {
  article?: any;
  fetchTopics: () => void;
}

const MagazineDetailsC: FunctionComponent<MagazineDetailsCProps> = ({
  article,
  fetchTopics,
}: MagazineDetailsCProps) => {
  useEffect(() => {
    fetchTopics();
  }, [fetchTopics]);

  if (!article) return null;
  return (
    <section className="magazine-page article-detail">
      <div className="article-img">
        <img src={article.image} alt={`article-img-${article.id}`} />
      </div>
      <div className="article-info">
        <div className="container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/magazine">Tous</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {article.topic.title}
              </li>
            </ol>
          </nav>
          <h1>{article.title}</h1>
          <span>
            <Icon name="calendar" type="line" />
            {moment(article.createdAt, 'DD/MM/YYYY HH:mm:ss').format(
              'YYYY/MM/DD - HH:mm',
            )}
          </span>

          <div
            className="article-content"
            dangerouslySetInnerHTML={{ __html: article.content }}
          />

          <hr className="mt-5" />
        </div>

        <div className="mt-4">
          <ShareBlock title={article.title} blockTitle="Partagez cet article" />
        </div>
      </div>
    </section>
  );
};

export default MagazineDetailsC;
