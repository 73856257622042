import { List } from 'immutable';
import { createSelector } from 'reselect';
import { PaginationRecord, SimpleListingStateRecord } from '../helpers';
import { ArticleRecord, TopicRecord } from '../models';
import { AppState, ArticleState } from '../types';

export const getMagazineState = (state: AppState): ArticleState =>
  state.magazine;

//* *********************  START MAGAZINE STATE  ****************** */
export const getArticlesState = createSelector<
  AppState,
  any,
  SimpleListingStateRecord<ArticleRecord>
>([getMagazineState], (magazine: ArticleState) => magazine.articlesState);

export const getArticles = createSelector<
  AppState,
  any,
  List<ArticleRecord> | undefined | null
>(
  [getArticlesState],
  (articlesState: SimpleListingStateRecord<ArticleRecord>) =>
    articlesState.get('list'),
);

export const getArticlesLoading = createSelector<
  AppState,
  any,
  boolean | undefined | null
>(
  [getArticlesState],
  (articlesState: SimpleListingStateRecord<ArticleRecord>) =>
    articlesState.get('loading'),
);

export const getArticlesInfinitLoading = createSelector<
  AppState,
  any,
  boolean | undefined | null
>(
  [getArticlesState],
  (articlesState: SimpleListingStateRecord<ArticleRecord>) =>
    articlesState.get('infinitLoading'),
);

export const getArticlesPagination = createSelector<
  AppState,
  any,
  PaginationRecord | undefined | null
>(
  [getArticlesState],
  (articlesState: SimpleListingStateRecord<ArticleRecord>) =>
    articlesState.get('pagination'),
);

/// ///// topics

export const getTopicsState = createSelector<
  AppState,
  any,
  SimpleListingStateRecord<TopicRecord>
>([getMagazineState], (magazine: ArticleState) => magazine.topicsState);

export const getTopics = createSelector<
  AppState,
  any,
  List<TopicRecord> | undefined | null
>([getTopicsState], (topicsState: SimpleListingStateRecord<TopicRecord>) =>
  topicsState.get('list'),
);

// export const getArticleDetailsState = createSelector<
//   AppState,
//   any,
//   ArticleRecord | undefined | null
// >([getMagazineState], (magazine: ArticleState) => magazine.articleDetailsState);
//* *********************  END MAGAZINE STATE  ****************** */
