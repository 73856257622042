import React, { FunctionComponent } from 'react';
import { MyOrders } from '../../containers';
import AppMeta from '../common/AppMeta';

interface MyOrdersPageProps {}

const MyOrdersPage: FunctionComponent<MyOrdersPageProps> = () => {
  return (
    <>
      <AppMeta title="Mes commandes" prefix="" />
      <MyOrders />
    </>
  );
};

export default MyOrdersPage;
