import { Record } from 'immutable';
import { GlobalResponse } from '../helpers';
import { User } from '../types';
import { OrderRecord } from './order';

/**
 * User record
 *
 * @export
 * @class UserRecord
 * @extends {Record<User>()}
 */
export class UserRecord extends Record<User>(
  {
    id: 0,
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
  },
  'user',
) {}

/// ///////////////// Order
export class OrderResponseRecord extends Record<OrderResponseForRec>(
  {
    success: false,
    order: null,
  },
  'order-response',
) {}

export interface OrderResponseForRec extends GlobalResponse {
  order?: OrderRecord | null;
}
