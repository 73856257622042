import { Record } from 'immutable';
import { SliderResponseForRec, SliderForRec } from '../types';

/**
 * Slider record
 *
 * @class SliderRecord
 * @extends {Record<Slider>('slider')}
 */
export class SliderRecord extends Record<SliderForRec>(
  {
    id: 0,
    title: null,
    type: null,
    event: null,
    campaign: null,
    product: null,
  },
  'slider',
) {}

/**
 * Slider data response record
 *
 * @export
 * @class SliderResponseRecord
 * @extends {Record<SliderResponseForRec>()}
 */
export class SliderResponseRecord extends Record<SliderResponseForRec>(
  {
    slides: null,
    success: false,
  },
  'sliderData-response',
) {}
