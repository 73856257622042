/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { FunctionComponent, useEffect } from 'react';
import Icon from 'react-remixicon';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';
import { ArticleRecord } from '../models';
import { PaginationRecord } from '../helpers';

interface MagazineGridCProps {
  fetchArticles: (
    topicId: number,
    page?: number,
    loadMore?: boolean,
    refresh?: boolean,
  ) => void;
  fetchTopics: () => void;
  articles: any;
  currentTopic: any;

  onLoadMore: (topicId: number) => void;
  infinitLoading?: boolean;
  pagination?: PaginationRecord | null;

  loading?: boolean;
}

const MagazineGridC: FunctionComponent<MagazineGridCProps> = ({
  fetchArticles,
  fetchTopics,
  articles,
  currentTopic,

  onLoadMore,
  infinitLoading,
  pagination,

  loading,
}: MagazineGridCProps) => {
  useEffect(() => {
    if (currentTopic) {
      fetchArticles(currentTopic.id);
    } else {
      fetchArticles(null);
    }
  }, [currentTopic, fetchArticles]);

  useEffect(() => {
    fetchTopics();
  }, []);

  const getFeaturedArticle = (): ArticleRecord => {
    if (!articles) return null;
    let article = null;
    articles.map((a, index) => {
      if (index === 0) article = a;
      return null;
    });
    return article;
  };

  const getFormatedDate = (date: string) => {
    return moment(date, 'DD/MM/YYYY HH:mm:ss').format('YYYY/MM/DD - HH:mm');
  };

  if (loading) return null;

  return (
    <section className="magazine-page">
      <div className="container">
        {getFeaturedArticle() && (
          <Link
            to={`/${getFeaturedArticle().slug}`}
            className="featured-article article-item"
          >
            <div className="row">
              <div className="col-md-6">
                <div className="article-img">
                  <img
                    src={getFeaturedArticle().image}
                    alt={`article-img-${getFeaturedArticle().id}`}
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="topic">
                  <span className="article-topic">
                    {getFeaturedArticle().topic.title}
                  </span>
                </div>
                <h4 className="article-title">{getFeaturedArticle().title}</h4>
                <span className="article-date">
                  <Icon name="calendar" type="line" />
                  {getFormatedDate(getFeaturedArticle().createdAt)}
                </span>

                <div className="mt-3">
                  <p className="article-content">
                    {getFeaturedArticle()
                      .content.replace(/(<([^>]+)>)/gi, '')
                      .replace(/\&nbsp;/g, ' ')}
                  </p>
                </div>

                <Link
                  className="read-more"
                  to={`/${getFeaturedArticle().slug}`}
                >
                  Lire la suite
                  <Icon name="arrow-right-s" type="line" />
                </Link>
              </div>
            </div>
          </Link>
        )}

        <div className="articles">
          <InfiniteScroll
            pageStart={0}
            loadMore={() => {
              if (!infinitLoading && !loading) {
                onLoadMore(currentTopic ? currentTopic.id : null);
              }
            }}
            hasMore={
              !infinitLoading &&
              !loading &&
              pagination &&
              pagination.get('nextPage') &&
              pagination.get('nextPage') !== -1
            }
          >
            <div className="row">
              {articles?.map((article, index) => {
                if (index === 0) return null;
                return (
                  <div key={article.id} className="col-lg-4 col-sm-6">
                    <Link to={`/${article.slug}`} className="article-item">
                      <div className="article-img">
                        <span className="article-topic">
                          {article.topic.title}
                        </span>
                        <img
                          src={article.image}
                          alt={`article-img-${article.id}`}
                          className="img-fluid"
                        />
                      </div>
                      <div className="article-info">
                        <h4 className="article-title">{article.title}</h4>
                        <span className="article-date">
                          {getFormatedDate(article.createdAt)}
                        </span>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </section>
  );
};

export default MagazineGridC;
