/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FunctionComponent } from 'react';
import { AnyFielProps } from '../../../types';

interface InputTextareaFieldProps extends AnyFielProps {}

const InputTextareaField: FunctionComponent = ({
  input: { onChange, onFocus, onBlur, value },
  meta: { error, touched, valid },
  label,
  id,
  isRequired,
  className,
  ...rest
}: InputTextareaFieldProps) => {
  return (
    <div className={className || 'col-12 mt-2'}>
      {label && (
        <label htmlFor={id}>
          {label} {!!isRequired && <span>*</span>}
        </label>
      )}
      <textarea
        placeholder={label}
        id={id}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        {...rest}
      />
      {!valid && touched && !!error && (
        <p className="text-danger mt-1">{error}</p>
      )}
    </div>
  );
};

export default InputTextareaField;
