import React, { FunctionComponent } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../types';
import { SignInDispatchProps, SignInStateProps } from './types';
import SignInC from '../../components/pages/Login';
import {
  signIn as signInAC,
  resetSignInMessages as resetSignInMessagesAC,
  resetSignIn as resetSignInAC,
  resetSocialSignInMessages as resetSocialSignInMessagesAc,
} from '../../actions';
import {
  getSignInError,
  getSignInLoading,
  getSocialSignInLoading,
} from '../../selectors/authentification';
import Message from '../../components/common/Message';

interface SignInProps extends SignInDispatchProps, SignInStateProps {}

const SignIn: FunctionComponent<SignInProps> = ({
  // loading,
  signIn,
  error,
  resetSignInMessages,

  errorSocial,
  resetSocialMessages,

  setIsForgotPassword,
}: SignInProps) => {
  return (
    <>
      {/* Social Sign Error */}
      <Message
        error={
          errorSocial
            ? {
                errorMessage: errorSocial.error,
                resetError: () => resetSocialMessages(),
              }
            : null
        }
      />

      {/* Sign-in Error */}
      <Message
        error={
          error
            ? {
                errorMessage: error.error,
                resetError: () => resetSignInMessages(),
              }
            : null
        }
      />
      <SignInC onSubmit={signIn} setIsForgotPassword={setIsForgotPassword} />
    </>
  );
};

/**
* SignIn map state to props
*
* @param {Dispatch} dispatch
@returns {SignInDispatchProps}
*/
const mapDispatchToProps = (dispatch: Dispatch): SignInDispatchProps => {
  return bindActionCreators(
    {
      signIn: signInAC,
      resetSignInMessages: resetSignInMessagesAC,
      resetSignIn: resetSignInAC,

      resetSocialMessages: resetSocialSignInMessagesAc,
    },
    dispatch,
  );
};

/**
 * SignIn map state to props
 *
 * @param {AppState} appState
 * @returns {SignInStateProps}
 */
const mapStateToProps = (
  appState: AppState,
  { setIsForgotPassword },
): SignInStateProps => {
  return {
    error: getSignInError(appState),
    loading: getSignInLoading(appState),

    errorSocial: getSocialSignInLoading(appState),
    setIsForgotPassword,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
