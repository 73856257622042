export const formsNames = {
  SIGN_IN: 'SIGN_IN',
  FORGOT_PASS: 'FORGOT_PASS',
  RECOVER_PASS: 'RECOVER_PASS',
  SIGN_UP: 'SIGN_UP',
  PROFIL_UPDATE: 'PROFIL_UPDATE',
  CONTACT: 'CONTACT',
  NEWSLETTER: 'NEWSLETTER',
};

export const fieldErrors = {
  REQUIRED: 'Ce champ est obligatoire.',
  FULL_NAME: 'Veuillez fournir un nom et prénom valide.',
  CODE: 'Le code doit avoir 4 chiffres.',
  EMAIL: 'Veuillez fournir une adresse électronique valide.',
  PASSWORD: 'Le mot de passe doit avoir au moins 8 caractères.',
  PASSWORD_CONFIRM:
    'Les champs password confirmation et Mot de passe doivent être identiques.',
  PHONE: 'Numéro téléphone est non valide.',
};
