import { Record } from 'immutable';
import { CarrierItem } from '../types';

/**
 * Carrier item record
 *
 * @export
 * @class CarrierItemRecord
 * @extends {Record<CarrierItem>()}
 */
export class CarrierItemRecord extends Record<CarrierItem>(
  {
    id: 0,
    title: null,
    description: null,
    delay: null,
    logo: null,
    price: null,
  },
  'carrier-item',
) {}
