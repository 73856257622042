export const addressActionKeys = {
  MY_ADDRESSES: 'MY_ADDRESSES',

  ADD_ADDRESS: 'ADD_ADDRESS',
  SET_ADDRESS: 'SET_ADDRESS',
  SET_ADDRESSES: 'SET_ADDRESSES',
  GET_ADDRESSES: 'GET_ADDRESSES',
  ADDRESS_DELETED: 'ADDRESS_DELETED',
  REMOVE_ADDRESSES: 'REMOVE_ADDRESSES',
  ADDRESS_UPDATED: 'ADDRESS_UPDATED',
  PUT_ADDRESSES: 'PUT_ADDRESSES',

  ADDRESSES_LOADING_START: 'ADDRESSES_LOADING_START',
  ADDRESSES_LOADING_END: 'ADDRESSES_LOADING_END',
  ADDRESSES_FAILED: 'ADDRESSES_FAILED',
  ADDRESSES_SUCCESS: 'ADDRESSES_SUCCESS',
  RESET_ADDRESSES_MESSAGES: 'RESET_ADDRESSES_MESSAGES',
};
