import { createSelector } from 'reselect';
import { List } from 'immutable';
import { AppState, ProductState } from '../types';
import { SimpleListingStateRecord, PaginationRecord } from '../helpers';
import { Error } from '../providers';
import { ProductRecord } from '../models';

export const getProductState = (appState: AppState) => appState.product;

//* ************************* BEGIN EVENTS  *************************** */

export const getProductsState = createSelector<
  AppState,
  any,
  SimpleListingStateRecord<ProductRecord>
>(
  [getProductState],
  (productState: ProductState) => productState.productsState,
);

export const getProductsLoading = createSelector<AppState, any, boolean>(
  [getProductsState],
  (productsState: SimpleListingStateRecord<ProductRecord>) =>
    productsState.get('loading'),
);

export const getProductsRefreshing = createSelector<AppState, any, boolean>(
  [getProductsState],
  (productsState: SimpleListingStateRecord<ProductRecord>) =>
    productsState.get('refreshing'),
);

export const getProductsInfinitLoading = createSelector<AppState, any, boolean>(
  [getProductsState],
  (productsState: SimpleListingStateRecord<ProductRecord>) =>
    productsState.get('infinitLoading'),
);

export const getProductsError = createSelector<AppState, any, Error | null>(
  [getProductsState],
  (productsState: SimpleListingStateRecord<ProductRecord>) =>
    productsState.get('error'),
);

export const getProductsList = createSelector<
  AppState,
  any,
  List<ProductRecord> | undefined | null
>(
  [getProductsState],
  (productsState: SimpleListingStateRecord<ProductRecord>) =>
    productsState.get('list'),
);

export const getProductsPagination = createSelector<
  AppState,
  any,
  PaginationRecord | undefined | null
>(
  [getProductsState],
  (productsState: SimpleListingStateRecord<ProductRecord>) =>
    productsState.get('pagination'),
);

//* ************************* END EVENTS *************************** */
